import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Home.pageTitle',
    defaultMessage: 'Home',
    description: 'Main landing page',
  },
  heroTitle: {
    id: 'Home.heroTitle',
    defaultMessage: 'What is Polonium Network all about?',
    description: 'Hero title',
  },
  heroMessage: {
    id: 'Home.heroMessage',
    defaultMessage:
      'Polonium Network is an international platform created by Polonium Foundation, a go-to place to make finding and sustaining research connections easier. We connect Polish researchers, from all sectors and disciplines, working all around the world, and international researchers interested in opportunities in or collaborations with Poland.',
    description: 'Hero message',
  },
  heroMessage2: {
    id: 'Home.heroMessage2',
    defaultMessage:
      'Networking is crucial for research. There are almost 200,000 researchers in Poland, nearly 40% in the business sector, and thousands of Polish researchers abroad. There are >8 million researchers globally.',
    description: 'Hero message',
  },
  heroMessageEmphasized: {
    id: 'Home.heroMessageEmphasized',
    defaultMessage: 'Stay connected. Polonium Network is your way in! ',
    description: 'Hero message emphasize',
  },
});
