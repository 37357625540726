import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { Button, CancelButton } from 'components/Button/Button';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { useApiErrorHandler } from 'helpers';
import { useNotification } from 'modules/notifications/NotificationContext';
import { useApi } from 'api/ApiContext';

import { UserAvatar, PhotoUploadField } from '../';

import { messages } from './messages';
import styles from './AvatarUpdateForm.module.scss';

export const AvatarUpdateForm: React.FC = () => {
  const [isEditingAvatar, setEditingAvatar] = useState<boolean>(false);
  const [addedAvatarFile, setAddedAvatarFile] = useState<File | null>(null);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { userData, setUserData } = useProfile();
  const avatarUrl = userData!.avatarUrl;
  const { profile } = useApi();

  const handleError = useApiErrorHandler();
  const { createNotification } = useNotification();

  const addFile = (files: File[]) => {
    setAddedAvatarFile(files[0]);
    setEditingAvatar(true);
  };

  const removeFile = () => {
    setAddedAvatarFile(null);
    setEditingAvatar(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    try {
      const { data } = await profile.updatePhoto(addedAvatarFile);
      setUserData(data);
      setEditingAvatar(false);
      setAddedAvatarFile(null);

      createNotification({
        type: 'success',
        message: <FormattedMessage {...messages.avatarUpdated} />,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {avatarUrl ? (
          <UserAvatar
            avatarUrl={avatarUrl}
            onRemoveClick={() => setEditingAvatar(true)}
            isEditing={isEditingAvatar}
          />
        ) : (
          <PhotoUploadField addedFile={addedAvatarFile} onFileAdd={addFile} />
        )}
        {isEditingAvatar && (
          <div className={styles.buttons}>
            <CancelButton onClick={removeFile} className={styles.button} />
            <Button
              type="submit"
              className={styles.submit}
              isProcessing={isSubmitting}
              disabled={isSubmitting}
            >
              <FormattedMessage {...commonMessages[avatarUrl ? 'remove' : 'saveButton']} />
            </Button>
          </div>
        )}
      </form>
      <p className={styles.instructions}>
        <span className={styles.instructionsTitle}>
          <FormattedMessage {...messages.photoInstructionsTitle} />
        </span>
        <span className={styles.instructionsText}>
          <FormattedMessage {...messages.photoInstructions} />
        </span>
      </p>
    </div>
  );
};
