import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import { useNotification } from 'modules/notifications/NotificationContext';

import { messages } from './messages';

export const useApiErrorHandler = (
  useNotificationHook = useNotification,
  errorMessages = messages
) => {
  const { createNotification } = useNotificationHook();

  return useCallback(
    (error: AxiosError | any, durationInMs?: number) => {
      const errorKeys: string[] = get(error, 'response.data.errors', ['unexpected']);

      const errors = new Set();

      errorKeys.forEach(errorKey => {
        const message = errorMessages[errorKey] || errorMessages.unexpected;

        if (!errors.has(message)) {
          errors.add(message);
          createNotification({
            type: 'error',
            message: <FormattedMessage {...message} />,
            duration: durationInMs,
          });
        }
      });
    },
    [createNotification, errorMessages]
  );
};
