import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  linksTitle: {
    id: 'Footer.linksTitle',
    defaultMessage: 'Foundation',
    description: 'Links title',
  },
  aboutUsLink: {
    id: 'Footer.aboutUsLink',
    defaultMessage: 'About us',
    description: 'About us link name',
  },
  privacyPolicyLink: {
    id: 'Footer.privacyPolicyLink',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy policy link name',
  },
  termsLink: {
    id: 'Footer.termsLink',
    defaultMessage: 'Terms of Service',
    description: 'About us',
  },
  findsUsTitle: {
    id: 'Footer.findsUsTitle',
    defaultMessage: 'Find us',
    description: 'Finds us title',
  },
  findsUsInfo: {
    id: 'Footer.findsUsInfo',
    defaultMessage: 'Polonium Foundation',
    description: 'Finds us info',
  },
  findsUsAddress: {
    id: 'Footer.findsUsAddress',
    defaultMessage: 'Pl. Bankowy 2, 00-095 Warszawa/Poland',
    description: 'Finds us address',
  },
  findsUsNip: {
    id: 'Footer.findsUsNip',
    defaultMessage: 'NIP: 7010584408',
    description: 'Finds us NIP',
  },
  disclaimer: {
    id: 'Footer.disclaimer',
    defaultMessage:
      'The project is co-funded in the framework of the programme of the Ministry of Science and Higher Education under the name "DIALOG" in the project "2018-2020 Gateway to the Polish research diaspora: portal and events integrating the Polish international research community".',
    description: 'Disclaimer',
  },
  logoText: {
    id: 'Footer.logoText',
    defaultMessage: 'Created with',
    description: 'Text next to logo',
  },
  copyrights: {
    id: 'Footer.copyrights',
    defaultMessage: 'Copyright © 2019-2023',
    description: 'Copyrights text',
  },
});
