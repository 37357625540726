import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { commonMessages } from 'intl/commonMessages';

import styles from './RemoveButton.module.scss';

export interface RemoveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onClick, className }) => (
  <IconButton
    icon={DeleteIcon}
    onClick={onClick}
    type="button"
    className={classnames(styles.button, className)}
  >
    <FormattedMessage {...commonMessages.remove} />
  </IconButton>
);
