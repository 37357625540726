import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { Loading } from 'components/Loading/Loading';
import { GrantData, GrantElementForTour, Meta } from 'types/grant';
import ResponsivePagination from 'react-responsive-pagination';
import { useLocation } from 'react-router-dom';
import Tour from 'reactour';
import { toast, Toaster } from 'react-hot-toast';

import { Button } from '../../../../components/Button/Button';

import { GrantCard } from './components/GrantCard/GrantCard';
import { messages } from './messages';
import styles from './GrantsList.module.scss';
import './pagination.css';
import { SearchContainer } from './components/SearchContainer/SearchContainer';
import { steps } from './grantTourSteps';

export type GetGrantsProps = {
  query?: string;
  page?: number;
  search?: string;
  locations?: string[];
  stages?: string[];
  limit?: number;
};

export const GrantsList: React.FC = () => {
  const { formatMessage } = useIntl();
  const api = useApi();
  const handleError = useApiErrorHandler();
  const location = useLocation();

  const [grantsList, setGrantsList] = useState<GrantData[]>([]);
  const [grantMeta, setGrantMeta] = useState<Meta>();
  const [isFetchingGrants, setIsFetchingGrants] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [locationsQuery, setLocationsQuery] = useState<string[]>([]);
  const [stagesQuery, setStagesQuery] = useState<string[]>([]);
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [tourViewed, setTourViewed] = useState<string | null>('true');

  const limit = 48;

  const getGrants = useCallback(
    async ({ query }: GetGrantsProps) => {
      setIsFetchingGrants(true);
      try {
        window.history.pushState(null, '', `/grants${query}`);
        const { data } = await api.grants.getGrants(query);
        if (!data) return;
        setTotalPages(Math.ceil(data.meta.collection.found / (limit ? limit : 48)));
        setTotalResults(data.meta.collection.found);
        setGrantsList(data.data);
        setGrantMeta(data.meta);
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingGrants(false);
      }
    },
    [api.grants, handleError]
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const searchParam = queryParams.get('search');
    const countriesParam = queryParams.getAll('countries[]');
    const stagesParam = queryParams.getAll('stages[]');
    const params = new URLSearchParams();
    if (searchParam) {
      setSearchQuery(searchParam);
      params.append('search', searchParam);
    } else {
      setSearchQuery('');
    }
    if (countriesParam) {
      setLocationsQuery(countriesParam);
      countriesParam.forEach(country => params.append('countries[]', country));
    } else {
      setLocationsQuery([]);
    }
    if (stagesParam) {
      setStagesQuery(stagesParam);
      stagesParam.forEach(stage => params.append('stages[]', stage));
    } else {
      setStagesQuery([]);
    }
    const paramsString = params.toString();
    const finalUrl =
      `?page=${currentPage}&items=${limit}` + (paramsString ? `&${paramsString}` : '');
    if (searchParam || countriesParam || stagesParam) {
      getGrants({ query: finalUrl }).catch(e => console.error(e.response.data ?? e.message ?? e));
    } else {
      getGrants({ query: `?page=${currentPage}&items=${limit}` }).catch(e =>
        console.error(e.response.data ?? e.message ?? e)
      );
      setSearchQuery('');
      setLocationsQuery([]);
      setStagesQuery([]);
    }
  }, [currentPage, getGrants, location.search]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }
    if (locationsQuery) {
      locationsQuery.forEach(country => params.append('countries[]', country));
    }
    if (stagesQuery) {
      stagesQuery.forEach(stage => params.append('stages[]', stage));
    }
    const finalUrl = `?page=${page}&items=${limit}&${params.toString()}`;
    if (stagesQuery || locationsQuery || searchQuery) {
      getGrants({ query: finalUrl }).catch(e => console.error(e.response.data ?? e.message ?? e));
    } else {
      getGrants({ query: `?page=${page}&items=${limit}` }).catch(e =>
        console.error(e.response.data ?? e.message ?? e)
      );
    }
  }

  const grantElementsForTour: GrantElementForTour = {
    stagesId: grantsList.find(
      grant => grant?.attributes?.stages && grant.attributes.stages.length > 0
    )?.id,
    countriesId: grantsList.find(
      grant => grant?.attributes?.countries && grant.attributes.countries.length > 0
    )?.id,
    institutionId: grantsList.find(grant => grant?.attributes?.institution)?.id,
    eligibilityId: grantsList.find(grant => grant?.attributes?.eligibility)?.id,
    urlRefId: grantsList.find(grant => grant?.attributes?.urlReference)?.id,
  };

  const notify = useCallback(
    () =>
      toast(
        () => (
          <div className={styles.popupContainer}>
            <p
              className={styles.popupText}
              onClick={() => {
                localStorage.removeItem('tour-viewed');
                toast.dismiss();
                setIsTourOpen(true);
              }}
            >
              <FormattedMessage {...messages.toastText} />
            </p>
            <Button small={true} onClick={() => toast.dismiss()}>
              <FormattedMessage {...messages.toastCloseText} />
            </Button>
          </div>
        ),
        {
          id: 'tour-popup',
        }
      ),
    []
  );

  useEffect(() => {
    const tourView = localStorage.getItem('tour-viewed');
    setTourViewed(tourView);
    if (tourView) {
      setIsTourOpen(false);
      notify();
    }
    if (!tourView) {
      setIsTourOpen(true);
    }
  }, [tourViewed, notify]);

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          localStorage.setItem('tour-viewed', 'true');
          notify();
        }}
        closeWithMask={true}
        scrollDuration={300}
        badgeContent={(current, total) => current + '/' + total}
        accentColor={'#00dacc'}
        rounded={10}
        className={styles.mask}
        lastStepNextButton={
          <span
            onClick={() => {
              localStorage.setItem('tour-viewed', 'true');
              notify();
            }}
            style={{ fontSize: 14, fontWeight: 700, textTransform: 'uppercase' }}
          >
            Done!
            <br />
            Let's start
          </span>
        }
      />
      <section className={styles.wrapper}>
        <Helmet title={formatMessage(messages.pageName)} />
        <section className={styles.headingContainer}>
          <div className={styles.headingContentWrapper}>
            <div id={'first-step'}>
              <h1 className={styles.headingTitle}>
                <span>
                  <FormattedMessage {...messages.pageTitle} />
                </span>
              </h1>

              <h5 className={styles.headingSubtitle}>
                <FormattedMessage {...messages.headingSubtitle} />
              </h5>
            </div>

            <p className={styles.headingDescription}>
              <span id={'second-step'}>
                <FormattedMessage {...messages.headingDescription} />
              </span>
            </p>
          </div>
        </section>
        <section className={styles.grants}>
          <SearchContainer
            results={totalResults}
            setSearchQuery={setSearchQuery}
            setLocationsQuery={setLocationsQuery}
            setStagesQuery={setStagesQuery}
            setCurrentPage={setCurrentPage}
            isFetchingGrants={isFetchingGrants}
            stagesQuery={stagesQuery}
            locationsQuery={locationsQuery}
            searchQuery={searchQuery}
            getGrants={getGrants}
            stages={grantMeta?.filters?.available?.stages}
            countries={grantMeta?.filters?.available?.countries}
          />
          <Loading isLarge isLoading={isFetchingGrants} className={styles.spinner}>
            <div id={'third-step'}>
              {grantsList.map((grant, index) => (
                <GrantCard
                  {...grant}
                  key={grant.id}
                  index={index}
                  elementsForTour={grantElementsForTour}
                />
              ))}
            </div>
            <div className={styles.grantsPagination}>
              {grantsList.length !== 0 && (
                <ResponsivePagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={page => handlePageChange(page)}
                />
              )}
            </div>
          </Loading>
        </section>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            duration: 10000,
            style: {
              maxWidth: '500px',
              background: '#115567',
              color: '#fff',
              marginRight: 20,
              boxShadow: '0 0.5em 3em rgba(0, 0, 0, 1)',
            },
          }}
        />
      </section>
    </>
  );
};
