import React from 'react';
import classnames from 'classnames';
import { Discipline } from 'types';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';

import styles from './DisciplineItem.module.scss';

export interface DisciplineItemProps {
  discipline: Discipline;
  onClick: (discipline: Discipline) => void;
  selected: boolean;
}

export const DisciplineItem: React.FC<DisciplineItemProps> = ({
  discipline,
  onClick,
  selected,
}) => {
  const Icon = selected ? CheckmarkIcon : PlusIcon;

  return (
    <li className={styles.discipline}>
      <button
        type="button"
        onClick={() => onClick(discipline)}
        className={classnames(styles.button, { [styles.selected]: selected })}
      >
        <Icon className={selected ? styles.iconSelected : styles.icon} />
        {discipline.attributes.name}
      </button>
    </li>
  );
};
