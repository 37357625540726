import React from 'react';
import classnames from 'classnames';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useNavContext } from 'modules/navigation/NavContext';

import { Notification } from '../types';
import { useNotification } from '../NotificationContext';

import styles from './NotificationToast.module.scss';

export interface NotificationToastProps {
  notification: Notification;
}

export const NotificationToast: React.FC<NotificationToastProps> = ({ notification }) => {
  const { deleteNotification } = useNotification();
  const { type, id } = notification;

  return (
    <div className={classnames(styles.toast, { [styles.error]: type === 'error' })}>
      {notification.message}
      <button
        onClick={() => deleteNotification(id!)}
        className={styles.button}
        aria-label="discard notification"
      >
        <Close className={styles.icon} />
      </button>
    </div>
  );
};

export const NotificationList: React.FC = () => {
  const { notifications } = useNotification();
  const { isMobileMenuOpen } = useNavContext();

  if (!notifications.length || isMobileMenuOpen) return null;

  return (
    <ul className={styles.list}>
      {notifications.map(notification => (
        <li key={notification.id} className={styles.listItem}>
          <NotificationToast notification={notification} />
        </li>
      ))}
    </ul>
  );
};
