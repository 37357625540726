import React, { useState, createContext, useContext } from 'react';
import { DeserializedUserProfile } from 'types';

export interface ProfileContextValues {
  userData: DeserializedUserProfile | null;
  setUserData: (data: DeserializedUserProfile | null) => void;
}

export const ProfileContext = createContext<ProfileContextValues | null>(null);

export const ProfileContextProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<DeserializedUserProfile | null>(null);

  return (
    <ProfileContext.Provider value={{ userData, setUserData }}>{children}</ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) throw new Error('useProfileContext have to be used within ProfileContextProvider');

  return context;
};
