import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'components/Spinner/Spinner';
import { ROUTES } from 'config/routes';
import { ButtonLink } from 'components/Button/ButtonLink';

import { messages } from './messages';
import styles from './EmailConfirmationInstruction.module.scss';

interface EmailConfirmationInstructionProps {
  onClick: () => void;
  isProcessing: boolean;
}

export const EmailConfirmationInstruction: React.FC<EmailConfirmationInstructionProps> = ({
  onClick,
  isProcessing,
}) => (
  <>
    <ol className={styles.list}>
      <FormattedMessage {...messages.instructionsHeader} />
      <li className={styles.listItem}>
        <FormattedMessage {...messages.checkSpam} />
      </li>
      <li className={styles.listItem}>
        <FormattedMessage
          {...messages.sendAgain}
          values={{
            button: (
              <button className={styles.resendButton} onClick={onClick} disabled={isProcessing}>
                <span className={styles.buttonText}>
                  <FormattedMessage {...messages.resendButton} />
                </span>
                {isProcessing && <Spinner className={styles.spinner} />}
              </button>
            ),
          }}
        />
      </li>
    </ol>
    <ButtonLink to={ROUTES.HOME}>
      <FormattedMessage {...messages.homeButton} />
    </ButtonLink>
  </>
);
