import React from 'react';

import styles from './TermsOfService.module.scss';
import { TermsOfServiceStatements } from './TermsOfServiceStatements';

export const TermsOfService: React.FC = () => {
  return (
    <section className={styles.pageWrapper}>
      <h1 className={styles.title}>Terms and conditions</h1>
      <p className={styles.paragraph}>
        These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;) are an agreement
        between Polonium Foundation (Fundacja Polonium), Pl. Bankowy 2, 00-095 Warszawa KRS
        0000621890, NIP 7010584408, Regon 364666620 (&quot;Fundacja Polonium, Pl. Bankowy 2, 00-095
        Warszawa&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
        (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Agreement sets forth the
        general terms and conditions of your use of the{' '}
        <a href="https://poloniumnetwork.org">poloniumnetwork.org</a> website and any of its
        products or services (collectively, &quot;Website&quot; or &quot;Services&quot;).
      </p>
      {TermsOfServiceStatements.map((statement, idx) => {
        return (
          <div key={idx}>
            <h2 className={styles.statementTitle}>{statement.title}</h2>
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: statement.content }}
            />
          </div>
        );
      })}
    </section>
  );
};
