import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, FormikHelpers } from 'formik';
import debounce from 'debounce-promise';
import { object } from 'yup';
import { commonMessages } from 'intl/commonMessages';
import { validators } from 'config/errors/validators';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { AutocompleteField } from 'components/Autocomplete';
import { Tag } from 'components/Tag/Tag';
import { Institution, DeserializedEntity } from 'types';

import { InlineEditButton, ProfileBox, ProfileBoxFormButtons } from '../';
import { messages } from '../../messages';

import styles from './InstitutionsBox.module.scss';

export interface InstitutionsBoxProps {
  institutions: Institution[];
  onEdit?: () => void;
}

export interface InstitutionsForm {
  institutions: string[];
}

const validationSchema = object().shape({
  institutions: validators.profileInstitutions,
});

export const InstitutionsBox: React.FC<InstitutionsBoxProps> = ({ institutions }) => {
  const { formatMessage } = useIntl();
  const [isEdited, setEdited] = useState<boolean>(false);
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  const initialValues = {
    institutions: institutions.map(({ attributes: { name } }) => name),
  };

  const edit = () => setEdited(true);

  const cancel = () => {
    setEdited(false);
  };

  const handleSubmit = async (
    { institutions }: InstitutionsForm,
    { setSubmitting }: FormikHelpers<InstitutionsForm>
  ) => {
    try {
      const { data } = await profile.updateInstitutions(institutions);
      setUserData(data);
      setSubmitting(false);
      setEdited(false);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  const getInstitutions = async (query: string) => {
    const response = await profile.getInstitutions(query);
    return response.data.data as DeserializedEntity[];
  };

  return (
    <ProfileBox
      onEdit={edit}
      isEdited={isEdited}
      title={<FormattedMessage {...messages.institutionsTitle} />}
      className={styles.boxWithTags}
    >
      {isEdited ? (
        <Formik<InstitutionsForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <AutocompleteField
                name="institutions"
                placeholder={formatMessage(commonMessages.institutionsPlaceholder)}
                loadOptions={debounce(getInstitutions, 400)}
                isMulti={true}
              />
              <ProfileBoxFormButtons isSubmitting={isSubmitting} onCancel={cancel} />
            </Form>
          )}
        </Formik>
      ) : (
        <InstitutionsPreview institutions={institutions} onEdit={edit} />
      )}
    </ProfileBox>
  );
};

const InstitutionsPreview: React.FC<InstitutionsBoxProps> = ({ institutions, onEdit }) => (
  <div className={styles.preview}>
    {institutions.length ? (
      institutions.map(({ attributes: { name } }) => <Tag key={name}>{name}</Tag>)
    ) : (
      <p className={styles.boxText}>
        <FormattedMessage
          {...messages.emptyInstitutionsText}
          values={{
            button: <InlineEditButton onClick={onEdit} />,
          }}
        />
      </p>
    )}
  </div>
);

export const InstitutionsPreviewBox: React.FC<InstitutionsBoxProps> = ({ institutions }) => (
  <ProfileBox
    title={<FormattedMessage {...messages.institutionsTitle} />}
    className={styles.boxWithTags}
  >
    <InstitutionsPreview institutions={institutions} />
  </ProfileBox>
);
