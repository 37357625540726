import React from 'react';
import { UserProfile } from 'types';
import { FormattedMessage } from 'react-intl';

import { ProfileCard } from '../ProfileCard/ProfileCard';

import styles from './FilteredProfiles.module.scss';
import { messages } from './messages';

interface FilteredProfilesProps {
  profilesList: UserProfile[];
}

const EmptyState: React.FC = () => (
  <div>
    <div className={styles.emptyStateImage} />
    <div className={styles.emptyStateCopy}>
      <span>
        <span className={styles.emptyStateCopyBold}>
          <FormattedMessage {...messages.noResultsCopyPefix} />
        </span>
        <FormattedMessage {...messages.noResultsCopySuffix} />
      </span>
      <FormattedMessage {...messages.noResultsCopySecondLine} />
    </div>
  </div>
);

export const FilteredProfiles: React.FC<FilteredProfilesProps> = ({ profilesList }) => {
  if (!profilesList.length) {
    return <EmptyState />;
  }

  return (
    <div className={styles.profilesListWrapper}>
      {profilesList.map(profile => (
        <ProfileCard key={profile.id} {...profile} />
      ))}
    </div>
  );
};
