import React, { createContext } from 'react';

import { Api, apiManager } from './ApiClient';

export const ApiContext = createContext<Api | undefined>(undefined);

export const ApiProvider: React.FC<{ api?: Api }> = ({ children, api = apiManager }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

export const useApi = () => {
  const context = React.useContext(ApiContext);

  if (!context) throw new Error('useApi have to be used within ApiProvider');

  return context;
};
