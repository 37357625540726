import React from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { JobOffersList } from 'modules/jobOffers/pages/JobOffersList/JobOffersList';
import { JobOfferPage } from 'modules/jobOffers/pages/JobOfferPage/JobOfferPage';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';

export const JobOffersRoutes: React.FC<RouteProps> = () => (
  <Switch>
    <ProtectedRoute exact path={ROUTES.JOB_OFFER} component={JobOfferPage} />
    <ProtectedRoute exact path={ROUTES.CAREER} component={JobOffersList} />
  </Switch>
);
