import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PARTNERS_EMAIL } from 'config/constants';
import { CallToActionSection } from 'pages/landings/components/CallToActionSection/CallToActionSection';

import { messages } from './messages';
import styles from './OfferCTASection.module.scss';

export const OfferCTASection: React.FC = () => (
  <CallToActionSection
    titleHighlighted={<FormattedMessage {...messages.offerCTATitleHighlighted} />}
    title={<FormattedMessage {...messages.offerCTATitle} />}
  >
    <p className={styles.description}>
      <FormattedMessage {...messages.description} />
    </p>
    <div className={styles.linkWrapper}>
      <a href={`mailto:${PARTNERS_EMAIL}`} className={styles.linkButton}>
        <FormattedMessage {...messages.buttonLabel} />
      </a>
    </div>
  </CallToActionSection>
);
