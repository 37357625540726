import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form, Formik, FormikHelpers } from 'formik';
import { object } from 'yup';
import { ROUTES } from 'config/routes';
import { useApiErrorHandler } from 'helpers';
import { validators } from 'config/errors/validators';
import { useApi } from 'api/ApiContext';
import { commonMessages } from 'intl/commonMessages';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/TextField/TextField';
import { ButtonLink } from 'components/Button/ButtonLink';
import background from 'assets/images/background-5.svg';

import { messages } from './messages';
import styles from './ForgotPassword.module.scss';

interface ForgotPasswordForm {
  email: string;
}

const initialValues = {
  email: '',
};

const validationSchema = object().shape({
  email: validators.email,
});

export const ForgotPassword: React.FC = () => {
  const { formatMessage } = useIntl();
  const { auth } = useApi();
  const { push } = useHistory();
  const handleError = useApiErrorHandler();

  const handleSubmit = async (
    { email }: ForgotPasswordForm,
    { setSubmitting }: FormikHelpers<ForgotPasswordForm>
  ) => {
    try {
      await auth.forgotPassword(email);
      push({ pathname: ROUTES.FORGOT_PASSWORD_CONFIRMATION, state: { email } });
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <AuthLayout
        description={formatMessage(messages.description)}
        title={formatMessage(messages.header)}
        classNames={{ description: styles.description }}
        background={background}
      >
        <Formik<ForgotPasswordForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form} noValidate>
              <TextField
                name="email"
                type="email"
                label={formatMessage(commonMessages.emailLabel)}
                placeholder={formatMessage(commonMessages.emailPlaceholder)}
              />
              <div className={styles.buttonWrapper}>
                <ButtonLink to={ROUTES.LOGIN} secondary={true} className={styles.cancelButton}>
                  {formatMessage(commonMessages.cancelButton)}
                </ButtonLink>
                <Button
                  type="submit"
                  className={styles.submit}
                  isProcessing={isSubmitting}
                  disabled={isSubmitting}
                >
                  {formatMessage(messages.submitButton)}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
};
