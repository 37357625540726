import omit from 'lodash/omit';
import { StoredAuthHeaders } from 'modules/auth/models';

export const mockAuthHeaders = {
  uid: 'han.solo@rebellion.com',
  client: 'rebellion-network',
  'access-token': 'h4nS0L0',
};

export const mockStoredAuthHeaders: StoredAuthHeaders = {
  ...omit(mockAuthHeaders, 'access-token'),
  accessToken: 'h4nS0L0',
};
