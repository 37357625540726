import React from 'react';

import styles from './Messages.module.scss';
import { Message, MessageProps } from './Message';
import { ActionsDropdown } from './ActionsDropdown';

export interface MessagesProps {
  deleteConversation: () => void;
  muteConversation: () => void;
  unmuteConversation: () => void;
  receiver: string;
  messages: Array<MessageProps>;
  muted: boolean;
}

export const Messages: React.FC<MessagesProps> = ({
  deleteConversation,
  muteConversation,
  unmuteConversation,
  receiver,
  messages,
  muted,
}) => {
  return (
    <section className={styles.card}>
      <div className={styles.boxHeader}>
        <h2 className={styles.headerTitle}>{receiver}</h2>
        <ActionsDropdown>
          <li className={styles.navItem}>
            <div onClick={deleteConversation} className={styles.link}>
              Delete
            </div>
          </li>
          {muted ? (
            <li className={styles.navItem}>
              <div onClick={unmuteConversation} className={styles.link}>
                Unmute
              </div>
            </li>
          ) : (
            <li className={styles.navItem}>
              <div onClick={muteConversation} className={styles.link}>
                Mute
              </div>
            </li>
          )}
        </ActionsDropdown>
      </div>
      <div className={styles.messagesContainer}>
        {messages.map((message, id) => (
          <Message
            key={`${message.text}-${id}`}
            text={message.text}
            isSender={message.isSender}
            date={message.date}
          />
        ))}
      </div>
    </section>
  );
};
