import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  stepOne: {
    id: 'AuthLayout.stepOne',
    defaultMessage: 'Confirm your email',
    description: 'Step One Title',
  },
  stepOneDescription: {
    id: 'AuthLayout.stepOneDescription',
    defaultMessage: 'Confirm your email',
    description: 'Step One Description',
  },
  stepTwo: {
    id: 'AuthLayout.stepTwo',
    defaultMessage: 'Define your discipline',
    description: 'Step Two Title',
  },
  stepTwoDescription: {
    id: 'AuthLayout.stepTwoDescription',
    defaultMessage:
      'Adding your scientific discipline will help other scholars find you to exchange thoughts.',
    description: 'Step One Description',
  },
  stepThree: {
    id: 'AuthLayout.stepThree',
    defaultMessage: 'Add your affiliation',
    description: 'Step Three Title',
  },
  stepThreeDescription: {
    id: 'AuthLayout.stepThreeDescription',
    defaultMessage:
      'Providing data about affiliation and current residence place will easier your local bonding.',
    description: 'Step Three Description',
  },
});
