import React from 'react';

import styles from './Message.module.scss';

export interface MessageProps {
  text: string;
  isSender: boolean;
  date: number;
}

export const Message: React.FC<MessageProps> = ({ text, isSender, date }) => {
  const messageClassName = isSender ? styles.messageRight : styles.messageLeft;

  return (
    <div className={messageClassName}>
      <div className={styles.messageContainer}>
        <span className={styles.message}>{text}</span>
        <p className={styles.date}>{new Date(+date * 1000).toLocaleString()}</p>
      </div>
    </div>
  );
};
