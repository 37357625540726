import React from 'react';
import { InputField, InputFieldProps } from 'components/InputField/InputField';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from 'assets/icons/eye-crossed.svg';

import styles from './PasswordField.module.scss';

export type PasswordFieldProps = Omit<InputFieldProps, 'type'>;

export const PasswordField: React.FC<PasswordFieldProps> = props => {
  const [isVisible, setVisible] = React.useState(false);

  const toggleVisible = () => setVisible(prevVisible => !prevVisible);
  const Icon = isVisible ? EyeCrossedIcon : EyeIcon;

  return (
    <InputField
      type={isVisible ? 'text' : 'password'}
      icon={
        <button className={styles.iconBtn} onClick={toggleVisible} type="button">
          <Icon className={styles.icon} />
        </button>
      }
      classNames={{ input: styles.input }}
      {...props}
    />
  );
};
