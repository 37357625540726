import { AxiosResponse } from 'axios';

import { ResourceClient } from './ResourceClient';

export interface SubscriptionData {
  email: string;
}

export class NewsletterSubscriptionsClient extends ResourceClient {
  createSubscription(email: string): Promise<AxiosResponse<{ data: SubscriptionData }>> {
    const newsletterSubscription = { email };

    return this.client.post<{ data: SubscriptionData }>('/newsletter_subscriptions', {
      newsletterSubscription,
    });
  }
}
