import { AxiosResponse } from 'axios';
import { ResidenceAttributes } from 'types';
import { AffiliationAndPrivacyFilledForm } from 'modules/onboarding/pages/AffiliationSetup/AffiliationSetup';

import { ResourceClient } from './ResourceClient';
import { UserData } from './AuthClient';
import { serializeAffiliations, serializeLocation } from './serializers';

export interface AffiliationsAttributes {
  academicTitle: string;
  institution: string;
}

export interface AffiliationAndResidenceParams {
  affiliationsAttributes: AffiliationsAttributes[];
  currentResidenceAttributes: ResidenceAttributes;
  hiddenProfile: boolean;
}

export class OnboardingClient extends ResourceClient {
  saveDisciplines(disciplineIds: number[]): Promise<AxiosResponse<UserData>> {
    return this.client.post<UserData>('/onboarding/disciplines_assignment', {
      disciplineIds,
    });
  }

  saveAffiliations(
    affiliationAndResidenceData: AffiliationAndPrivacyFilledForm
  ): Promise<AxiosResponse<UserData>> {
    const { residence, affiliations, hiddenProfile } = affiliationAndResidenceData;

    const user = {
      hiddenProfile,
      currentResidenceAttributes: serializeLocation(residence),
      affiliationsAttributes: serializeAffiliations(affiliations),
    };

    return this.client.post<UserData>('/onboarding/affiliations_and_residences', { user });
  }
}
