import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { object, array } from 'yup';
import { validators } from 'config/errors/validators';
import { DeserializedUserProfile, AffiliationForm, AffiliationFilledForm } from 'types';
import { CancelButton, SaveButton } from 'components/Button/Button';
import { useApiErrorHandler } from 'helpers/errors/useApiErrorHandler';
import { useApi } from 'api/ApiContext';

import { AvatarUpdateForm, AffiliationFormContent } from '../';

import styles from './ProfileHeaderForm.module.scss';

const validationSchema = object().shape({
  residence: validators.residence,
  affiliations: array().of(
    object().shape({
      position: validators.position,
      institution: validators.institution,
    })
  ),
});

export interface ProfileHeaderFormProps {
  userName: string;
  initialValues: AffiliationForm;
  onCancel: () => void;
  onSubmit: (data: DeserializedUserProfile) => void;
}

export const ProfileHeaderForm: React.FC<ProfileHeaderFormProps> = ({
  userName,
  initialValues,
  onCancel,
  onSubmit,
}) => {
  const handleError = useApiErrorHandler();
  const { profile } = useApi();

  const handleSubmit = async (
    values: AffiliationForm,
    { setSubmitting }: FormikHelpers<AffiliationForm>
  ) => {
    try {
      const { data } = await profile.updateHeader(values as AffiliationFilledForm);
      onSubmit(data);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.name}>{userName}</h1>
      <div className={styles.photo}>
        <AvatarUpdateForm />
      </div>
      <Formik<AffiliationForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate className={styles.affiliationForm}>
            <AffiliationFormContent />
            <div className={styles.buttons}>
              <CancelButton onClick={onCancel} className={styles.button} />
              <SaveButton
                disabled={isSubmitting}
                isProcessing={isSubmitting}
                className={styles.submitButton}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
