import { string, boolean, ref, mixed, array } from 'yup';

import { messages } from './messages';

export const EXPERTISES_MAX_LENGTH = 6;
export const PASSWORD_MIN_LENGTH = 8;
export const ABOUT_MAX_LENGTH = 1000;
export const URL_REGEX = /^(http|https):\/\/|[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i;

const requiredString = (errorMessageId?: string) =>
  string()
    .trim()
    .required(errorMessageId);

export const validators = {
  firstName: requiredString(messages.firstNameRequired.id),
  lastName: requiredString(messages.lastNameRequired.id),
  email: string()
    .email(messages.emailFormat.id)
    .required(messages.emailRequired.id),
  password: string()
    .min(PASSWORD_MIN_LENGTH, messages.passwordLength.id)
    .matches(/\d/, messages.passwordDigits.id)
    .matches(/[a-zA-Z]/, messages.passwordLetters.id)
    .matches(/[-!$%^&*()_+|~=`{}[\]:";'<>?,.@/]/, messages.passwordSpecialChar.id)
    .required(messages.passwordRequired.id),
  passwordConfirmation: string().oneOf([ref('password'), null], messages.passwordMismatch.id),
  termsAccepted: boolean().oneOf([true], messages.requiredField.id),
  newsletterAccepted: boolean(),
  residence: mixed().required(messages.residenceRequired.id),
  gender: mixed().required(messages.genderRequired.id),
  position: requiredString(messages.positionRequired.id),
  institution: requiredString(messages.institutionRequired.id),
  profileInstitutions: array()
    .of(requiredString())
    .required(messages.institutionsMinLength.id),
  university: requiredString(messages.universityRequired.id),
  degrees: array()
    .of(requiredString())
    .required(messages.degreesMinLength.id),
  grantProvider: mixed().required(messages.grantProviderRequired.id),
  grantName: mixed().required(messages.grantNameRequired.id),
  socialMediaPlatform: mixed().required(messages.socialMediaPlatformRequired.id),
  socialMediaUrl: string()
    .matches(URL_REGEX, messages.socialMediaUrlFormat.id)
    .required(messages.socialMediaUrlRequired.id),
};
