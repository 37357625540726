import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ErrorMessageProps, useField } from 'formik';
import find from 'lodash/find';
import { messages } from 'config/errors/messages';

import styles from './FieldError.module.scss';

export const FieldError: React.FC<ErrorMessageProps> = ({ className, name }) => {
  const errorClassNames = classnames(styles.error, className);
  const [, { error }] = useField({ name });
  const errorMessage = find(messages, { id: error });

  return (
    <span className={errorClassNames}>
      {errorMessage ? <FormattedMessage {...errorMessage} /> : error}
    </span>
  );
};
