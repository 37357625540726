import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'config/routes';
import {
  POLONIUM_TWITTER,
  POLONIUM_FACEBOOK,
  POLONIUM_YOUTUBE,
  POLONIUM_LINKEDIN,
} from 'config/constants';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg';
import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import PoloniumLogo from 'assets/icons/logo_dark.png';

import { messages } from './messages';
import styles from './Footer.module.scss';

export const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContainer}>
      <div className={styles.row}>
        <div className={styles.column}>
          <h5 className={styles.title}>
            <FormattedMessage {...messages.linksTitle} />
          </h5>
          <div className={styles.container}>
            <Link to={ROUTES.ABOUT} className={styles.link}>
              <FormattedMessage {...messages.aboutUsLink} />
            </Link>
            <Link to={ROUTES.PRIVACY} target="_blank" className={styles.link}>
              <FormattedMessage {...messages.privacyPolicyLink} />
            </Link>
            <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank" className={styles.link}>
              <FormattedMessage {...messages.termsLink} />
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h5 className={styles.title}>
            <FormattedMessage {...messages.findsUsTitle} />
          </h5>
          <p className={styles.container}>
            <FormattedMessage {...messages.findsUsInfo} />
            <br />
            <FormattedMessage {...messages.findsUsAddress} />
            <br />
            <FormattedMessage {...messages.findsUsNip} />
          </p>
        </div>
        <div className={styles.column}>
          <div className={styles.socialContainer}>
            <a
              href={POLONIUM_TWITTER}
              className={styles.socialItem}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Twitter className={styles.icon} />
            </a>
            <a
              href={POLONIUM_FACEBOOK}
              className={styles.socialItem}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Facebook className={styles.icon} />
            </a>
            <a
              href={POLONIUM_LINKEDIN}
              className={styles.socialItem}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LinkedIn className={styles.icon} />
            </a>
            <a
              href={POLONIUM_YOUTUBE}
              className={styles.socialItem}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Youtube className={styles.icon} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.disclaimerContainer}>
        <p className={styles.disclaimer}>
          <FormattedMessage {...messages.disclaimer} />
        </p>
      </div>
      <div className={styles.bottom}>
        <img src={PoloniumLogo} className={styles.logo} alt="Logo" />

        <p className={styles.copyright}>
          <FormattedMessage {...messages.copyrights} />
        </p>
      </div>
    </div>
  </footer>
);
