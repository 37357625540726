import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  testimonialsTitle: {
    id: 'Home.testimonialsTitle',
    defaultMessage: 'Testimonials',
    description: 'Testimonials section title',
  },
  testimonialsSubtitle: {
    id: 'Home.testimonialsSubtitle',
    defaultMessage: 'They trust us',
    description: 'Testimonials section subtitle',
  },
});
