import React, { useState } from 'react';

import styles from './Tooltip.module.scss';

type TooltipProps = {
  text: string | JSX.Element;
  children: JSX.Element;
};

const Tooltip = ({ text, children }: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div className={styles.tooltipContainer} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && <div className={styles.tooltip}>{text}</div>}
    </div>
  );
};

export default Tooltip;
