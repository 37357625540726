import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Form, Formik, FormikHelpers } from 'formik';
import { object } from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/AuthContext';
import { useApi } from 'api/ApiContext';
import { ROUTES } from 'config/routes';
import { validators } from 'config/errors/validators';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/TextField/TextField';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { AuthNavbar } from 'components/AuthLayout/AuthNavbar/AuthNavbar';
import { commonMessages } from 'intl/commonMessages';
import { useApiErrorHandler } from 'helpers';

import { messages } from './messages';
import styles from './Login.module.scss';

interface LoginForm {
  email: string;
  password: string;
}

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  email: validators.email,
  password: validators.password,
});

export const Login: React.FC = () => {
  const { formatMessage } = useIntl();
  const { auth } = useApi();
  const { logIn } = useAuthContext();
  const history = useHistory();
  const handleError = useApiErrorHandler();

  const handleSubmit = async (values: LoginForm, actions: FormikHelpers<LoginForm>) => {
    try {
      const response = await auth.signIn(values);
      const { data, headers } = response;
      logIn({ headers, user: data });

      history.push(ROUTES.MY_PROFILE);
    } catch (error) {
      handleError(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <AuthLayout
        description={formatMessage(messages.description)}
        title={formatMessage(messages.header)}
        withPhotoBackground={true}
        authNavbar={
          <AuthNavbar
            path={ROUTES.SIGNUP}
            description={formatMessage(messages.authNavbarDescription)}
            link={formatMessage(messages.authNavbarLink)}
          />
        }
      >
        <Formik<LoginForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form} noValidate>
              <TextField
                name="email"
                type="email"
                label={formatMessage(commonMessages.emailLabel)}
                placeholder={formatMessage(commonMessages.emailPlaceholder)}
              />
              <Link to={ROUTES.FORGOT_PASSWORD} className={styles.link}>
                {formatMessage(commonMessages.forgotPassword)}
              </Link>
              <PasswordField
                name="password"
                label={formatMessage(commonMessages.passwordLabel)}
                placeholder={formatMessage(commonMessages.passwordPlaceholder)}
              />
              <Button
                type="submit"
                className={styles.button}
                isProcessing={isSubmitting}
                disabled={isSubmitting}
              >
                {formatMessage(messages.submitButton)}
              </Button>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
};
