import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  helpTitle: {
    id: 'Stepper.helpTitle',
    defaultMessage: 'Need help?',
    description: 'Help tilte',
  },
  helpDescription: {
    id: 'AuthLayout.helpDescription',
    defaultMessage:
      'Should you have any questions regarding the Polonium Platform we will be happy to tackle them. Write to us on',
    description: 'Help description',
  },
});
