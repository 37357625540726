import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export class HttpResponseError extends Error {
  config: AxiosRequestConfig = this.error.config;
  request: XMLHttpRequest = this.error.request;
  response: AxiosResponse<any> = this.error.response!;

  constructor(public error: AxiosError) {
    super(error.message);
  }
}

export class InterceptedResponseError {
  constructor(public error: HttpResponseError) {}

  get isUnauthorized() {
    const isUnauthenticated =
      this.error.response.status === 401 && this.contains('unauthenticated');

    return isUnauthenticated;
  }

  private contains(message: string) {
    return (
      this.error.response.data.errors instanceof Array &&
      this.error.response.data.errors.some((msg: string) => msg === message)
    );
  }
}
