import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export const messages: { [key: string]: MessageDescriptor } = defineMessages({
  unexpected: {
    id: 'errors.unexpected',
    defaultMessage: 'Unexpected error occured. Try again later',
    description: 'Unexpected error message',
  },
  sessions_bad_credentials: {
    id: 'errors.sessions_bad_credentials',
    defaultMessage: 'Incorrect credentials',
    description: 'Incorrect credentials error message',
  },
  sessions_not_confirmed: {
    id: 'errors.sessions_not_confirmed',
    defaultMessage: 'An account has to be confirmed to log in',
    description: 'Unconfirmed account error message',
  },
  email_already_taken: {
    id: 'errors.email_already_taken',
    defaultMessage: 'The email address is already taken',
    description: 'Taken email error message',
  },
  unauthenticated: {
    id: 'errors.unauthorized',
    defaultMessage: 'You are unauthorized, please login again',
    description: 'Unauthorized message',
  },
  messageToYourself: {
    id: 'errors.messageToYourself',
    defaultMessage:
      'Your inbox is empty or you are trying to message yourself. Please try choosing contact in community page.',
    description: 'User clicks contact button on their account',
  },
  youAreBlocked: {
    id: 'errors.youAreBlocked',
    defaultMessage: 'This user has muted your conversation.',
    description: 'User wants to send message to user that has blocked him.',
  },
});
