export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  PARTNERSHIP: '/partnership',
  FIND_THE_RIGHT_PEOPLE: '/find-the-right-people',
  PRIVACY: '/privacy-policy',
  COOKIES: '/cookie-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  SIGNUP: '/sign-up',
  LOGIN: '/login',
  ACCOUNT_CONFIRMATION: '/sign-up/confirmation',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password/confirmation',
  RESET_PASSWORD: '/reset-password',
  COMMUNITY: '/profiles',
  MESSAGES: '/profiles/messages',
  COMMUNITY_LIST: '/profiles/results',
  PROFILE: '/profiles/:userId',
  MY_PROFILE: '/profiles/me',
  SETTINGS: '/profiles/me/settings',
  CAREER: '/careers',
  JOB_OFFER: '/careers/:jobOfferId',
  GRANTS: '/grants',
  ONBOARDING: '/onboarding',
  DISCIPLINE: '/onboarding/discipline',
  AFFILIATION: '/onboarding/affiliation',
};
