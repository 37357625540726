import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeserializedAffiliation, DeserializedLocation } from 'types';
import { ReactComponent as Location } from 'assets/icons/location.svg';

import { messages } from '../../messages';

import styles from './UserDetails.module.scss';

interface UserDetailsProps {
  affiliations: DeserializedAffiliation[];
  residence: DeserializedLocation;
}

export const UserDetails: React.FC<UserDetailsProps> = ({ affiliations, residence }) => (
  <>
    <ul>
      {affiliations.map(({ position, institution }) => (
        <li className={styles.userDetails} key={`${position.label}_${institution.label}`}>
          <span className={styles.position}>{position.label} </span>
          <span className={styles.university}>{institution.label}</span>
        </li>
      ))}
    </ul>
    <p className={styles.location}>
      <span className={styles.residenceLabel}>
        <Location className={styles.icon} />
        <FormattedMessage {...messages.residence} />
      </span>
      {residence.city}
    </p>
  </>
);
