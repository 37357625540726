import React, { useEffect, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import styles from '../GrantCard.module.scss';

const EligibilityInfo: React.FC<{
  eligibility: string | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  grantId: number;
  eligibilityId: number | undefined;
}> = ({ eligibility, setIsModalOpen, eligibilityId, grantId }) => {
  const [windowWidth, setWindowWidth] = useState<number | undefined>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  const clearedText = eligibility?.replace(/[["\]]/g, '');

  return (
    <div
      className={styles.eligibilityContainer}
      id={eligibilityId === grantId ? 'eighth-step' : ''}
    >
      <span className={styles.eligibilitySpan}>Eligibility:</span>
      <div>
        <LinesEllipsis
          className={styles.eligibility}
          text={clearedText}
          maxLine={windowWidth && windowWidth > 768 ? 2 : 4}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
        {eligibility && eligibility.length > 180 && (
          <button className={styles.showAllButton} onClick={() => setIsModalOpen(true)}>
            Show all...
          </button>
        )}
      </div>
    </div>
  );
};

export default EligibilityInfo;
