import React from 'react';
import classnames from 'classnames';
import { useAuthContext } from 'modules/auth/AuthContext';
import { ReactComponent as UserIcon } from 'assets/icons/user-silhouette.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-head.svg';

import styles from './UserMenuButton.module.scss';

export interface UserMenuButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const UserMenuButton: React.FC<UserMenuButtonProps> = ({ onClick, isOpen }) => {
  const { user } = useAuthContext();

  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.name}>{user && user.firstName}</div>
      <div className={classnames(styles.iconWrapper, { [styles.open]: isOpen })}>
        <UserIcon className={styles.icon} />
        <Arrow className={classnames(styles.arrow, { [styles.open]: isOpen })} />
      </div>
    </button>
  );
};
