import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ROUTES } from 'config/routes';
import dashboard from 'assets/images/6.png';
import register from 'assets/images/5.png';
import { ButtonLink } from 'components/Button/ButtonLink';

import { messages } from './messages';
import styles from './HelpSection.module.scss';

export const HelpSection: React.FC = () => (
  <section className={styles.section}>
    <div className={classnames(styles.container, styles.mobile)}>
      <div>
        <h2 className={styles.title}>
          <FormattedMessage {...messages.helpTitle} />
          <br />
          <FormattedMessage {...messages.helpSubtitle} />
        </h2>
      </div>
    </div>
    <div className={styles.colorBg} />
    <div className={styles.container}>
      <div className={styles.photoContainer}>
        <img src={dashboard} alt="dashboard presentation" className={styles.photo} />
        <img
          src={register}
          alt="registration presentation"
          className={classnames(styles.photo, styles.photo2)}
        />
      </div>
      <div className={styles.halfWidthContainer}>
        <h2 className={classnames(styles.title, styles.desktop)}>
          <FormattedMessage {...messages.helpTitle} /> <br />
          <FormattedMessage {...messages.helpSubtitle} />
        </h2>
        <div className={styles.featureContainer}>
          <h4 className={styles.featureTitle}>
            <FormattedMessage {...messages.helpFirstFeatureTitle} />
          </h4>
          <p className={styles.featureText}>
            <FormattedMessage {...messages.helpFirstFeature} />
          </p>
        </div>
        <div className={styles.featureContainer}>
          <h4 className={styles.featureTitle}>
            <FormattedMessage {...messages.helpSecondFeatureTitle} />
          </h4>
          <p className={styles.featureText}>
            <FormattedMessage {...messages.helpSecondFeature} />
          </p>
        </div>
        <ButtonLink to={ROUTES.SIGNUP}>
          <FormattedMessage {...messages.helpCTA} />
        </ButtonLink>
      </div>
    </div>
  </section>
);
