import React from 'react';
import { Link } from 'react-router-dom';
import { JobOffer } from 'types/jobOffer';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as DeadlineIcon } from 'assets/icons/stopwatch.svg';
import { ROUTES } from 'config/routes';
import { generateRouteWithParam } from 'helpers';

import styles from './JobOfferCard.module.scss';
import { messages } from './messages';

export const DeadlineInfo: React.FC<{ deadline: string }> = ({ deadline }) => {
  const daysUntil = (dateToCheckAgainst: string) => {
    return moment().to(moment(dateToCheckAgainst));
  };

  return (
    <div className={styles.jobOfferDeadline}>
      <DeadlineIcon className={styles.icon} />
      <span className={styles.applicationDeadlineCopy}>
        <FormattedMessage {...messages.applicationDeadlineCopy} />
      </span>
      <span className={styles.applicationDeadlineDate}>
        {moment(deadline).format('MMMM Do YYYY')}
      </span>
      <span className={styles.daysUntilCount}>({daysUntil(deadline)})</span>
    </div>
  );
};

export const JobOfferCard: React.FC<JobOffer> = ({
  id,
  attributes: { title, createdAt, institutionName, locationName, deadline },
}) => {
  return (
    <Link to={generateRouteWithParam(ROUTES.JOB_OFFER, id)} className={styles.wrapper}>
      <div className={styles.jobOfferDetails}>
        <div className={styles.jobOfferTitle}>{title}</div>
        <div className={styles.jobOfferInstitutionName}>{institutionName}</div>
        <div className={styles.locationAndDeadline}>
          <div className={styles.jobOfferLocation}>
            <LocationIcon className={styles.icon} />
            {locationName}
          </div>
          <DeadlineInfo deadline={deadline} />
        </div>
      </div>
      <div className={styles.jobOfferCreatedAt}>
        <CalendarIcon className={styles.icon} />
        {createdAt}
      </div>
    </Link>
  );
};
