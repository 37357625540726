import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'config/routes';
import { ButtonLink } from 'components/Button/ButtonLink';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/thick-tick.svg';
import { commonMessages } from 'intl/commonMessages';

import styles from './SuccessScreen.module.scss';

export interface SuccessScreenProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ title, description }) => (
  <div className={styles.page}>
    <div className={styles.iconWrapper}>
      <CheckmarkIcon className={styles.icon} />
    </div>
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.description}>{description}</p>
    <ButtonLink to={ROUTES.LOGIN}>
      <FormattedMessage {...commonMessages.loginLink} />
    </ButtonLink>
  </div>
);
