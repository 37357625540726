import React from 'react';
import { Discipline } from 'types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Tag } from 'components/Tag/Tag';

import styles from './ExpertiseItem.module.scss';

export interface ExpertiseItemProps {
  expertise: Discipline;
  onRemoveClick: (expertise: Discipline) => void;
}

export const ExpertiseItem: React.FC<ExpertiseItemProps> = ({ expertise, onRemoveClick }) => (
  <li className={styles.expertise}>
    <Tag className={styles.expertiseTag}>
      {expertise.attributes.name}
      <button type="button" className={styles.button} onClick={() => onRemoveClick(expertise)}>
        <CloseIcon className={styles.icon} />
      </button>
    </Tag>
  </li>
);
