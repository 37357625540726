export const CookiePolicyStatements = [
  {
    title: 'What are cookies?',
    content:
      '<p>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a "session cookie") or for multiple repeat visits (using a "persistent cookie").</p><br />' +
      '<p>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.</p><br />' +
      '<p>Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting our Website or using our Services.</p><br />' +
      '<p>Cookies may be set by the Website ("first-party cookies"), or by third parties, such as those who serve content or provide advertising or analytics services on the website ("third party cookies"). These third parties can recognize you when you visit our website and also when you visit certain other websites.</p>',
  },
  {
    title: 'What type of cookies do we use?',
    content:
      '<p><strong>Necessary cookies</strong></p><br />' +
      '<p>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our Website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account to access the content.</p><br />' +
      '<p><strong>Functionality cookies</strong></p><br />' +
      '<p>Functionality cookies let us operate the Website and our Services in accordance with the choices you make. For example, we will recognize your username and remember how you customized the Website and Services during future visits.</p><br />' +
      '<p><strong>Performance And Analytics cookies</strong></p><br />' +
      '<p>Cookies help us analyze how the Services are being accessed and used, and enable us to track performance of the Services. For example, we use cookies to determine if you viewed a page or opened an email. This helps us provide you with information that you find interesting.</p>',
  },
  {
    title: 'Third Parties',
    content:
      'Third Party Services may use cookies to help you sign into their services from our Services. Any such third party cookie usage is governed by the policy of the third party placing the cookie.',
  },
  {
    title: 'Netlify and Heroku Dev Center Ads',
    content:
      'We work with a third party publishers, ad networks and service providers to manage our website. Our third party, Netlify and Heroku Dev Center, may place cookies on your device or browser to collect information about your activities on the Services and other websites you visit, to show you Netlify and Heroku Dev Center ads. For example, if you visit our site and also use a social media platform, you may see a Netlify and Heroku Dev Center ad in your feed or in your social media timeline. See <a href="https://www.netlify.com/privacy/" target="_blank">Netlify Privacy Policy</a> and <a href="https://www.heroku.com/policy/security/" target="_blank">Heroku Dev Center Privacy Policy</a>.',
  },
  {
    title: 'What are your cookie options?',
    content:
      'If you don\'t like the idea of cookies or certain types of cookies, you can change your browser\'s settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this or to learn more about cookies, visit <a href="https://www.internetcookies.org" target="_blank">internetcookies.org</a>' +
      '<br /><br />Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features our Website and Services offer.',
  },
  {
    title: 'Changes and amendments',
    content:
      'We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.',
  },
  {
    title: 'Device Identifiers.',
    content:
      'We use device identifiers on Netlify and Heroku Dev Center web and mobile applications to track, analyze and improve the performance of the Services and our ads.',
  },
  {
    title: 'Acceptance of this policy',
    content:
      'You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.',
  },
  {
    title: 'Contacting us',
    content:
      'If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to our use of cookies, you may send an email to <a href="mailto:iod@poloniumfoundation.org">iod@poloniumfoundation.org</a>',
  },
];
