import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'components/Button/Button';

import styles from './ActionsDropdown.module.scss';

export const ActionsDropdown: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prevIsOpen: boolean) => !prevIsOpen);
  };

  return (
    <>
      {isOpen && <div className={styles.overlayTransparent} onClick={toggleIsOpen} />}
      <div className={styles.container}>
        <Button onClick={toggleIsOpen} className={styles.dropdownButton}>
          ...
        </Button>
        <ul
          className={classnames(styles.dropdown, { [styles.open]: isOpen })}
          onClick={toggleIsOpen}
        >
          {children}
        </ul>
      </div>
    </>
  );
};
