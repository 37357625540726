import React from 'react';

import styles from './CategoryTitle.module.scss';

export interface CategoryTitleProps {
  name: string;
  disciplinesCount: number;
}

export const CategoryTitle: React.FC<CategoryTitleProps> = ({ name, disciplinesCount }) => (
  <h4 className={styles.title}>
    {name}
    <span className={styles.highlighted}> ({disciplinesCount})</span>
  </h4>
);
