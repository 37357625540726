import React from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { ValueType } from 'react-select';
import { useGrantNames } from 'helpers';
import { commonMessages } from 'intl/commonMessages';
import { SelectField } from 'components/Autocomplete/SelectField';
import { OptionType } from 'components/Autocomplete/types';
import { DeserializedGrantInstitution } from 'types';

import { RemoveButton } from '../';

import { GrantsForm } from './GrantBox';
import styles from './GrantBox.module.scss';

export interface GrantFieldProps {
  index: number;
  institutions: DeserializedGrantInstitution[];
  onRemove?: (index: number) => void;
}

export const GrantField: React.FC<GrantFieldProps> = ({ index, institutions, onRemove }) => {
  const { formatMessage } = useIntl();
  const { getGrantNames, grantNames, areNamesLoading } = useGrantNames();
  const { setFieldValue, getFieldProps }: FormikContextType<GrantsForm> = useFormikContext();

  const onProviderSelect = (option: ValueType<OptionType>, index: number) => {
    setFieldValue(`grants[${index}]name`, '');
    option &&
      institutions.map(institution => institution.value).includes((option as OptionType).value) &&
      getGrantNames((option as OptionType).value);
  };

  const onMenuOpen = () => {
    const option = getFieldProps(`grants[${index}]provider`).value;
    const insitutionId = institutions
      .find(institution => institution.label === option.label)
      ?.value.toString();
    option && getGrantNames(insitutionId);
  };

  return (
    <div className={styles.formRow}>
      {onRemove && <RemoveButton onClick={() => onRemove(index)} />}
      <SelectField
        name={`grants[${index}]provider`}
        onFieldChange={(value: ValueType<OptionType>) => onProviderSelect(value, index)}
        options={institutions}
        label={formatMessage(commonMessages.grantProviderLabel)}
        placeholder={formatMessage(commonMessages.grantProviderPlaceholder)}
        className={styles.provider}
        isCreatable
      />
      <SelectField
        name={`grants[${index}]name`}
        options={grantNames}
        onMenuOpen={onMenuOpen}
        isDisabled={areNamesLoading || !getFieldProps(`grants[${index}]provider`).value}
        label={formatMessage(commonMessages.grantNameLabel)}
        placeholder={formatMessage(
          getFieldProps(`grants[${index}]provider`).value
            ? commonMessages.grantNamePlaceholder
            : commonMessages.grantNameWithoutInstitutionPlaceholder
        )}
        className={styles.name}
        isCreatable
      />
    </div>
  );
};
