import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  searchLabel: {
    id: 'SearchGrantBar.searchLabel',
    defaultMessage: 'Look for scholars',
    description: 'Label for search input',
  },
  searchPlaceholder: {
    id: 'SearchGrantBar.searchPlaceholder',
    defaultMessage: 'Name, affiliation, location or keyword',
    description: 'Placeholder for search query',
  },
  searchInstructions: {
    id: 'SearchGrantBar.searchInstructions',
    defaultMessage: 'Use a comma to separate the keywords',
    description: 'Instructions for search query',
  },
});
