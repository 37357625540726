import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import styles from '../ResetPassword.module.scss';

export interface RequirementListItemProps {
  messageID: string;
  highlightedID: string;
  extraValues?: {
    [key: string]: any;
  };
}
export const RequirementListItem: React.FC<RequirementListItemProps> = ({
  messageID,
  highlightedID,
  extraValues,
}) => (
  <li className={styles.listItem}>
    <FormattedMessage
      {...messages[messageID]}
      values={{
        details: (
          <strong>
            <FormattedMessage {...messages[highlightedID]} values={extraValues} />
          </strong>
        ),
      }}
    />
  </li>
);
