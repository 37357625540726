import React from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { GrantsList } from 'modules/grants/pages/GrantsList/GrantsList';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';

export const GrantsRoutes: React.FC<RouteProps> = () => (
  <Switch>
    <ProtectedRoute exact path={ROUTES.GRANTS} component={GrantsList} />
  </Switch>
);
