import React from 'react';
import classnames from 'classnames';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
  className?: string;
  isLarge?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ className, isLarge }) => (
  <div className={classnames(styles.wrapper, className)}>
    <div className={classnames(styles.spinner, { [styles.isLarge]: isLarge })} />
  </div>
);
