import React, { useState, useCallback } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'config/routes';
import { useApi } from 'api/ApiContext';
import { useNotification } from 'modules/notifications/NotificationContext';
import { useApiErrorHandler } from 'helpers';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import background from 'assets/images/auth-bg-shapes.jpg';

import { EmailConfirmationInstruction } from '../../components/EmailConfirmationInstruction/EmailConfirmationInstruction';

import { messages } from './messages';
import styles from './ForgotPasswordConfirmation.module.scss';

export const ForgotPasswordConfirmation: React.FC = () => {
  const { state } = useLocation();
  const [isResending, setResending] = useState<boolean>(false);
  const { auth } = useApi();
  const handleError = useApiErrorHandler();
  const { createNotification } = useNotification();

  const email = state && state.email;

  const resendLink = useCallback(async () => {
    setResending(true);
    try {
      await auth.forgotPassword(email);
      createNotification({
        type: 'success',
        message: <FormattedMessage {...messages.resendSuccess} />,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setResending(false);
    }
  }, [auth, email, createNotification, handleError]);

  if (!email) return <Redirect to={ROUTES.FORGOT_PASSWORD} />;

  return (
    <AuthLayout
      title={<FormattedMessage {...messages.header} />}
      description={
        <FormattedMessage
          {...messages.description}
          values={{ email: <span className={styles.email}>{email}</span> }}
        />
      }
      background={background}
      classNames={{ title: styles.title, description: styles.description }}
    >
      <EmailConfirmationInstruction onClick={resendLink} isProcessing={isResending} />
    </AuthLayout>
  );
};
