import React from 'react';
import { useField } from 'formik';
import { TextField } from 'components/TextField/TextField';
import { SelectField } from 'components/Autocomplete/SelectField';
import { OptionType } from 'components/Autocomplete/types';

import { RemoveButton } from '../';

import styles from './SocialMediaBox.module.scss';

export interface SocialMediumFieldProps {
  onRemove: (index: number) => void;
  index: number;
}

export const SocialMediumField: React.FC<SocialMediumFieldProps> = ({ onRemove, index }) => {
  const [{ value: platform }] = useField(`media[${index}]platform`);
  const [{ value: url }] = useField(`media[${index}]url`);
  const platformName = platform.label;

  return (
    <div className={styles.formRow}>
      {onRemove && <RemoveButton onClick={() => onRemove(index)} />}
      <TextField name={`media[${index}]url`} label={platformName} value={url} />
    </div>
  );
};

export interface NewSocialMediumFieldProps {
  index: number;
  platforms: OptionType[];
}

export const NewSocialMediumField: React.FC<NewSocialMediumFieldProps> = ({ index, platforms }) => (
  <div className={styles.newField}>
    <SelectField
      name={`media[${index}]platform`}
      label="Platform name"
      options={platforms}
      placeholder="Pick a medium from the list"
    />
    <TextField
      name={`media[${index}]url`}
      label="Paste a link to your profile to selected social media"
      placeholder="e.g. https://github.com/johndoe"
    />
  </div>
);
