import userPhoto from 'assets/images/user-stories-photo.jpg';

export const mockResidence = {
  data: {
    attributes: {
      name: 'Warsaw, Poland',
      latitude: 12.12,
      longitude: 6.2,
    },
  },
};

export const mockDeserializedResidence = {
  residence: {
    name: 'Warsaw, Poland',
    lat: 12.12,
    lng: 6.2,
  },
  city: 'Warsaw, Poland',
  coordinates: { lat: 12.12, lng: 6.2 },
};

export const mockAffiliations = {
  data: [
    {
      attributes: {
        academicTitle: {
          data: {
            attributes: {
              name: 'Administration',
            },
          },
        },
        institution: {
          data: {
            attributes: {
              name: 'University of Warsaw',
            },
          },
        },
      },
    },
  ],
};

export const mockDeserializedAffiliations = [
  {
    position: {
      attributes: {
        name: 'Administration',
      },
      label: 'Administration',
      value: 'Administration',
    },
    institution: {
      attributes: {
        name: 'University of Warsaw',
      },
      label: 'University of Warsaw',
      value: 'University of Warsaw',
    },
  },
];

export const mockProfile = {
  firstName: 'Małgorzata',
  lastName: 'Iwanowicz-Hamerszmit',
  affiliations: mockAffiliations,
  currentResidence: mockResidence,
  avatarUrl: userPhoto,
  hiddenProfile: false,
  newsletterAccepted: true,
  email: 'malgorzataheba@gmail.com',
  about:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  disciplines: {
    data: [
      {
        id: '2',
        type: 'discipline',
        attributes: {
          id: 2,
          name: 'Aerospace engineering',
        },
      },
    ],
  },
  institutions: {
    data: [
      {
        id: '2',
        type: 'institutions',
        attributes: {
          id: 2,
          name: 'Warsaw Uni',
        },
      },
      {
        id: '2',
        type: 'institutions',
        attributes: {
          id: 2,
          name: 'Polish Academy',
        },
      },
    ],
  },
  educations: {
    data: [
      {
        attributes: {
          degrees: {
            data: [
              {
                attributes: {
                  name: 'Balchelor of applied sciences',
                },
              },
            ],
          },
          institution: {
            data: {
              id: '2',
              attributes: {
                id: 2,
                name: 'University of Warsaw',
              },
            },
          },
        },
      },
      {
        attributes: {
          degrees: {
            data: [
              {
                attributes: {
                  name: 'Balchelor of applied sciences',
                },
              },
            ],
          },
          institution: {
            data: {
              id: '2',
              attributes: {
                id: 2,
                name: 'A.S. Pushkin Brest State University',
              },
            },
          },
        },
      },
    ],
  },
  grants: {
    data: [
      {
        attributes: {
          grantName: {
            data: {
              attributes: {
                id: 2,
                name: 'Research Fellowships in Humanities and Social Science',
              },
            },
          },
          grantInstitution: {
            data: {
              attributes: {
                id: 2,
                name: 'Welcome trust',
              },
            },
          },
        },
      },
      {
        attributes: {
          grantName: {
            data: {
              id: '2',
              attributes: {
                id: 2,
                name: 'Individual Fellowship',
              },
            },
          },
          grantInstitution: {
            data: {
              id: '2',
              attributes: {
                id: 2,
                name: 'Marie Skłodowska-Curie Actions',
              },
            },
          },
        },
      },
    ],
  },
  socialMedia: {
    data: [
      {
        attributes: {
          platform: 'GitHub',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Academia_edu',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Mendeley',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Research_gate',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Twitter',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Youtube',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Instagram',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'LinkedIn',
          url: '',
        },
      },
      {
        attributes: {
          platform: 'Facebook',
          url: '',
        },
      },
    ],
  },
  currentStatus: {
    data: {
      attributes: {
        isVisible: true,
        statement:
          'an Assistant to work with me in Harfe Instrumentalstudium. Please contact me directly.',
        prefix: 'I need',
      },
    },
  },
};

export const mockSerializedProfile = {
  data: { id: 123, type: 'user', attributes: mockProfile },
};

export const mockDeserializedProfile = {
  ...mockProfile,
  affiliations: mockDeserializedAffiliations,
  currentResidence: mockDeserializedResidence,
};

export const mockProfileUserEmpty = {
  firstName: 'Małgorzata',
  lastName: 'Iwanowicz-Hamerszmit',
  affiliations: mockDeserializedAffiliations,
  currentResidence: mockDeserializedResidence,
  avatarUrl: null,
  about: '',
  disciplines: {
    data: [
      {
        id: '2',
        attributes: {
          id: 2,
          name: 'Aerospace engineering',
        },
      },
    ],
  },
  institutions: {
    data: [],
  },
  educations: {
    data: [],
  },
  grants: {
    data: [],
  },
  socialMedia: {
    data: [],
  },
  currentStatus: {
    data: {
      attributes: {
        isVisible: true,
        statement: '',
        prefix: 'I need',
      },
    },
  },
};

export const mockPositions = [
  {
    id: '1',
    type: 'position',
    attributes: { id: 1, name: 'Professor' },
    label: 'Professor',
    value: 1,
  },
  {
    id: '2',
    type: 'position',
    attributes: { id: 2, name: 'Administration' },
    label: 'Administration',
    value: 2,
  },
  {
    id: '3',
    type: 'position',
    attributes: { id: 3, name: 'Graduate Student' },
    label: 'Graduate Student',
    value: 3,
  },
];

export const mockInstitutions = [
  {
    id: '1',
    type: 'institution',
    attributes: { id: 1, name: 'Harvard University' },
    label: 'Harvard University',
    value: 1,
  },
  {
    id: '2',
    type: 'institution',
    attributes: { id: 2, name: 'Cambridge University' },
    label: 'Cambridge University',
    value: 2,
  },
  {
    id: '3',
    type: 'institution',
    attributes: { id: 3, name: 'Oxford University' },
    label: 'Oxford University',
    value: 3,
  },
];

export const mockStatusOptions = ['I need', 'I want', 'I am looking'];
export const mockSocialMediaPlatforms = ['LinkedIn', 'Facebook', 'Google Scholar'];
