import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  poloniumTitle: {
    id: 'Home.poloniumTitle',
    defaultMessage: 'About Polonium Foundation',
    description: 'About Polonium title',
  },
  poloniumSubtitle: {
    id: 'Home.poloniumSubtitle',
    defaultMessage: 'Find out more about what Polonium Foundation is',
    description: 'About Polonium subtitle',
  },
});
