import React from 'react';
import classnames from 'classnames';
import ReactSelect, { ValueType } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import { useField } from 'formik';
import { InputFieldProps } from 'components/InputField/InputField';
import { FieldError } from 'components/FieldError/FieldError';

import { OptionType } from './types';

import './Autocomplete.scss';

export interface SelectProps<T> extends ReactSelectProps<T>, CreatableProps<T> {
  label: React.ReactChild;
  isCreatable?: boolean;
  isIndicatorVisible?: boolean;
}

export const Select: React.FC<SelectProps<OptionType>> = ({
  label,
  isIndicatorVisible,
  isValid,
  isInvalid,
  clearButton,
  isCreatable,
  ...props
}) => (
  <div
    className={classnames('select-container', {
      valid: isValid,
      invalid: isInvalid,
      isIndicatorVisible: isIndicatorVisible,
    })}
  >
    <div className="select-container_inner">
      {clearButton && <div className="select-container__clear-button">{clearButton}</div>}
      <label>
        <span className="select-container__label">{label}</span>
        {isCreatable ? (
          <CreatableSelect isClearable classNamePrefix="react-select" {...props} />
        ) : (
          <ReactSelect isClearable classNamePrefix="react-select" {...props} />
        )}
      </label>
    </div>
  </div>
);

export interface SelectFieldProps<T>
  extends SelectProps<T>,
    Omit<InputFieldProps, keyof SelectFieldProps<T>> {
  name: string;
}

export const SelectField: React.FC<SelectFieldProps<OptionType>> = ({
  name,
  isIndicatorVisible,
  onFieldChange,
  className,
  errorClassName,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({ name });

  const handleChange = (option: ValueType<OptionType>) => {
    setValue(option || '');
    if (onFieldChange) {
      onFieldChange(option);
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const hasError = touched && !!error;

  return (
    <div className={classnames('autocomplete-container', className)}>
      <Select
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!error && touched}
        isInvalid={hasError}
        value={value}
        isIndicatorVisible={isIndicatorVisible}
        {...props}
      />
      {hasError && <FieldError name={name} className={errorClassName} />}
    </div>
  );
};
