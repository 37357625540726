import React, { useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/magnifier.svg';
import { TextField } from 'components/TextField/TextField';
import { FormattedMessage, useIntl } from 'react-intl';

import { GrantListFilterValues } from '../../../../types';

import styles from './SearchGrantBar.module.scss';
import { messages } from './messages';

interface SearchBarProps {
  name: string;
  id: string;
  values: GrantListFilterValues;
  onSearchSubmit: (values: GrantListFilterValues) => void;
  query: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const SearchGrantBar: React.FC<SearchBarProps> = ({
  name,
  id,
  values,
  onSearchSubmit,
  query,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (query) {
      setFieldValue(name, query);
    } else {
      setFieldValue(name, '');
    }
  }, [query, setFieldValue, name]);

  return (
    <div className={styles.searchBar} id={id}>
      <p className={styles.searchLabel}>
        <FormattedMessage {...messages.searchLabel} />
      </p>
      <div className={styles.searchBarContainer}>
        <div className={styles.searchIconContainer}>
          <SearchIcon className={styles.searchIcon} />
        </div>
        <TextField
          name={name}
          placeholder={formatMessage(messages.searchPlaceholder)}
          classNames={{ input: styles.searchField, wrapper: styles.searchWrapper }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSearchSubmit(values);
            }
          }}
        />
      </div>
    </div>
  );
};
