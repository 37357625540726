import React from 'react';
import classnames from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import styles from './Modal.module.scss';

ReactModal.setAppElement('body');

export interface ModalProps extends ReactModalProps {
  onClose: () => void;
  modalClassName?: string;
  header?: React.ReactNode;
  isLarge?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  modalClassName,
  header,
  isLarge,
  children,
  ...props
}) => (
  <ReactModal
    isOpen={isOpen}
    shouldCloseOnEsc={true}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
    bodyOpenClassName="scroll-lock desktop-lock"
    className={classnames(styles.modal, { [styles.large]: isLarge }, modalClassName)}
    {...props}
  >
    {header && (
      <header className={styles.headerBar}>
        <h2 className={styles.header}>{header}</h2>
        <button onClick={onClose} className={styles.button} aria-label="close modal">
          <CloseIcon className={styles.icon} />
        </button>
      </header>
    )}
    <div className={styles.container}>{children}</div>
  </ReactModal>
);
