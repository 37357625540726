import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IconButton } from 'components/Button/IconButton/IconButton';

import { messages } from '../messages';

export interface AffiliationActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  hasSingleAffiliation: boolean;
  onAdd: () => void;
  onRemove: () => void;
}

export const AffiliationActionButton: React.FC<AffiliationActionButtonProps> = ({
  hasSingleAffiliation,
  onAdd,
  onRemove,
}) => {
  return (
    <IconButton
      icon={hasSingleAffiliation ? AddIcon : DeleteIcon}
      onClick={hasSingleAffiliation ? onAdd : onRemove}
      type="button"
    >
      <FormattedMessage
        {...messages[hasSingleAffiliation ? 'add2Affiliation' : 'removeAffiliation']}
      />
    </IconButton>
  );
};
