import React from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from 'types';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'config/routes';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { UserPhoto } from 'components/UserPhoto/UserPhoto';
import { generateRouteWithParam } from 'helpers';

import { DisciplinesContainer } from '../DisciplinesContainer/DisciplinesContainer';

import { messages } from './messages';
import styles from './ProfileCard.module.scss';

export const ProfileCard: React.FC<UserProfile> = ({
  id,
  attributes: {
    firstName,
    lastName,
    avatarUrl,
    currentResidence,
    affiliations,
    disciplines,
    currentStatus,
  },
}) => {
  const currentResidenceName = currentResidence.data?.attributes?.name || '';
  const firstAffiliationAttrs = affiliations.data[0]?.attributes;
  const academicTitleName = firstAffiliationAttrs?.academicTitle?.data?.attributes?.name || '';
  const institutionName = firstAffiliationAttrs?.institution?.data?.attributes?.name || '';
  const currentStatusAttributes = currentStatus.data?.attributes;
  const currentStatusPrefix = currentStatusAttributes?.prefix || '';
  const currentStatusStatement = currentStatusAttributes?.statement || '';
  const currentStatusIsVisible = currentStatusAttributes?.isVisible;

  return (
    <Link to={generateRouteWithParam(ROUTES.PROFILE, id)} className={styles.wrapper}>
      <div className={styles.basicUserInfoArea}>
        <UserPhoto photo={avatarUrl} className={styles.avatar} />
        <div className={styles.heading}>
          <h5 className={styles.name}>
            {firstName} {lastName}
          </h5>
          <span className={styles.profileLink}>
            <FormattedMessage {...messages.viewProfileLink} />
          </span>
        </div>
        <div className={styles.details}>
          <p className={styles.affiliation}>
            <span className={styles.affiliationName}>{academicTitleName}</span>
            <FormattedMessage {...messages.institutionInfo} values={{ institutionName }} />
          </p>
          <p className={styles.residence}>
            <span className={styles.residenceLabel}>
              <Location className={styles.icon} />
              <FormattedMessage {...messages.currentResidenceLabel} />
            </span>
            <span className={styles.residenceLocation}>{currentResidenceName}</span>
          </p>
        </div>
      </div>
      {currentStatusStatement && currentStatusIsVisible && (
        <p className={styles.status}>
          {currentStatusPrefix} {currentStatusStatement}
        </p>
      )}
      <DisciplinesContainer {...disciplines} />
    </Link>
  );
};
