import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import image from 'assets/images/1.png';

import { HeroSection } from '../components/HeroSection/HeroSection';
import { PoloniumSection } from '../components/PoloniumSection/PoloniumSection';
import { NewsletterSection } from '../components/NewsletterSection/NewsletterSection';
import { CookieModal } from '../../../components/Modal/CookieModal/CookieModal';

import { HelpSection } from './components/HelpSection/HelpSection';
import { GainSection } from './components/GainSection/GainSection';
import { TestimonialsSection } from './components/TestimonialsSection/TestimonialsSection';
import { HeroStats } from './components/HeroStats/HeroStats';
import { messages } from './messages';

export const Home: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <CookieModal />
      <HeroSection
        backgroundImage={image}
        heroTitle={<FormattedMessage {...messages.heroTitle} />}
        heroMessage={<FormattedMessage {...messages.heroMessage} />}
        heroMessage2={<FormattedMessage {...messages.heroMessage2} />}
        heroMessageEmphasized={<FormattedMessage {...messages.heroMessageEmphasized} />}
      >
        <HeroStats />
      </HeroSection>

      <HelpSection />
      <GainSection />
      <TestimonialsSection />
      <PoloniumSection />
      <NewsletterSection />
    </>
  );
};
