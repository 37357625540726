import { AxiosResponse } from 'axios';
import { DeserializedGrant, Grant } from 'types/grant';

import { ResourceClient } from './ResourceClient';

export class GrantsClient extends ResourceClient {
  getGrants(
    query?: string
    // page?: number,
    // items?: number,
    // search?: string,
    // countries?: string[],
    // stages?: string[]
  ): Promise<AxiosResponse<Grant>> {
    return this.client.get<Grant>(query ? `/grants${query}` : '/grants');
  }

  getGrant(id: string): Promise<AxiosResponse<{ data: { attributes: DeserializedGrant } }>> {
    return this.client.get<{ data: { attributes: DeserializedGrant } }>(`/grants/${id}`);
  }
}
