import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  comprehensiveListMessage: {
    id: 'ComprehensiveListMessage.text',
    defaultMessage:
      'We did all in our power to make this list as comprehensive as possible, but if you would like to add a grant from your institution that is not currently on the list, please',
    description: 'Message for comprehensive list and contact invitation',
  },
  ministryGrantMessage: {
    id: 'MinistryGrantMessage.text',
    defaultMessage:
      'Zadanie publiczne współfinansowane ze środków Ministerstwa Edukacji i Nauki otrzymanych w 2023 r. w ramach konkursu „Organizowanie i animowanie działań na rzecz środowiska akademickiego”.',
    description:
      'Message indicating public task co-financed by the Ministry of Education and Science funds received in 2023 as part of the competition "Organizing and animating activities for the academic community"',
  },
  results: {
    id: 'SearchContainer.results',
    defaultMessage: 'Results available',
    description: 'Results',
  },
  searchLabelClear: {
    id: 'SearchContainer.searchLabelClear',
    defaultMessage: 'Clear all',
    description: 'Clear button text for location input',
  },
  submitButton: {
    id: 'SearchContainer.submitButton',
    defaultMessage: 'Filter',
    description: 'Filter',
  },
  cancelButton: {
    id: 'SearchContainer.cancelButton',
    defaultMessage: 'CANCEL',
    description: 'Cancel',
  },
});
