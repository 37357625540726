import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { IconButton } from 'components/Button/IconButton/IconButton';

export const EditButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...props }) => (
  <IconButton icon={EditIcon} {...props}>
    <FormattedMessage {...commonMessages.editButton} />
  </IconButton>
);
