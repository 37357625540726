import React from 'react';
import { LinkProps, Link } from 'react-router-dom';
import classnames from 'classnames';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

import styles from './Button.module.scss';

interface ButtonProps extends LinkProps {
  light?: boolean;
  secondary?: boolean;
  small?: boolean;
  block?: boolean;
  icon?: React.ReactNode;
  reversed?: boolean;
  className?: string;
  iconClassName?: string;
}

export const ButtonLink: React.FC<ButtonProps> = ({
  to,
  secondary,
  children,
  block,
  light,
  small,
  icon,
  reversed,
  className,
  iconClassName,
  ...props
}) => {
  const buttonClassNames = classnames(
    styles.button,
    {
      [styles.light]: light,
      [styles.outline]: secondary,
      [styles.small]: small,
      [styles.block]: block,
      [styles.reversed]: reversed,
    },
    className
  );

  const iconClassNames = classnames(styles.icon, iconClassName);

  return (
    <Link className={buttonClassNames} to={to} {...props}>
      <span>{children}</span>
      {icon && <span className={iconClassNames}>{icon}</span>}
    </Link>
  );
};

export const BackButtonLink: React.FC<ButtonProps> = ({ children, ...props }) => (
  <ButtonLink secondary={true} reversed icon={<Arrow />} {...props}>
    {children}
  </ButtonLink>
);

export const NextButtonLink: React.FC<ButtonProps> = ({ children, ...props }) => (
  <ButtonLink secondary={true} icon={<Arrow />} {...props}>
    {children}
  </ButtonLink>
);
