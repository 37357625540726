import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';
import { object } from 'yup';
import { useApi } from 'api/ApiContext';
import { serializeLocation } from 'api/serializers';
import { validators } from 'config/errors/validators';
import { ROUTES } from 'config/routes';
import { useApiErrorHandler } from 'helpers';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { LocationField, OptionType } from 'components/Autocomplete';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/TextField/TextField';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { YearDropdown } from 'components/YearDropdown/YearDropdown';
import { CheckboxField } from 'components/CheckboxField/CheckboxField';
import { AuthNavbar } from 'components/AuthLayout/AuthNavbar/AuthNavbar';
import { commonMessages } from 'intl/commonMessages';
import { FormikValueSetter, SocialMediaRegistration, SocialMediaAttributes, Location } from 'types';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { messages } from './messages';
import styles from './Signup.module.scss';
import { SignUpSocialMediumField } from './components/SocialMediaFieldForSignup/SignUpSocialMediaField';

interface YearOfBirth {
  value: string;
}

interface SignupForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  yearOfBirthInput: YearOfBirth;
  yearOfBirth: string;
  birthPlace: Location;
  birthPlaceAttributes: any; // TODO type this
  socialMedia: SocialMediaRegistration[];
  socialMediaAttributes: SocialMediaAttributes[];
  termsAccepted: boolean;
  newsletterAccepted?: boolean;
}

const validationSchema = object().shape({
  firstName: validators.firstName,
  lastName: validators.lastName,
  email: validators.email,
  password: validators.password,
  passwordConfirmation: validators.passwordConfirmation,
  termsAccepted: validators.termsAccepted,
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  termsAccepted: false,
  yearOfBirth: '',
  yearOfBirthInput: { value: '' },
  birthPlace: { name: '', lat: 0, lng: 0 },
  birthPlaceAttributes: {},
  socialMedia: [],
  socialMediaAttributes: [],
  newsletterAccepted: false,
};

export const Signup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { auth } = useApi();
  const { push } = useHistory();
  const [platforms, setPlatforms] = useState<OptionType[]>([]);
  const handleError = useApiErrorHandler();
  const { profile } = useApi();

  useEffect(() => {
    const getSocialMediaPlatforms = async () => {
      try {
        const { data } = await profile.getSocialMediaPlatforms();
        setPlatforms(data.map(value => ({ label: value, value })));
      } catch (error) {
        handleError(error);
      }
    };

    getSocialMediaPlatforms();
  }, [handleError, profile]);

  const handleSubmit = async (values: SignupForm, actions: FormikHelpers<SignupForm>) => {
    const signUpValues = { ...values };
    signUpValues.yearOfBirth = values.yearOfBirthInput.value;
    if (values.birthPlace.name !== '') {
      signUpValues.birthPlaceAttributes = serializeLocation(values.birthPlace);
    } else {
      signUpValues.birthPlaceAttributes = null;
    }

    signUpValues.socialMediaAttributes = values.socialMedia.map(({ platform, url }) => ({
      platform: platform.value.toLowerCase(),
      url,
    }));

    try {
      await auth.signUp(signUpValues);

      push({
        pathname: ROUTES.ACCOUNT_CONFIRMATION,
        state: { email: values.email },
      });
    } catch (error) {
      handleError(error);
      actions.setSubmitting(false);
    }
  };

  const handleLocationInputChange = (value: string, setFieldValue: FormikValueSetter) => {
    setFieldValue('city', value);
    setFieldValue('coordinates', null);
    setFieldValue('birthPlace', null);
  };

  const handleLocationSelect = async (value: string, setFieldValue: FormikValueSetter) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setFieldValue('city', value);
    setFieldValue('coordinates', latLng);
    setFieldValue('birthPlace', { name: value, ...latLng });
  };

  // let institutions: [];

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <AuthLayout
        description={formatMessage(messages.description)}
        title={formatMessage(messages.header)}
        withPhotoBackground={true}
        authNavbar={
          <AuthNavbar
            path={ROUTES.LOGIN}
            description={formatMessage(messages.authNavbarDescription)}
            link={formatMessage(messages.authNavbarLink)}
          />
        }
      >
        <Formik<SignupForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form} noValidate>
              <div className={styles.names}>
                <TextField
                  name="firstName"
                  label={formatMessage(commonMessages.firstNameLabel) + ' *'}
                  placeholder={formatMessage(commonMessages.firstNamePlaceholder)}
                  classNames={{ wrapper: styles.firstName }}
                />
                <TextField
                  name="lastName"
                  label={formatMessage(commonMessages.lastNameLabel) + ' *'}
                  placeholder={formatMessage(commonMessages.lastNamePlaceholder)}
                  classNames={{ wrapper: styles.lastName }}
                />
              </div>
              <TextField
                name="email"
                type="email"
                label={formatMessage(commonMessages.emailLabel) + ' *'}
                placeholder={formatMessage(commonMessages.emailPlaceholder)}
              />
              <PasswordField
                name="password"
                label={formatMessage(commonMessages.passwordLabel) + ' *'}
                prompt={formatMessage(commonMessages.passwordPrompt)}
                placeholder={formatMessage(commonMessages.passwordPlaceholder)}
              />
              <PasswordField
                name="passwordConfirmation"
                label={formatMessage(commonMessages.passwordConfirmationLabel) + ' *'}
                placeholder={formatMessage(commonMessages.passwordPlaceholder)}
              />
              <YearDropdown name="yearOfBirthInput" />
              <div className="autocomplete-container">
                <LocationField
                  name="city"
                  residenceFieldName="birthPlace"
                  onSelect={handleLocationSelect}
                  onChange={handleLocationInputChange}
                  label="Place of birth *"
                  placeholder="Select a city of your birth"
                />
                <p>not visible for other users</p>
              </div>
              <SignUpSocialMediumField platforms={platforms} />
              <CheckboxField
                name="termsAccepted"
                label={formatMessage(commonMessages.termsOfServiceLabelRequired, {
                  termsOfServiceLink: (
                    <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank">
                      {formatMessage(commonMessages.termsOfServiceLink)}
                    </Link>
                  ),
                  privacyPolicyLink: (
                    <Link to={ROUTES.PRIVACY} target="_blank">
                      {formatMessage(commonMessages.privacyPolicyLink)}
                    </Link>
                  ),
                })}
              />
              <CheckboxField
                name="newsletterAccepted"
                label={formatMessage(messages.newsletterLabel)}
              />
              <p>* Required fields</p>
              <Button
                type="submit"
                className={styles.button}
                isProcessing={isSubmitting}
                disabled={isSubmitting}
              >
                {formatMessage(messages.createAccount)}
              </Button>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
};
