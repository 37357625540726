import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { Loading } from 'components/Loading/Loading';
import { JobOffer } from 'types/jobOffer';
import { ROUTES } from 'config/routes';

import { JobOfferCard } from './components/JobOfferCard/JobOfferCard';
import { messages } from './messages';
import styles from './JobOffersList.module.scss';

export const JobOffersList: React.FC = () => {
  const { formatMessage } = useIntl();
  const api = useApi();
  const handleError = useApiErrorHandler();

  const [jobOffersList, setJobOffersList] = useState<JobOffer[]>([]);
  const [isFetchingOffers, setIsFetchingOffers] = useState<boolean>(true);

  useEffect(() => {
    const getJobOffers = async () => {
      setIsFetchingOffers(true);

      try {
        const { data } = await api.jobOffers.getJobOffers();
        setJobOffersList(data.data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingOffers(false);
      }
    };

    getJobOffers();
  }, [api.jobOffers, handleError]);

  return (
    <section className={styles.wrapper}>
      <Helmet title={formatMessage(messages.pageName)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <h1 className={styles.headingTitle}>
            <FormattedMessage {...messages.pageTitle} />
          </h1>

          <h5 className={styles.headingSubtitle}>
            <FormattedMessage {...messages.headingSubtitle} />
          </h5>

          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>

          <a href={ROUTES.PARTNERSHIP} className={styles.postOfferButton}>
            <FormattedMessage {...messages.postOfferButton} />
          </a>
        </div>
      </section>

      <section className={styles.jobs}>
        <h2 className={styles.pageTitle}>
          <FormattedMessage {...messages.sectionTitle} />
        </h2>
        <p className={styles.pageSubtitle}>
          <FormattedMessage {...messages.searchInstruction1} />
          <span className={styles.emphasized}>
            <FormattedMessage {...messages.ctrlF} />
          </span>
          <FormattedMessage {...messages.searchInstruction2} />
          <span className={styles.emphasized}>
            <FormattedMessage {...messages.commandF} />
          </span>
          .
        </p>

        <Loading isLarge isLoading={isFetchingOffers} className={styles.spinner}>
          {jobOffersList.map(jobOffer => (
            <JobOfferCard {...jobOffer} key={jobOffer.id} />
          ))}
        </Loading>
      </section>
    </section>
  );
};
