export const PAGE_TITLE = 'Polonium';
export const CONTACT_EMAIL = 'contact@poloniumfoundation.org';
export const PARTNERS_EMAIL = 'partners@poloniumfoundation.org';
export const NETWORK_EMAIL = 'network@poloniumfoundation.org';
export const POLONIUM_FOUNDATION_WEBSITE = 'https://poloniumfoundation.org/';
export const EL_PASSION_WEBSITE = 'https://www.elpassion.com/';
export const POLONIUM_TWITTER = 'https://twitter.com/polonium_org';
export const POLONIUM_FACEBOOK = 'https://www.facebook.com/polonium.org/';
export const POLONIUM_LINKEDIN =
  'https://www.linkedin.com/company/polonium-foundation/?originalSubdomain=pl';
export const POLONIUM_YOUTUBE = 'https://www.youtube.com/channel/UCkinL18xdseF6OxAzkHhaBQ';
export const RESEARCH_WEBSITE = 'https://poloniumfoundation.org/research';
