import React from 'react';
import classnames from 'classnames';
import { Field, FieldConfig, useField } from 'formik';
import { FieldError } from 'components/FieldError/FieldError';

import styles from './InputField.module.scss';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export interface InputFieldProps extends Omit<InputProps, keyof FieldConfig>, FieldConfig {
  label?: React.ReactChild;
  placeholder?: string;
  prompt?: React.ReactChild;
  icon?: React.ReactNode;
  isValid?: boolean;
  isInvalid?: boolean;
  classNames?: {
    wrapper?: string;
    inputWrapper?: string;
    input?: string;
    inputInvalid?: string;
    label?: string;
  };
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  placeholder,
  prompt,
  icon,
  isValid,
  isInvalid,
  classNames = {},
  ...props
}) => {
  const [field, { error, touched }] = useField({ name });
  const hasError = !!error && touched;
  const hasNoError = touched && !error;
  const inputClassNames = classnames(
    styles.input,
    classNames.input,
    {
      [styles.invalid]: isInvalid || hasError,
      [styles.valid]: isValid || hasNoError,
    },
    (isInvalid || hasError) && classNames.inputInvalid
  );

  return (
    <div className={classnames(styles.wrapper, classNames.wrapper)}>
      <label className={classnames(styles.label, classNames.label)} htmlFor={name}>
        {label}
      </label>
      {prompt && <p className={styles.prompt}>{prompt}</p>}
      <div className={classnames(styles.inputWrapper, classNames.inputWrapper)}>
        <Field
          className={inputClassNames}
          id={name}
          name={name}
          placeholder={placeholder}
          {...field}
          {...props}
        />
        {icon && icon}
        {hasError && <FieldError name={name} />}
      </div>
    </div>
  );
};
