import React from 'react';

import styles from './CallToActionSection.module.scss';

export interface CallToActionSectionProps {
  title: React.ReactNode;
  titleHighlighted: React.ReactNode;
}

export const CallToActionSection: React.FC<CallToActionSectionProps> = ({
  title,
  titleHighlighted,
  children,
}) => (
  <section className={styles.section}>
    <h2 className={styles.title}>
      <span className={styles.titleHighlighted}>{titleHighlighted}</span>
      {title}
    </h2>
    <div className={styles.content}>{children}</div>
  </section>
);
