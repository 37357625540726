import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Profile.pageTitle',
    defaultMessage: 'Profile',
    description: 'Profile page name',
  },
  residence: {
    id: 'Profile.residence',
    defaultMessage: 'Current residence:',
    description: 'Residence header text',
  },
  born: {
    id: 'Profile.born',
    defaultMessage: 'Born:',
    description: 'Born header text',
  },
  status: {
    id: 'Profile.status',
    defaultMessage: 'My current status:',
    description: 'Status header text',
  },
  statusPlaceholder: {
    id: 'Profile.statusPlaceholder',
    defaultMessage: 'Write down your current interest',
    description: 'Status header default text',
  },
  hint: {
    id: 'Profile.hint',
    defaultMessage: 'Empty status is hidden for viewers',
    description: 'Status header hint',
  },
  showStatus: {
    id: 'Profile.showStatus',
    defaultMessage: 'Show my status',
    description: 'Show status text',
  },
  hideStatus: {
    id: 'Profile.hideStatus',
    defaultMessage: 'Hide my status',
    description: 'Hide status text',
  },
  aboutTitle: {
    id: 'Profile.aboutTitle',
    defaultMessage: 'About me',
    description: 'About title',
  },
  aboutEmptyDescription: {
    id: 'Profile.aboutEmptyDescription',
    defaultMessage:
      'You don’t have any description yet, please fill this field in order to help others reach to you.',
    description: 'About default description',
  },
  aboutPlaceholder: {
    id: 'Profile.aboutPlaceholder',
    defaultMessage: 'Fill this field to help others reach you...',
    description: 'About field placeholder',
  },
  expertiseTitle: {
    id: 'Profile.expertiseTitle',
    defaultMessage: 'Disciplines',
    description: 'Disciplines title',
  },
  institutionsTitle: {
    id: 'Profile.institutionsTitle',
    defaultMessage: 'Institutions',
    description: 'Institutions title',
  },
  emptyInstitutionsText: {
    id: 'Profile.emptyInstitutionsText',
    defaultMessage:
      'You don’t have any Institution featured in your profile, if you want to add an institution go to {button}',
    description: 'Institutions default text',
  },
  educationTitle: {
    id: 'Profile.educationTitle',
    defaultMessage: 'Education',
    description: 'Education title',
  },
  educationDefaultText: {
    id: 'Profile.educationDefaultText',
    defaultMessage: 'Add your education details here by entering {button}',
    description: 'Education default text',
  },
  grantsTitle: {
    id: 'Profile.grantsTitle',
    defaultMessage: 'Grants & Funding',
    description: 'Grants title',
  },
  grantsDefaultText: {
    id: 'Profile.grantsDefaultText',
    defaultMessage:
      'You don’t have any grants or funding featured in your profile, if you want to add a grant or funding item click the button below.',
    description: 'Grants default text',
  },
  socialMediaTitle: {
    id: 'Profile.socialMediaTitle',
    defaultMessage: 'Social Media',
    description: 'Social media title',
  },
  showMore: {
    id: 'Profile.showMore',
    defaultMessage: 'Show More',
    description: 'Show more button',
  },
  showLess: {
    id: 'Profile.showLess',
    defaultMessage: 'Show Less',
    description: 'Show less button',
  },
  updateHeader: {
    id: 'Profile.updateHeader',
    defaultMessage: 'Edit header',
    description: 'Update affiliation & residence modal header',
  },
  updateExpertises: {
    id: 'Profile.updateExpertises',
    defaultMessage: 'Select disciplines from the list',
    description: 'Update expertises modal header',
  },
  updateExpertisesTitle: {
    id: 'Profile.updateExpertisesTitle',
    defaultMessage: 'Select your disciplines',
    description: 'Update expertises modal header',
  },
  expertiseUpdated: {
    id: 'Profile.expertiseUpdated',
    defaultMessage: 'Your expertises have been successfully updated.',
    description: 'Update expertises success notification',
  },
  headerUpdated: {
    id: 'Profile.headerUpdated',
    defaultMessage: 'Your header information has been successfully updated.',
    description: 'Update header information success notification',
  },
  educationHelperTitle: {
    id: 'Profile.educationHelperTitle',
    defaultMessage: 'No education degree featured yet, add as many as you please above',
    description: 'Helper description title',
  },
  educationHelperDescription: {
    id: 'Profile.educationHelperDescription',
    defaultMessage:
      'Maria Curie Skłodowska would put Sorbona as her University, but she would add both bachelor and master as her education level obtained on Sorbon.',
    description: 'Helper description',
  },
  contactButton: {
    id: 'Profile.contactButton',
    defaultMessage: 'Contact me',
    description: 'Contact Button',
  },
});
