import React from 'react';
import classnames from 'classnames';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { AutocompleteProps } from './types';
import './Autocomplete.scss';

export const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  isValid,
  isInvalid,
  className,
  ...props
}) => (
  <div
    className={classnames('select-container', className, {
      valid: isValid,
      invalid: isInvalid,
    })}
  >
    <label>
      <span className="select-container__label">{label}</span>
      <AsyncCreatableSelect
        cacheOptions
        defaultOptions
        isClearable
        classNamePrefix="react-select"
        {...props}
      />
    </label>
  </div>
);
