import { useMemo } from 'react';
import { RouteProps, useLocation } from 'react-router';

export const useFloatingNavigation = (includeRoutes: RouteProps[]) => {
  const { pathname } = useLocation();

  const isLanding = useMemo(
    () => includeRoutes.some((route: RouteProps) => route.path === pathname),
    [pathname, includeRoutes]
  );

  return isLanding;
};
