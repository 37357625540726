import React, { useState } from 'react';
// import { ReactComponent as WhiteLinkIcon } from 'assets/icons/link-white.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/new-link.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { GrantData } from 'types/grant';
import { Modal } from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';

import StageInfo from './components/StageInfo';
import CountryInfo from './components/CountryInfo';
import InstitutionInfo from './components/InstitutionInfo';
import CallStartAtEndAtInfo from './components/CallStartAtEndAtInfo';
import EligibilityInfo from './components/EligibilityInfo';
import GrantLink from './components/GrantLink';
import styles from './GrantCard.module.scss';
import { messages } from './messages';

export const GrantCard: React.FC<GrantData> = ({
  id,
  attributes: {
    name,
    eligibility,
    countries,
    stages,
    institution,
    urlReference,
    callStartAt,
    callEndAt,
  },
  index,
  elementsForTour,
}) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.grantDetails}>
          <div className={styles.stageAndLocationContainer}>
            {stages && (
              <StageInfo stages={stages ?? []} stagesId={elementsForTour?.stagesId} grantId={id} />
            )}
            {countries && (
              <CountryInfo
                countries={countries ?? []}
                countriesId={elementsForTour?.countriesId}
                grantId={id}
              />
            )}
          </div>
          {institution && (
            <InstitutionInfo
              institution={institution}
              grantId={id}
              institutionId={elementsForTour?.institutionId}
            />
          )}
          <h3
            id={elementsForTour?.institutionId === id ?? index === 0 ? 'seventh-step' : ''}
            className={styles.grantTitle}
          >
            {name ?? ''}
          </h3>
          <CallStartAtEndAtInfo callStartAt={callStartAt} callEndAt={callEndAt} />
          {eligibility && eligibility.length > 5 && (
            <EligibilityInfo
              eligibility={eligibility}
              setIsModalOpen={setIsModalOpen}
              grantId={id}
              eligibilityId={elementsForTour?.eligibilityId}
            />
          )}
          {urlReference && (
            <GrantLink
              urlReference={urlReference}
              grantId={id}
              urlRefId={elementsForTour?.urlRefId}
            />
          )}
        </div>
        <Modal onClose={() => setIsModalOpen(false)} isOpen={modalIsOpen}>
          <div className={styles.modalContentWrapper}>
            <div className={styles.closeIconContainer}>
              <CloseIcon className={styles.closeIcon} onClick={() => setIsModalOpen(false)} />
            </div>
            <p className={styles.eligibility}>{eligibility}</p>
            <div className={styles.buttonContainer}>
              {urlReference && (
                <a
                  className={styles.fullDescriptionButtonContainer}
                  href={urlReference}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    icon={<LinkIcon />}
                    small={true}
                    className={styles.fullDescriptionButton}
                    iconClassName={styles.fullDescriptionIcon}
                  >
                    <FormattedMessage {...messages.fullDescriptionButtonText} />
                  </Button>
                </a>
              )}
              {/*<button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>*/}
              {/*  CANCEL*/}
              {/*</button>*/}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
