import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { OnboardingLayout } from 'modules/onboarding/components/OnboardingLayout';
import background from 'assets/images/background-3.svg';

import { messages } from './messages';
import { DisciplineForm } from './DisciplineForm';
import styles from './DisciplineForm.module.scss';

export const DisciplineSetup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <OnboardingLayout
        background={background}
        activeStep={1}
        title={formatMessage(messages.header)}
        description={formatMessage(messages.description)}
        classNames={{ description: styles.description }}
      >
        <DisciplineForm onSubmit={() => push(ROUTES.AFFILIATION)} />
      </OnboardingLayout>
    </>
  );
};
