import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ForgotPasswordConfirmation.pageTitle',
    defaultMessage: 'Forgot password?',
    description: 'Forgot Password Confirmation page title',
  },
  header: {
    id: 'ForgotPasswordConfirmation.header',
    defaultMessage: 'Check your email',
    description: 'Forgot Password Confirmation main header',
  },
  description: {
    id: 'ForgotPasswordConfirmation.description',
    defaultMessage:
      'If the e-mail address {email} is correct, you should receive a link to set up new password.',
    description: 'Forgot Password Confirmation description',
  },
  resendSuccess: {
    id: 'ForgotPasswordConfirmation.resendSuccess',
    defaultMessage: 'We have resent a link to set up new password.',
    description: 'Forgot Password Confirmation - successful resend message',
  },
});
