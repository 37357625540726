import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Affiliation.pageTitle',
    defaultMessage: 'Onboarding',
    description: 'Select your affilation page',
  },
  header: {
    id: 'Affiliation.header',
    defaultMessage: 'Let the community know you',
    description: 'Select affilation main header',
  },
  helperTitle: {
    id: 'Affiliation.helperTitle',
    defaultMessage: 'You can add up to two affiliation',
    description: 'Helper title',
  },
  helperDescription: {
    id: 'Affiliation.helperDescription',
    defaultMessage: `Stefan Banach after 1944 has two affiliation as he was officially the head of Mathematic Departament 
      in University of Lviv and Lecturer at the Lviv Polytechnic Institute at the same time.`,
    description: 'Helper description',
  },
  affiliationSubheader: {
    id: 'Affiliation.affiliationSubheader',
    defaultMessage: 'Affiliation',
    description: 'Affilation subtitle',
  },
  privacySettingsTitle: {
    id: 'SelectDiscipline.privacySettingsTitle',
    defaultMessage: 'Privacy settings',
    description: 'Privacy settings title',
  },
  privacySettingsDescription: {
    id: 'SelectDiscipline.privacySettingsDescription',
    defaultMessage:
      'If you want your profile not to appear in the search results, check the box below.',
    description: 'Privacy settings description',
  },
  backButton: {
    id: 'SelectDiscipline.backButton',
    defaultMessage: 'return',
    description: 'Back button',
  },
  nextButton: {
    id: 'SelectDiscipline.nextButton',
    defaultMessage: 'Finish',
    description: 'Next button',
  },
});
