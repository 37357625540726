import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';

import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
  isProcessing?: boolean;
  block?: boolean;
  small?: boolean;
  warning?: boolean;
  icon?: React.ReactNode;
  reversed?: boolean;
  light?: boolean;
  className?: string;
  iconClassName?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  secondary,
  isProcessing,
  block,
  small,
  warning,
  icon,
  reversed,
  light,
  className,
  iconClassName,
  ...props
}) => {
  const buttonClassNames = classnames(
    styles.button,
    {
      [styles.outline]: secondary,
      [styles.block]: block,
      [styles.reversed]: reversed,
      [styles.small]: small,
      [styles.warning]: warning,
      [styles.light]: light,
      [styles.processing]: isProcessing,
    },
    className
  );

  const iconClassNames = classnames(styles.icon, iconClassName);

  return (
    <button className={buttonClassNames} {...props}>
      <span>{children}</span>
      {icon && <span className={iconClassNames}>{icon}</span>}
    </button>
  );
};

export const BackButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button secondary={true} reversed icon={<Arrow />} {...props}>
    {children}
  </Button>
);

export const NextButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button secondary={true} icon={<Arrow />} {...props}>
    {children}
  </Button>
);

export const SaveButton: React.FC<ButtonProps> = ({ ...props }) => (
  <Button type="submit" className={styles.longButton} {...props}>
    <FormattedMessage {...commonMessages.saveButton} />
  </Button>
);

export const CancelButton: React.FC<ButtonProps> = ({ ...props }) => (
  <Button secondary={true} type="button" className={styles.longButton} {...props}>
    <FormattedMessage {...commonMessages.cancelButton} />
  </Button>
);
