import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dataTitle: {
    id: 'About.dataTitle',
    defaultMessage: 'We have the data',
    description: 'Data section title',
  },
  dataSubtitle: {
    id: 'About.dataSubtitle',
    defaultMessage: 'See the report',
    description: 'Data section subtitle',
  },
  dataFirstFeature: {
    id: 'About.dataFirstFeature',
    defaultMessage:
      'We have conducted series of surveys and analysis, we would like to share what we found out.',
    description: 'Data first feature',
  },
  dataSecondFeature: {
    id: 'About.dataSecondFeature',
    defaultMessage:
      'Tutaj trochę treści dotyczącej badań i konkluzji z linkiem do pełnej dokumentacji. We have conducted series of surveys and analysis, we would like to share what we found out.',
    description: 'Data second feature ',
  },
  dataThirdFeature: {
    id: 'About.dataThirdFeature',
    defaultMessage:
      'This is where Polonium Network comes in handy – it is a great ‘go-between’ for researchers in Poland, members of the diaspora, and international researchers from all over the world.',
    description: 'Data third feature ',
  },
  contactUs: {
    id: 'About.contactUs',
    defaultMessage: 'Contact us directly:',
    description: 'Data contactUs',
  },
  helpCTA: {
    id: 'About.helpCTA',
    defaultMessage: 'Join for free',
    description: 'About CTA',
  },
});
