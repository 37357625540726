import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthLayout, AuthLayoutProps } from 'components/AuthLayout/AuthLayout';

import { messages } from '../messages';

import { Stepper } from './Stepper/Stepper';

const onboardingSteps = [
  {
    title: <FormattedMessage {...messages.stepOne} />,
    description: <FormattedMessage {...messages.stepOne} />,
  },
  {
    title: <FormattedMessage {...messages.stepTwo} />,
    description: <FormattedMessage {...messages.stepTwoDescription} />,
  },
  {
    title: <FormattedMessage {...messages.stepThree} />,
    description: <FormattedMessage {...messages.stepThreeDescription} />,
  },
];

export interface OnboardingLayoutProps extends AuthLayoutProps {
  activeStep: number;
}

export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  activeStep,
  children,
  ...props
}) => (
  <AuthLayout
    withOnboarding={true}
    stepperComponent={<Stepper steps={onboardingSteps} activeStep={activeStep} />}
    {...props}
  >
    {children}
  </AuthLayout>
);
