import React from 'react';

import { SOCIAL_MEDIA_TYPES } from './SOCIAL_MEDIA_TYPES';
import styles from './SocialTag.module.scss';

export interface SocialMedia {
  link: string;
  type: string;
}

const mapPlatformToKey = (platform: string) => platform.replace(' ', '_').toLowerCase();

export const SocialTag: React.FC<SocialMedia> = ({ link, type }) => {
  const socialMedia = SOCIAL_MEDIA_TYPES[mapPlatformToKey(type)];
  const Icon = socialMedia ? socialMedia.icon : () => null;

  return (
    <a className={styles.container} href={link} target="_blank" rel="noopener noreferrer">
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} />
      </div>
      {type}
    </a>
  );
};
