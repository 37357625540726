import React from 'react';

import { UserPhoto } from '../../../../../components/UserPhoto/UserPhoto';

import styles from './Contact.module.scss';

export interface ContactProps {
  name: string;
  unread: number;
  avatarUrl?: string | null;
  lastMessage: string;
  onClick: () => void;
}

export const Contact: React.FC<ContactProps> = ({
  name,
  unread,
  avatarUrl,
  lastMessage,
  onClick,
}) => {
  return (
    <section className={styles.card} onClick={onClick}>
      <UserPhoto className={styles.userPhoto} photo={avatarUrl} />
      <div className={styles.nameAndMessage}>
        <p
          className={styles.name}
          style={unread > 0 ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
        >
          {name} ({unread})
        </p>
        <p className={styles.message}>{lastMessage}</p>
      </div>
    </section>
  );
};
