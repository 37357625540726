import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/AuthContext';
import { useRedirect } from 'helpers';
import { RouteProps } from 'types';

export const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...routeProps }) => {
  const { isOnboarded } = useAuthContext();
  const redirectPath = useRedirect();

  return (
    <Route
      {...routeProps}
      render={props => (isOnboarded ? <Component {...props} /> : <Redirect to={redirectPath} />)}
    />
  );
};
