import { Affiliation, DeserializedAffiliation } from 'types';
import { getName } from 'helpers';

export const deserializeAffiliation = (affiliation: Affiliation): DeserializedAffiliation => {
  const { academicTitle, institution } = affiliation.attributes;

  return {
    position: {
      ...academicTitle.data,
      label: getName(academicTitle),
      value: getName(academicTitle),
    },
    institution: {
      ...institution.data,
      label: getName(institution),
      value: getName(institution),
    },
  };
};

export const deserializeAffiliations = (affiliation: {
  data: Affiliation[];
}): DeserializedAffiliation[] => {
  return affiliation.data.map(deserializeAffiliation);
};
