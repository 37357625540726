import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'DisciplineSetup.pageTitle',
    defaultMessage: 'Onboarding',
    description: 'Select your discipline page',
  },
  header: {
    id: 'DisciplineSetup.header',
    defaultMessage: 'Select your discipline',
    description: 'Select discipline main header',
  },
  description: {
    id: 'DisciplineSetup.description',
    defaultMessage: 'Pick at least one discipline to continue',
    description: 'Select discipline description',
  },
  backButton: {
    id: 'DisciplineSetup.backButton',
    defaultMessage: 'Back to the list',
    description: 'Discipline list back button label',
  },
  expertisesTitle: {
    id: 'DisciplineSetup.expertisesTitle',
    defaultMessage: 'Selected disciplines',
    description: 'Expertises list title',
  },
  expertisesHint: {
    id: 'DisciplineSetup.expertisesHint',
    defaultMessage: 'You can pick up to 6 disciplines from any science subjects.',
    description: 'Expertises list hint',
  },
  submitButton: {
    id: 'DisciplineSetup.submitButton',
    defaultMessage: 'Save and continue',
    description: 'Submit and continue button label',
  },
  error: {
    id: 'DisciplineSetup.error',
    defaultMessage: 'Please pick between 1 and 6 disciplines',
    description: 'Invalid discipline number error message',
  },
});
