import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  removePhoto: {
    id: 'UserAvatar.removePhoto',
    defaultMessage: 'Remove photo',
    description: 'Remove photo button label',
  },

  removeConfirmation: {
    id: 'UserAvatar.removeConfirmation',
    defaultMessage: 'Remove current avatar?',
    description: 'Available file formats',
  },
});
