import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { commonMessages } from 'intl/commonMessages';

import styles from './AddButton.module.scss';

export interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const AddButton: React.FC<AddButtonProps> = ({ onClick }) => (
  <IconButton icon={AddIcon} className={styles.button} onClick={onClick} type="button">
    <FormattedMessage {...commonMessages.addNew} />
  </IconButton>
);
