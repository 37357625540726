import React from 'react';
import { FieldConfig, useField } from 'formik';

import { Checkbox } from './Checkbox';

type InputProps = Omit<React.HTMLAttributes<HTMLInputElement>, keyof FieldConfig>;

interface CheckboxFieldProps extends InputProps, FieldConfig {
  label: React.ReactNode;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, ...props }) => {
  const [field, { error, touched }] = useField({ name });

  return (
    <Checkbox
      name={name}
      checked={field && field.value}
      hasError={touched && !!error}
      {...field}
      {...props}
    />
  );
};
