import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CONTACT_EMAIL } from 'config/constants';

import { messages } from './messages';
import styles from './ErrorPage.module.scss';

export const ErrorPage: React.FC = () => {
  return (
    <section className={styles.pageWrapper}>
      <h1 className={styles.title}>
        <FormattedMessage
          {...messages.title}
          values={{
            emphasizedTitle: (
              <span className={styles.emphasized}>
                <FormattedMessage {...messages.emphasizedTitle} />
              </span>
            ),
          }}
        />
      </h1>
      <p>
        <FormattedMessage
          {...messages.subtitle}
          values={{
            contactEmail: (
              <a href={`mailto:${CONTACT_EMAIL}`} className={styles.email}>
                {CONTACT_EMAIL}
              </a>
            ),
          }}
        />
      </p>
    </section>
  );
};
