import React from 'react';
import { Navigation } from 'modules/navigation/Navigation';
import { Footer } from 'components/Footer/Footer';
import { RouteProps } from 'react-router-dom';
import { useNavigationVisibility, useFloatingNavigation } from 'helpers';

import styles from './Layout.module.scss';

export interface LayoutProps {
  routesWithoutNav: RouteProps[];
  routesWithFloatingNav: RouteProps[];
}

export const Layout: React.FC<LayoutProps> = ({
  routesWithoutNav,
  routesWithFloatingNav,
  children,
}) => {
  const isNavVisible = useNavigationVisibility(routesWithoutNav);
  const isNavFloating = useFloatingNavigation(routesWithFloatingNav);

  return (
    <div className={styles.layout}>
      {isNavVisible && <Navigation isFloating={isNavFloating} />}
      <main className={styles.main}>{children}</main>
      {isNavVisible && <Footer />}
    </div>
  );
};
