import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  fullDescriptionButtonText: {
    id: 'GrantCard.fullDescriptionButtonText',
    defaultMessage: 'GO TO GRANT',
    description: 'Go to full grant information',
  },
  grantLinkButtonText: {
    id: 'GrantCard.grantLinkButtonText',
    defaultMessage: 'GO TO GRANT',
    description: 'Go to full grant information',
  },
});
