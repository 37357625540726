import React from 'react';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';

import styles from '../GrantCard.module.scss';

const CountryInfo: React.FC<{
  countries: string[];
  grantId: number;
  countriesId: number | undefined;
}> = ({ countries, grantId, countriesId }) => {
  const countriesElements = countries.join(' | ');
  return (
    <div className={styles.country} id={countriesId === grantId ? 'fifth-step' : ''}>
      {countriesElements && (
        <div className={styles.countryIconContainer}>
          <LocationIcon className={styles.icon} />
        </div>
      )}
      <div className={styles.countryElements}>{countriesElements}</div>
    </div>
  );
};

export default CountryInfo;
