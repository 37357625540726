import React from 'react';
import classnames from 'classnames';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { SaveButton, CancelButton } from 'components/Button/Button';

import styles from './ProfileBox.module.scss';

import { EditButton } from '..';

export interface ProfileBoxProps {
  title: React.ReactNode;
  onEdit?: () => void;
  isEdited?: boolean;
  className?: string;
}

export const ProfileBox: React.FC<ProfileBoxProps> = ({
  title,
  onEdit,
  isEdited,
  children,
  ...props
}) => (
  <WhiteBox focus={isEdited} {...props}>
    <div className={styles.boxHeader}>
      <h2 className={styles.headerTitle}>{title}</h2>
      {onEdit && <EditButton onClick={onEdit} disabled={isEdited} />}
    </div>
    <div>{children}</div>
  </WhiteBox>
);

export interface ProfileBoxFormButtonsProps {
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  className?: string;
}

export const ProfileBoxFormButtons: React.FC<ProfileBoxFormButtonsProps> = ({
  onCancel,
  onSubmit,
  isSubmitting,
  className,
}) => (
  <div className={classnames(styles.buttons, className)}>
    <CancelButton
      onClick={onCancel}
      small={true}
      className={styles.cancel}
      disabled={isSubmitting}
    />
    <SaveButton
      small={true}
      className={styles.submit}
      isProcessing={isSubmitting}
      disabled={isSubmitting}
      onClick={onSubmit}
    />
  </div>
);
