import React, { useState } from 'react';
import { ValueType, components } from 'react-select';
import { DeserializedEntity } from 'types';
import { AutocompleteSearch, OptionType, MenuProps } from 'components/Autocomplete';
import { FieldArray, FormikProps } from 'formik';

import { SelectedItem } from '../SelectedItem/SelectedItem';
import { ClearableLabel } from '../ClearableLabel/ClearableLabel';

const Menu: React.FC<MenuProps> = ({ children, selectProps, ...props }) => {
  if (!selectProps.inputValue) return null;

  return (
    <components.Menu {...props} selectProps={selectProps}>
      <div>{children}</div>
    </components.Menu>
  );
};

interface ItemsMultiselectAutocompleteProps {
  fieldName: string;
  label: string;
  placeholder: string;
  loadOptions: (query: string) => Promise<DeserializedEntity[]>;
}

export const ItemsMultiselectAutocomplete: React.FC<ItemsMultiselectAutocompleteProps> = ({
  fieldName,
  label,
  placeholder,
  loadOptions,
}) => {
  const [currentValue, setCurrentValue] = useState<ValueType<OptionType>>(null);
  const itemAlreadySelected = (selectedItems: DeserializedEntity[], value: ValueType<OptionType>) =>
    selectedItems.filter(selectedItem => selectedItem.attributes.id === (value as OptionType).value)
      .length;

  const setSelected = ({
    form,
    value,
  }: {
    form: FormikProps<any>;
    value: ValueType<OptionType>;
  }) => {
    const selectedItems = form.values[fieldName];

    if (value && !itemAlreadySelected(selectedItems, value)) {
      selectedItems.push(value);
      form.setFieldValue(fieldName, selectedItems);
    }

    setCurrentValue(null);
  };

  return (
    <FieldArray name={fieldName}>
      {({ remove, form }) => {
        const selectedItems: DeserializedEntity[] = form.values[fieldName];
        return (
          <>
            <AutocompleteSearch
              name={fieldName}
              loadOptions={loadOptions}
              label={
                <ClearableLabel
                  name={label}
                  onClear={() => form.setFieldValue(fieldName, [])}
                  disabled={!selectedItems.length}
                />
              }
              onChange={value => setSelected({ form, value })}
              components={{ Menu }}
              value={currentValue}
              placeholder={placeholder}
            />
            <div>
              {selectedItems.map((discipline: DeserializedEntity, index: number) => (
                <SelectedItem
                  key={index}
                  selectedItem={discipline}
                  onRemoveClick={() => remove(index)}
                />
              ))}
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};
