import React from 'react';
import classnames from 'classnames';
import userDefaultPhoto from 'assets/icons/default-avatar.svg';

import styles from './UserPhoto.module.scss';

interface UserPhotoProps {
  className?: string;
  photo?: string | null;
}

export const UserPhoto: React.FC<UserPhotoProps> = ({ className, photo }) => (
  <div className={classnames(styles.container, className)}>
    <img className={styles.photo} src={photo || userDefaultPhoto} alt="user" />
  </div>
);
