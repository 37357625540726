import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  removePhoto: {
    id: 'AvatarUpdateForm.removePhoto',
    defaultMessage: 'Remove photo',
    description: 'Remove photo button label',
  },
  photoInstructionsTitle: {
    id: 'AvatarUpdateForm.photoInstructionsTitle',
    defaultMessage: 'What photo should I add?',
    description: 'Photo instructions title',
  },
  photoInstructions: {
    id: 'AvatarUpdateForm.photoInstructions',
    defaultMessage:
      'When creating an ID card for our community add a photo where we can see your face clearly.',
    description: 'Photo instructions',
  },
  avatarUpdated: {
    id: 'AvatarUpdateForm.avatarUpdated',
    defaultMessage: 'Your avatar has been successfully updated.',
    description: 'Avatar update success notification',
  },
});
