import { capitalizeFirstLetter } from 'helpers';
import { Entity, DeserializedEntity } from 'types';

export const deserializePosition = (positions: Entity[]): DeserializedEntity[] =>
  positions.map(({ attributes, ...position }) => ({
    label: capitalizeFirstLetter(attributes.name),
    value: attributes.id || attributes.name,
    attributes,
    ...position,
  }));
