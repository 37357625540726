import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Poland } from 'assets/icons/poland.svg';
import { ReactComponent as World } from 'assets/icons/world.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { PARTNERS_EMAIL } from 'config/constants';

import { messages } from './messages';
import styles from './WhiteBoxSection.module.scss';

interface StatProps {
  icon: SVGComponent;
  title: React.ReactNode;
  text: React.ReactNode;
}

const Stat: React.FC<StatProps> = ({ icon: Icon, title, text }) => (
  <li className={styles.statContainer}>
    <Icon className={styles.icon} />
    <h5 className={styles.statsTitle}>{title}</h5>
    <p className={styles.stats}>{text}</p>
  </li>
);

export const WhiteBoxSection: React.FC = () => (
  <section className={styles.section}>
    <div className={styles.container}>
      <h2 className={styles.title}>
        <FormattedMessage {...messages.dataTitle} />
      </h2>
      <WhiteBox className={styles.whiteBox} landing>
        <ul className={styles.list}>
          <Stat
            icon={Poland}
            title={<FormattedMessage {...messages.statsFirstTitle} />}
            text={<FormattedMessage {...messages.statsFirst} />}
          />
          <Stat
            icon={World}
            title={<FormattedMessage {...messages.statsSecondTitle} />}
            text={<FormattedMessage {...messages.statsSecond} />}
          />

          <Stat
            icon={File}
            title={<FormattedMessage {...messages.statsThirdTitle} />}
            text={<FormattedMessage {...messages.statsThird} />}
          />

          <Stat
            icon={User}
            title={<FormattedMessage {...messages.statsFourthTitle} />}
            text="still counting :)"
          />
        </ul>
      </WhiteBox>

      <div className={styles.row}>
        <div className={styles.halfWidthContainer}>
          <p className={styles.paragraph}>
            <FormattedMessage {...messages.paragraph1} />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage {...messages.paragraph2} />
          </p>
        </div>
        <div className={styles.halfWidthContainer}>
          <p className={styles.paragraph}>
            <FormattedMessage {...messages.paragraph3} />
          </p>
          <p className={styles.paragraph}>
            <strong className={styles.paragraphStrong}>
              <FormattedMessage {...messages.paragraph4} />
            </strong>
            <a href={`mailto:${PARTNERS_EMAIL}`} className={styles.link}>
              {PARTNERS_EMAIL}
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
);
