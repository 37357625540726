import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Signup.pageTitle',
    defaultMessage: 'Sign up',
    description: 'Sign up page title',
  },
  header: {
    id: 'Signup.header',
    defaultMessage: 'Join Polish academic diaspora',
    description: 'Sign up form main header',
  },
  description: {
    id: 'Signup.description',
    defaultMessage: 'Find new opportunities',
    description: 'Sign up form description',
  },
  newsletterLabel: {
    id: 'Signup.newsletterLabel',
    defaultMessage:
      'I want to receive the Polonium Network newsletter (different from the main Polonium Foundation newsletter!) to be up to date with all job offers and the academic news.',
    description: 'Newsletter checkbox field label',
  },
  createAccount: {
    id: 'Signup.createAccount',
    defaultMessage: 'Create an account',
    description: 'Signup form submit button label',
  },
  authNavbarLink: {
    id: 'AuthLayout.login',
    defaultMessage: 'Sign in',
    description: 'Login CTA link label',
  },
  authNavbarDescription: {
    id: 'AuthLayout.loginDescription',
    defaultMessage: 'Already a member?',
    description: 'Login CTA description',
  },
});
