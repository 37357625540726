import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PhotoUploadIcon } from 'assets/icons/photo-upload-placeholder.svg';
import { FileUploadField } from 'components/FileUploadField/FileUploadField';
import { AVATAR_UPLOAD_CONFIG } from 'config/fileUpload';

import { messages } from './messages';
import styles from './PhotoUploadField.module.scss';

export interface PhotoUploadFieldProps {
  addedFile: File | null;
  onFileAdd: (files: File[]) => void;
}

export const PhotoUploadField: React.FC<PhotoUploadFieldProps> = ({ addedFile, onFileAdd }) => (
  <div className={styles.wrapper}>
    <FileUploadField onFileAdd={onFileAdd} addedFile={addedFile}>
      <PhotoUploadIcon className={styles.icon} />
      <h4 className={styles.title}>
        <FormattedMessage {...messages.profilePhoto} />
      </h4>
      <p className={styles.prompt}>
        <FormattedMessage
          {...messages.dragAndDrop}
          values={{
            strong: (msg: string) => <strong className={styles.strong}>{msg}</strong>,
          }}
        />
        <strong className={styles.formatsHeader}>
          <FormattedMessage
            {...messages.availableFormats}
            values={{
              formats: (
                <span className={styles.formats}>{AVATAR_UPLOAD_CONFIG.acceptedFormats}</span>
              ),
            }}
          />
        </strong>
        <FormattedMessage {...messages.maxPhotoSize} />
      </p>
    </FileUploadField>
  </div>
);
