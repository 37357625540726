import React from 'react';
import classnames from 'classnames';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';

import styles from './ProfileSettingsSection.module.scss';

interface ProfileSettingsSectionProps {
  title: React.ReactNode;
  text?: React.ReactNode;
  warning?: boolean;
}

export const ProfileSettingsSection: React.FC<ProfileSettingsSectionProps> = ({
  title,
  text,
  warning,
  children,
}) => (
  <WhiteBox className={styles.whiteBox}>
    <h2 className={classnames(styles.title, { [styles.warning]: warning })}>{title}</h2>
    <p className={styles.text}>{text}</p>
    {children}
  </WhiteBox>
);
