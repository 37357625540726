import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import image from 'assets/images/business.jpg';

import { HeroSection } from '../components/HeroSection/HeroSection';
import { PoloniumSection } from '../components/PoloniumSection/PoloniumSection';
import { Partnership } from '../components/Partnership/Partnership';
import { TestimonialsSection } from '../Home/components/TestimonialsSection/TestimonialsSection';
import { ButtonLink } from '../../../components/Button/ButtonLink';
import { ROUTES } from '../../../config/routes';

import { OfferCTASection } from './components/OfferCTASection/OfferCTASection';
import { WhiteBoxSection } from './components/WhiteBoxSection/WhiteBoxSection';
import { messages } from './messages';

export const BecomePartner: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />

      <HeroSection
        backgroundImage={image}
        heroTitle={<FormattedMessage {...messages.heroTitle} />}
        heroMessage={<FormattedMessage {...messages.heroMessage} />}
        heroMessage2={<FormattedMessage {...messages.heroMessageSecond} />}
        heroMessageEmphasized={<FormattedMessage {...messages.heroMessageEmphasized} />}
      >
        <ButtonLink to={ROUTES.FIND_THE_RIGHT_PEOPLE}>
          <FormattedMessage {...messages.heroButton} />
        </ButtonLink>
      </HeroSection>
      <WhiteBoxSection />
      <TestimonialsSection />
      <PoloniumSection />
      <Partnership />
      <OfferCTASection />
    </>
  );
};
