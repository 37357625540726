import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FieldArray } from 'formik';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { commonMessages } from 'intl/commonMessages';
import {
  LocationField,
  AffiliationAutocompleteField,
  PositionSelectField,
} from 'components/Autocomplete';
import { AffiliationField, FormikValueSetter } from 'types';

import { messages } from '../messages';

import styles from './AffiliationFormContent.module.scss';

import { AffiliationActionButton } from '..';

export const emptyAffiliation = {
  position: '',
  institution: '',
};

export const AffiliationFormContent: React.FC = () => {
  const { formatMessage } = useIntl();

  const handleInputChange = (value: string, setFieldValue: FormikValueSetter) => {
    setFieldValue('city', value);
    setFieldValue('coordinates', null);
    setFieldValue('residence', null);
  };

  const handleLocationSelect = async (value: string, setFieldValue: FormikValueSetter) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setFieldValue('city', value);
    setFieldValue('coordinates', latLng);
    setFieldValue('residence', { name: value, ...latLng });
  };

  return (
    <section className={styles.container}>
      <FieldArray name="affiliations">
        {({ push, remove, form }) => {
          const affiliations: AffiliationField[] = form.values.affiliations;
          const hasSingleAffiliation = affiliations.length < 2;

          const addAffiliation = () => {
            hasSingleAffiliation && push(emptyAffiliation);
          };

          const removeAffiliation = (index: number) => {
            if (!hasSingleAffiliation) remove(index);
          };

          return affiliations.map((affiliation, index) => (
            <div key={`${affiliation.position}-${affiliation.institution}-${index}`}>
              <div className={styles.subtitleContainer}>
                <h3 className={styles.subtitle}>
                  <FormattedMessage {...messages.affiliationSubheader} />
                </h3>
                <AffiliationActionButton
                  hasSingleAffiliation={hasSingleAffiliation}
                  onAdd={addAffiliation}
                  onRemove={() => removeAffiliation(index)}
                />
              </div>

              <div className={styles.affiliationContainer}>
                <PositionSelectField name={`affiliations[${index}]position`} />
                <AffiliationAutocompleteField name={`affiliations.${index}.institution`} />
              </div>
            </div>
          ));
        }}
      </FieldArray>

      <LocationField
        name="city"
        residenceFieldName="residence"
        onSelect={handleLocationSelect}
        onChange={handleInputChange}
        label={formatMessage(commonMessages.locationLabel)}
        placeholder={formatMessage(commonMessages.locationPlaceholder)}
      />
    </section>
  );
};
