import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BackButton } from 'components/Button/Button';

import { messages } from '../../messages';

import styles from './DisciplineList.module.scss';

export interface DisciplineListProps {
  onBackClick: () => void;
  title: React.ReactNode;
}

export const DisciplineList: React.FC<DisciplineListProps> = ({ children, onBackClick, title }) => (
  <div className={styles.wrapper}>
    {title}
    <div className={styles.container}>
      <ul className={styles.list} data-testid="disciplines">
        {children}
      </ul>
    </div>
    <BackButton type="button" onClick={onBackClick} secondary={false} className={styles.button}>
      <FormattedMessage {...messages.backButton} />
    </BackButton>
  </div>
);
