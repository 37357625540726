import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { UserProfile, ProfileFiltersValues, DeserializedPosition, DeserializedDegree } from 'types';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler, useGrantInstitutions } from 'helpers';
import { Loading } from 'components/Loading/Loading';
import { ValueType } from 'react-select';
import { OptionType } from 'components/Autocomplete';
import { Button } from 'components/Button/Button';

import { SearchBar } from '../../components/SearchBar/SearchBar';

import { ProfilesListFilters } from './components/filters/ProfilesListFilters/ProfilesListFilters';
import { FilteredProfiles } from './components/FilteredProfiles/FilteredProfiles';
import { OrderDropdown } from './components/filters/OrderDropdown/OrderDropdown';
import { Pagination } from './components/Pagination/Pagination';
import { messages } from './messages';
import styles from './ProfilesList.module.scss';

const defaultMetaValues = {
  pageCount: 0,
  totalCount: 0,
  currentPage: 0,
};

export interface ProfilesListMeta {
  pageCount: number;
  totalCount: number;
  currentPage: number;
}

interface ProfilesListProps extends RouteComponentProps<{}, {}, { searchValue: string }> {}

export const ProfilesList: React.FC<ProfilesListProps> = ({ history: { location } }) => {
  const { formatMessage } = useIntl();
  const api = useApi();
  const handleError = useApiErrorHandler();
  const searchValue = location?.state?.searchValue;

  const defaultFiltersValues = {
    query: searchValue,
    orderBy: 'last_name ASC',
    withStatus: false,
    disciplines: [],
    educations: [],
    academicTitle: undefined,
    degree: undefined,
    grantInstitution: undefined,
    grantName: undefined,
  };

  const [filters, setFilters] = useState<ProfileFiltersValues>(defaultFiltersValues);
  const [profilesList, setProfilesList] = useState<UserProfile[]>([]);
  const [positions, setPositions] = useState<DeserializedPosition[]>([]);
  const [degrees, setDegrees] = useState<DeserializedDegree[]>([]);
  const [profilesListMeta, setProfilesListMeta] = useState<ProfilesListMeta>(defaultMetaValues);
  const [isFetchingProfiles, setIsFetchingProfiles] = useState<boolean>(true);
  const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false);
  const grantInstitutions = useGrantInstitutions();

  const fetchProfilesList = useCallback(
    async (newFilters: ProfileFiltersValues, page: number = defaultMetaValues.currentPage) => {
      setFilters(newFilters);
      setIsFetchingProfiles(true);
      try {
        const disciplinesIds = newFilters.disciplines?.map(discipline => discipline.id);
        const educationsIds = newFilters.educations?.map(institution => institution.id) as string[];
        const { data } = await api.profilesList.fetchProfilesList(
          {
            ...newFilters,
            disciplinesIds,
            educationsIds,
            academicTitleId: newFilters.academicTitle?.id,
            degreeId: newFilters.degree?.id,
            grantInstitutionId: newFilters.grantProvider?.id,
            grantNameId: newFilters.grantName?.id,
          },
          page
        );
        const { profiles, ...meta } = data;
        setProfilesList(profiles.data);
        setProfilesListMeta({ ...meta, currentPage: page });
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingProfiles(false);
        setAreFiltersVisible(false);
      }
    },
    [api.profilesList, handleError]
  );

  const getPositions = useCallback(async () => {
    try {
      const response = await api.profile.getPositions();
      setPositions(response.data.data);
    } catch (error) {
      handleError(error);
    }
  }, [api.profile, handleError]);

  const getDegrees = useCallback(async () => {
    try {
      const response = await api.profile.getDegrees();
      setDegrees(response.data.data);
    } catch (error) {
      handleError(error);
    }
  }, [api.profile, handleError]);

  useEffect(() => {
    fetchProfilesList(filters);
    getPositions();
    getDegrees();
  }, [api.profilesList, filters, fetchProfilesList, getPositions, getDegrees]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    fetchProfilesList(filters, selectedItem.selected);
  };

  const handleOrderChange = (order: ValueType<OptionType>) => {
    fetchProfilesList({ ...filters, orderBy: (order as OptionType).value });
  };

  return (
    <section className={styles.wrapper}>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <section className={styles.searchBarContainer}>
        <div className={styles.contentWrapper}>
          <SearchBar
            defaultSearchValue={defaultFiltersValues.query}
            onSearchSubmit={({ query }: ProfileFiltersValues) =>
              fetchProfilesList({ ...filters, query })
            }
          />
        </div>
      </section>
      <section className={styles.searchResultsWrapper}>
        <h5 className={styles.searchResultsHeader}>
          <FormattedMessage
            {...messages[filters.query ? 'resultsMessage' : 'noQueryMessage']}
            values={{ searchValue: filters.query }}
          />
        </h5>
        <p className={styles.searchResultsCount}>
          <span className={styles.emphasized}>({profilesListMeta.totalCount})</span>
          <FormattedMessage
            {...messages.resultsCount}
            values={{ count: profilesListMeta.totalCount }}
          />
          <span className={styles.emphasized}>
            <FormattedMessage {...messages.resultsSuffix} />
          </span>
        </p>

        <section className={styles.results}>
          <ProfilesListFilters
            activeFilters={filters}
            onFiltersSubmit={newFilters =>
              fetchProfilesList({ ...newFilters, query: filters.query })
            }
            isVisible={areFiltersVisible}
            positions={positions}
            degrees={degrees}
            grantInstitutions={grantInstitutions}
            onClose={() => setAreFiltersVisible(false)}
          />
          <div className={styles.profilesListWrapper}>
            <Button
              secondary
              className={styles.showFiltersButton}
              onClick={() => setAreFiltersVisible(true)}
            >
              <FormattedMessage {...messages.showAllfiltersButton} />
            </Button>
            <div className={styles.orderAndPaginationArea}>
              {profilesList.length > 0 && (
                <OrderDropdown currentValue={filters.orderBy} onOrderChange={handleOrderChange} />
              )}
              <Pagination listMeta={profilesListMeta} onPageChange={handlePageClick} />
            </div>
            <Loading isLarge isLoading={isFetchingProfiles} className={styles.spinner}>
              <FilteredProfiles profilesList={profilesList} />
              <div className={styles.downPagePagination}>
                <Pagination listMeta={profilesListMeta} onPageChange={handlePageClick} />
              </div>
            </Loading>
          </div>
        </section>
      </section>
    </section>
  );
};
