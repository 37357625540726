import React from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';

import styles from './Partnership.module.scss';
import { logos } from './logos';

export const Partnership: React.FC = () => (
  <section className={styles.section}>
    <div className={styles.container}>
      <h5 className={styles.title}>
        <FormattedMessage {...commonMessages.partnership} />
      </h5>
      <div className={styles.imagesContainer}>
        {logos.map(({ image, title, link }) => (
          <a
            href={link}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.link}
            key={title}
          >
            <img src={image} alt={title} className={styles.partners} />
          </a>
        ))}
      </div>
    </div>
  </section>
);
