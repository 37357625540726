import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { City, ProfileFiltersValues } from 'types';
import { useApiErrorHandler } from 'helpers';
import { useApi } from 'api/ApiContext';
import { Loading } from 'components/Loading/Loading';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { useHistory, Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';

import { SearchBar } from '../../components/SearchBar/SearchBar';

import { messages } from './messages';
import styles from './Community.module.scss';

export const Community: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isFetchingCities, setFetchingCities] = useState<boolean>(true);
  const [city, setCity] = useState<City[]>([]);

  const api = useApi();
  const handleError = useApiErrorHandler();

  const { push } = useHistory();

  useEffect(() => {
    const getCities = async () => {
      setFetchingCities(true);
      try {
        const response = await api.profilesList.getCities();
        setCity(response.data.data);
      } catch (error) {
        handleError(error);
      } finally {
        setFetchingCities(false);
      }
    };

    getCities();
  }, [api.profilesList, handleError]);

  const firstCities = city.slice(0, 16);

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <section className={styles.photoSection}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            <FormattedMessage {...messages.title} />
          </h1>
          <p className={styles.subtitle}>
            <FormattedMessage {...messages.subtitle} />
          </p>
          <SearchBar
            defaultSearchValue=""
            onSearchSubmit={({ query }: ProfileFiltersValues) =>
              push({
                pathname: ROUTES.COMMUNITY_LIST,
                state: { searchValue: query },
              })
            }
          />
        </div>
      </section>
      <section className={styles.residenceContainer}>
        <div className={styles.container}>
          <h3 className={styles.secondaryTitleCentered}>
            <FormattedMessage {...messages.searchByResidenceTitle} />
          </h3>
          <Loading isLoading={isFetchingCities} className={styles.spinner}>
            <div className={styles.cityContainer}>
              {firstCities.map(({ attributes: { name, profilesCount, imageUrl } }) => (
                <Link
                  key={name}
                  className={styles.cityLink}
                  to={{
                    pathname: ROUTES.COMMUNITY_LIST,
                    state: { searchValue: name.split(',')[0] },
                  }}
                >
                  <figure>
                    <img src={imageUrl} alt={name} className={styles.cityPhoto} />
                    <figcaption className={styles.cityDescription}>
                      {name}
                      {!!profilesCount && (
                        <span className={styles.cityContacts}>
                          {profilesCount}
                          <FormattedMessage
                            {...messages.profilesCount}
                            values={{ count: profilesCount }}
                          />
                        </span>
                      )}
                    </figcaption>
                  </figure>
                </Link>
              ))}
            </div>
          </Loading>
          <button className={styles.addButton} onClick={() => push(ROUTES.COMMUNITY_LIST)}>
            <FormattedMessage {...messages.viewAllButton} />
            <span className={styles.addButtonIconContainer}>
              <ArrowIcon className={styles.addButtonIcon} />
            </span>
          </button>
        </div>
      </section>
    </>
  );
};
