import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { useAuthContext } from 'modules/auth/AuthContext';
import { RouteProps } from 'types';

export const AuthorizationRoute: React.FC<RouteProps> = ({
  component: Component,
  ...routeProps
}) => {
  const { isAuthorized } = useAuthContext();

  return (
    <Route
      {...routeProps}
      render={props => (isAuthorized ? <Redirect to={ROUTES.HOME} /> : <Component {...props} />)}
    />
  );
};
