import React from 'react';
import { TextField } from 'components/TextField/TextField';
import { SelectField } from 'components/Autocomplete/SelectField';
import { OptionType } from 'components/Autocomplete/types';
import { FormattedMessage } from 'react-intl';

import { commonMessages } from '../../../../../../intl/commonMessages';
import styles from '../../../../../profiles/pages/ProfileSettings/ProfileSettings.module.scss';

export interface SignUpSocialMediumFieldProps {
  platforms: OptionType[];
}

export const SignUpSocialMediumField: React.FC<SignUpSocialMediumFieldProps> = ({ platforms }) => {
  return (
    <div>
      <SelectField
        name={`socialMedia[0]platform`}
        label="Social Media Platform *"
        options={platforms}
        placeholder="Pick a platform from the list"
      />
      <p className={styles.socialMediaInfo}>
        <FormattedMessage
          {...commonMessages.socialMediaPrompt}
          values={{
            networkEmail: (
              <a href="mailto: network@poloniumfoundation.org">network@poloniumfoundation.org</a>
            ),
          }}
        />
      </p>
      <TextField
        name={`socialMedia[0]url`}
        label="Paste a link to your social media profile *"
        placeholder="e.g. https://github.com/johndoe"
      />
    </div>
  );
};
