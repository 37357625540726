import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { ReactComponent as HelperIcon } from 'assets/icons/light.svg';

import styles from './Helper.module.scss';

export interface HelperProps {
  title: React.ReactNode;
  description: React.ReactNode;
  rotated?: boolean;
}

export const Helper: React.FC<HelperProps> = ({ title, description, rotated }) => (
  <figure className={classnames(styles.backgroundContainer, rotated && styles.rotated)}>
    <HelperIcon className={styles.icon} />
    <figcaption>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>
        <strong className={styles.descriptionStrong}>
          <FormattedMessage {...commonMessages.example} />
        </strong>
        {description}
      </p>
    </figcaption>
  </figure>
);
