import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export const messages: { [key: string]: MessageDescriptor } = defineMessages({
  fileRejected: {
    id: 'FileUpload.fileRejected',
    defaultMessage:
      'A file "{name}" cannot be uploaded - please select a file that does not exceed {size} MB and is in one of the supported formats ({formats})',
    description: 'Unconfirmed account error message',
  },
  addedFile: {
    id: 'FileUpload.addedFile',
    defaultMessage: 'Added files:',
    description: 'Added file header',
  },
});
