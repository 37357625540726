import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Discipline } from 'types';
import { useApi } from 'api/ApiContext';
import { useAuthContext } from 'modules/auth/AuthContext';
import { EXPERTISES_MAX_LENGTH } from 'config/errors/validators';
import { NextButton } from 'components/Button/Button';
import { Loading } from 'components/Loading/Loading';

import { CategoryItem } from './components/CategoryItem/CategoryItem';
import { DisciplineItem } from './components/DisciplineItem/DisciplineItem';
import { ExpertiseItem } from './components/ExpertiseItem/ExpertiseItem';
import { messages } from './messages';
import { CategoryList } from './components/CategoryList/CategoryList';
import { DisciplineList } from './components/DisciplineList/DisciplineList';
import { ExpertiseList } from './components/ExpertiseList/ExpertiseList';
import { CategoryTitle } from './components/CategoryTitle/CategoryTitle';
import styles from './DisciplineForm.module.scss';

export interface DisciplineFormProps {
  onSubmit: () => void;
}

export const DisciplineForm: React.FC<DisciplineFormProps> = ({ onSubmit }) => {
  const { user, updateUser } = useAuthContext();
  const api = useApi();

  const [isFetchingCategories, setFetchingCategories] = useState<boolean>(true);
  const [isFetchingDisciplines, setFetchingDisciplines] = useState<boolean>(false);
  const [categories, setCategories] = useState<Discipline[]>([]);
  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Discipline | null>(null);
  const [expertises, setExpertises] = useState<Discipline[]>(user!.disciplines.data);
  const maxLengthError = useMemo(() => expertises.length > EXPERTISES_MAX_LENGTH, [
    expertises.length,
  ]);
  const isInvalid = useMemo(() => !expertises.length || maxLengthError, [
    expertises.length,
    maxLengthError,
  ]);

  useEffect(() => {
    const getCategories = async () => {
      setFetchingCategories(true);
      try {
        const response = await api.profile.getCategories();
        setCategories(response.data.data);
      } catch (error) {
        // @todo: update error handling globally
      } finally {
        setFetchingCategories(false);
      }
    };

    getCategories();
  }, [api.profile]);

  const getDisciplines = useCallback(
    async (category: Discipline) => {
      setFetchingDisciplines(true);
      try {
        const response = await api.profile.getDisciplines(category.id);
        setDisciplines(response.data.data);
        setSelectedCategory(category);
      } catch (error) {
        // @todo: update error handling globally
      } finally {
        setFetchingDisciplines(false);
      }
    },
    [api.profile]
  );

  const clearCategory = useCallback(() => setSelectedCategory(null), []);

  const addExpertise = useCallback((expertise: Discipline): void => {
    setExpertises(prevExpertises =>
      prevExpertises.some(({ id }) => id === expertise.id)
        ? prevExpertises
        : [...prevExpertises, expertise]
    );
  }, []);

  const removeExpertise = useCallback((expertise: Discipline): void => {
    setExpertises(prevExpertises => prevExpertises.filter(({ id }) => id !== expertise.id));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isInvalid) return;

      try {
        const response = await api.onboarding.saveDisciplines(
          expertises.map(({ attributes }) => attributes.id)
        );

        updateUser(response.data.data.attributes);
        await onSubmit();
      } catch (error) {
        // @todo: update error handling globally
      }
    },
    [expertises, isInvalid, api, updateUser, onSubmit]
  );

  return (
    <Loading isLoading={isFetchingCategories}>
      <form onSubmit={handleSubmit}>
        <div className={styles.boxContainer}>
          <Loading isLoading={isFetchingDisciplines} className={styles.spinner}>
            {selectedCategory ? (
              <DisciplineList
                title={
                  <CategoryTitle
                    name={selectedCategory.attributes.name}
                    disciplinesCount={disciplines.length}
                  />
                }
                onBackClick={clearCategory}
              >
                {disciplines.map(discipline => (
                  <DisciplineItem
                    key={discipline.id}
                    discipline={discipline}
                    onClick={addExpertise}
                    selected={expertises.some(({ id }) => id === discipline.id)}
                  />
                ))}
              </DisciplineList>
            ) : (
              <CategoryList>
                {categories.map(category => (
                  <CategoryItem key={category.id} category={category} onClick={getDisciplines} />
                ))}
              </CategoryList>
            )}
          </Loading>

          <ExpertiseList>
            {expertises.map(expertise => (
              <ExpertiseItem
                key={expertise.id}
                expertise={expertise}
                onRemoveClick={removeExpertise}
              />
            ))}
          </ExpertiseList>
        </div>

        {maxLengthError && (
          <p className={styles.error}>
            <FormattedMessage {...messages.error} />
          </p>
        )}

        <div className={styles.submitWrapper}>
          <NextButton type="submit" className={styles.submit} disabled={isInvalid}>
            <FormattedMessage {...messages.submitButton} />
          </NextButton>
        </div>
      </form>
    </Loading>
  );
};
