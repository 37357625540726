import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'config/routes';
import { BackButtonLink } from 'components/Button/ButtonLink';
import { commonMessages } from 'intl/commonMessages';

import styles from './AuthHomeButton.module.scss';

export const AuthHomeButton: React.FC<{ className?: string }> = ({ className }) => (
  <BackButtonLink
    to={ROUTES.HOME}
    light={true}
    className={classnames(styles.homeButton, className)}
  >
    <FormattedMessage {...commonMessages.homeButton} />
  </BackButtonLink>
);
