import React, { useState } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../config/routes';

import styles from './CookieModal.module.scss';

export const CookieModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(localStorage.getItem('cookieAgreed') === null);
  const onClose = () => {
    localStorage.setItem('cookieAgreed', 'true');
    setIsOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      className={classnames(styles.modal)}
    >
      <div className={styles.container}>
        <p className={styles.text}>
          What is Maria Skłodowska-Curie's favourite food? Curry. But here we can only offer you
          cookies. By using this website, you agree to accept our cookies in exchange for a great
          experience of smooth website browsing that leaves you radiant and happy. &nbsp;
          <Link className={styles.agree} to={ROUTES.COOKIES} target="_blank">
            Click here
          </Link>
          &nbsp; for more information.
        </p>
        <button className={styles.agree} onClick={onClose}>
          Yes, I agree
        </button>
      </div>
    </ReactModal>
  );
};
