import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../../messages';

import styles from './ExpertiseList.module.scss';

export const ExpertiseList: React.FC = ({ children }) => (
  <div className={styles.wrapper}>
    <h3 className={styles.title}>
      <FormattedMessage {...messages.expertisesTitle} />
    </h3>
    <p className={styles.hint}>
      <FormattedMessage {...messages.expertisesHint} />
    </p>
    <ul className={styles.list} data-testid="expertises">
      {children}
    </ul>
  </div>
);
