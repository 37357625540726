import { AxiosResponse } from 'axios';
import { UserProfile, Discipline, City } from 'types';

import { ResourceClient } from './ResourceClient';

interface ProfileFilters {
  query?: string;
  disciplinesIds?: string[];
  educationsIds?: string[];
  withStatus?: boolean;
  academicTitleId?: string;
  degreeId?: string;
  grantInstitutionId?: string;
  grantNameId?: string;
  orderBy?: string;
}

interface ProfileData {
  profiles: {
    data: UserProfile[];
  };
  totalCount: number;
  pageCount: number;
}

export class ProfilesListClient extends ResourceClient {
  fetchProfilesList(filters: ProfileFilters, page: number): Promise<AxiosResponse<ProfileData>> {
    return this.client.get<ProfileData>('/profiles', { params: { ...filters, page: page + 1 } });
  }

  getCategories(): Promise<AxiosResponse<{ data: Discipline[] }>> {
    return this.client.get<{ data: Discipline[] }>('/disciplines');
  }

  getCities(): Promise<AxiosResponse<{ data: City[] }>> {
    return this.client.get<{ data: City[] }>('/cities');
  }
}
