import React from 'react';
import { InputField, InputFieldProps } from 'components/InputField/InputField';

export interface TextFieldProps extends Omit<InputFieldProps, 'type'> {
  type?: 'text' | 'email' | 'tel';
}

export const TextField: React.FC<TextFieldProps> = ({ type = 'text', ...props }) => {
  return <InputField type={type} {...props} />;
};
