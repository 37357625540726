import React, { useCallback, useState, useEffect } from 'react';
import { commonMessages } from 'intl/commonMessages';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { DeserializedPosition } from 'types';
import { useApiErrorHandler } from 'helpers';

import { MenuProps, OptionType } from './types';

import { SelectField, SelectFieldProps, MenuWithHint, MultiValue } from '.';
import './Autocomplete.scss';

const Menu: React.FC<MenuProps> = props => (
  <MenuWithHint
    hint={<FormattedMessage {...commonMessages.degreeAutocompleteHint} />}
    openOnFocus
    {...props}
  />
);

export interface DegreeSelectFieldProps extends Omit<SelectFieldProps<OptionType>, 'label'> {}

export const DegreeSelectField: React.FC<DegreeSelectFieldProps> = ({ name, ...props }) => {
  const [degrees, setDegrees] = useState<DeserializedPosition[]>([]);

  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();

  const getDegrees = useCallback(async () => {
    try {
      const response = await profile.getDegrees();
      setDegrees(response.data.data);
    } catch (error) {
      handleError(error);
    }
  }, [profile, handleError]);

  useEffect(() => {
    getDegrees();
  }, [getDegrees]);

  return (
    <SelectField
      name={name}
      components={{ Menu, MultiValue }}
      label={
        <>
          <span>{formatMessage(commonMessages.degreeLabel)}</span>
          <span className="select-container__label--info">
            {formatMessage(commonMessages.degreeAdditionalInfo)}
          </span>
        </>
      }
      placeholder={formatMessage(commonMessages.degreePlaceholder)}
      options={degrees}
      isMulti={true}
      isCreatable={true}
      className="select-container--degree"
      errorClassName="select-container__error--indented"
      {...props}
    />
  );
};
