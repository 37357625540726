import React from 'react';

import styles from '../GrantCard.module.scss';

const StageInfo: React.FC<{ stages: string[]; stagesId: number | undefined; grantId: number }> = ({
  stages,
  stagesId,
  grantId,
}) => {
  const stagesElements = stages.join(' | ');
  return (
    <div className={styles.stage} id={stagesId === grantId ? 'fourth-step' : ''}>
      <div className={styles.stageName}>{stagesElements ? 'Stage:' : ''}</div>
      <div className={styles.stageElements}>{stagesElements}</div>
    </div>
  );
};

export default StageInfo;
