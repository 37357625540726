import React from 'react';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';

import styles from './Slide.module.scss';

export interface SlideProps {
  title: React.ReactNode;
  imgUrl: string;
  copyrights: React.ReactNode;
  text: React.ReactNode;
  date: React.ReactNode;
}

export const Slide: React.FC<SlideProps> = ({ title, imgUrl, copyrights, text, date }) => (
  <div className={styles.slide}>
    <figure className={styles.figure}>
      <img className={styles.photo} src={imgUrl} alt="testimonial's author" />
      <figcaption className={styles.copyrights}>{copyrights}</figcaption>
    </figure>
    <div className={styles.boxWrapper}>
      <WhiteBox className={styles.box}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
        <span className={styles.date}>{date}</span>
      </WhiteBox>
    </div>
  </div>
);
