import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-dropdown-select';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { Field } from 'formik';

import styles from './DropdownSelectMenu.module.scss';
import { messages } from './messages';

interface DropdownSelectMenuProps {
  options: Array<{ label: string; value: string; disabled: boolean }>;
  id: string;
  name: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<number>>;
  selectedItems: number;
  label: string;
  placeholder: string;
  query: string[];
  setQuery: React.Dispatch<React.SetStateAction<string[]>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  submit: (() => Promise<void>) & (() => Promise<any>);
  wrapperClass: any;
}

export const DropdownSelectMenu: React.FC<DropdownSelectMenuProps> = ({
  id,
  options,
  name,
  selectedItems,
  setSelectedItems,
  label,
  placeholder,
  submit,
  query,
  setQuery,
  wrapperClass,
  // setFieldValue
}) => {
  const [selectedValues, setSelectedValues] = useState<
    { label: string; value: string; disabled: boolean }[]
  >([]);

  useEffect(() => {
    if (selectedItems === 0 && query.length === 0) {
      setSelectedValues([]);
    }
  }, [selectedItems, query.length]);

  useEffect(() => {
    if (query && query.length > 0) {
      const selectedOptions = query.map(value => options.find(option => option.value === value));
      const filteredOptions = selectedOptions.filter(
        (
          option
        ): option is {
          label: string;
          value: string;
          disabled: boolean;
        } => option !== undefined
      );
      if (filteredOptions.length > 0) {
        setSelectedValues(filteredOptions);
      }
    }
  }, [query, options]);

  return (
    <div className={wrapperClass} id={id}>
      <Field name={name}>
        {({ field, form }: any) => (
          <>
            <div className={styles.searchLabelContainer}>
              <p className={styles.searchLabel}>
                {label}
                <span>({selectedItems}) </span>
              </p>
              <button
                type={'button'}
                className={styles.clearContainer}
                onClick={() => {
                  form.setFieldValue(name, []);
                  setSelectedValues([]);
                  setSelectedItems(0);
                  setQuery([]);
                  submit().catch(e => console.log(e));
                }}
              >
                <TrashIcon className={styles.trashIcon} />
                <p className={styles.clearText}>
                  <FormattedMessage {...messages.searchLabelClear} />
                </p>
              </button>
            </div>
            <Select
              name={field.name}
              multi
              searchable={true}
              options={options}
              values={selectedValues}
              className={styles.select}
              contentRenderer={() => <p style={{ color: '#999', marginLeft: 12 }}>{placeholder}</p>}
              style={{
                border: selectedItems > 0 ? '1px solid #20DACC' : '1px solid #b9c7dc',
                background: selectedItems > 0 ? '#F2FDFC' : '#fafcff',
              }}
              placeholder={placeholder}
              itemRenderer={({
                item,
                methods,
              }: {
                item: { disabled: boolean; label: string };
                methods: any;
              }) => (
                <div className={styles.styledItem}>
                  {item.disabled ? (
                    <div className={styles.styledItemDiv} aria-disabled>
                      {item.label}
                    </div>
                  ) : (
                    <div className={styles.styledItemDiv} onClick={() => methods.addItem(item)}>
                      <input
                        className={styles.styledItemInput}
                        style={{ width: 'auto' }}
                        onChange={() => methods.addItem(item)}
                        type="checkbox"
                        checked={methods.isSelected(item)}
                      />
                      <span className={styles.checkmark}></span>
                      <span className={styles.label}>{item.label}</span>
                    </div>
                  )}
                </div>
              )}
              onChange={value => {
                form.setFieldValue(
                  name,
                  value.map(item => item.value)
                );
                setSelectedValues(value);
                setSelectedItems(value.length);
              }}
            />
          </>
        )}
      </Field>
    </div>
  );
};
