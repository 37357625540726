import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  partnerFirstTitle: {
    id: 'FindTheRightPeople.partnerFirstTitle',
    defaultMessage: 'High and growing exposure of your brand',
    description: 'Partner first: title',
  },
  partnerFirstSubtitle: {
    id: 'FindTheRightPeople.partnerFirstSubtitle',
    defaultMessage: 'Our vibrant community at your disposal',
    description: 'Partner first: subtitle',
  },
  partnerSecondTitle: {
    id: 'FindTheRightPeople.partnerSecondTitle',
    defaultMessage: 'Powerful targeting for your job postings',
    description: 'Partner second: title',
  },
  partnerSecondSubtitle: {
    id: 'FindTheRightPeople.partnerSecondSubtitle',
    defaultMessage: 'Your job ad is matched with candidates’ disciplines and fields of interest',
    description: 'Partner second: subtitle',
  },
  partnerThirdTitle: {
    id: 'FindTheRightPeople.partnerThirdTitle',
    defaultMessage: 'Diverse backgrounds',
    description: 'Partner third: title',
  },
  partnerThirdSubtitle: {
    id: 'FindTheRightPeople.partnerThirdSubtitle',
    defaultMessage: 'Linguistics, history, or machine learning? We’ve got you covered!',
    description: 'Partner third: subtitle',
  },
  partnerFourthTitle: {
    id: 'FindTheRightPeople.partnerFourthTitle',
    defaultMessage: 'Academics & Professionals',
    description: 'Partner fourth: title',
  },
  partnerFourthSubtitle: {
    id: 'FindTheRightPeople.partnerFourthSubtitle',
    defaultMessage: 'We bring academia and industry together',
    description: 'Partner fourth: subtitle',
  },
  partnerFifthTitle: {
    id: 'FindTheRightPeople.partnerFifthTitle',
    defaultMessage: 'Motivated pool of talents',
    description: 'Partner fifth: title',
  },
  partnerFifthSubtitle: {
    id: 'FindTheRightPeople.partnerFifthSubtitle',
    defaultMessage: 'From top universities and research institutes around the world!',
    description: 'Partner fifth: subtitle',
  },
  partnerSixthTitle: {
    id: 'FindTheRightPeople.partnerSixthTitle',
    defaultMessage: 'Global network',
    description: 'Partner sixth: title',
  },
  partnerSixthSubtitle: {
    id: 'FindTheRightPeople.partnerSixthSubtitle',
    defaultMessage: 'Wherever you are - we are there too!',
    description: 'Partner sixth: subtitle',
  },
});
