import React from 'react';

import styles from './FindTheRightPeople.module.scss';
import { PartnerSection } from './components/PartnerSection/PartnerSection';

export const FindTheRightPeople: React.FC = () => {
  return (
    <section className={styles.pageWrapper}>
      <h1 className={styles.title}>Perfect Match for Your Business</h1>
      <p className={styles.paragraph}>
        The Polonium Network Team knows the value of time among the researchers and employers. This
        is why we created a tool that is less time-consuming and much more specialised than any
        other existing portal on the market. Are you looking for ambitious and well-educated
        employees with international experience? Polonium Network offers the vibrant and growing
        community of highly motivated individuals from different disciplines. Global in reach but
        localized in focus, Polonium Network maximizes your chances of finding a perfect match for
        your business.
      </p>
      <PartnerSection />
    </section>
  );
};
