import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { object } from 'yup';
import { useApi } from 'api/ApiContext';
import { validators } from 'config/errors/validators';
import { Button } from 'components/Button/Button';
import { commonMessages } from 'intl/commonMessages';
import { CallToActionSection } from 'pages/landings/components/CallToActionSection/CallToActionSection';
import { CheckboxField } from 'components/CheckboxField/CheckboxField';
import { TextField } from 'components/TextField/TextField';
import { ROUTES } from 'config/routes';
import { useNotification } from 'modules/notifications/NotificationContext';

import { messages } from './messages';
import styles from './NewsletterSection.module.scss';

export interface NewsletterForm {
  email: string;
  termsAccepted: boolean;
}

const initialValues = {
  email: '',
  termsAccepted: false,
};

const validationSchema = object().shape({
  email: validators.email,
  termsAccepted: validators.termsAccepted,
});

export const NewsletterSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { newsletterSubsciptions } = useApi();
  const { createNotification } = useNotification();

  const handleSubmit = async (
    values: NewsletterForm,
    { setSubmitting }: FormikHelpers<NewsletterForm>
  ) => {
    try {
      await newsletterSubsciptions.createSubscription(values.email);
      // @todo: add error handling, excluding email-taken error
    } finally {
      setSubmitting(false);
      createNotification({
        type: 'success',
        message: <FormattedMessage {...messages.subscriptionSubmitted} />,
      });
    }
  };

  return (
    <CallToActionSection
      titleHighlighted={<FormattedMessage {...messages.titleHighlighted} />}
      title={<FormattedMessage {...messages.newsletterTitle} />}
    >
      <Formik<NewsletterForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.form} noValidate>
            <TextField
              name="email"
              type="email"
              label={formatMessage(messages.emailLabel)}
              placeholder={formatMessage(commonMessages.emailPlaceholder)}
              classNames={{
                wrapper: styles.inputWrapper,
                input: styles.input,
                inputInvalid: styles.invalid,
                label: styles.label,
              }}
            />

            <div className={styles.checkboxField}>
              <CheckboxField
                name="termsAccepted"
                label={formatMessage(commonMessages.termsOfServiceLabel, {
                  termsOfServiceLink: (
                    <Link to={ROUTES.TERMS_OF_SERVICE}>
                      {formatMessage(commonMessages.termsOfServiceLink)}
                    </Link>
                  ),
                  privacyPolicyLink: (
                    <Link to={ROUTES.PRIVACY}>
                      {formatMessage(commonMessages.privacyPolicyLink)}
                    </Link>
                  ),
                })}
              />
            </div>
            <Button
              type="submit"
              light={true}
              className={styles.button}
              isProcessing={isSubmitting}
              disabled={isSubmitting}
            >
              {formatMessage(messages.newsletterSubmitButton)}
            </Button>
          </Form>
        )}
      </Formik>
    </CallToActionSection>
  );
};
