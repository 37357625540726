import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  affiliationSubheader: {
    id: 'Affiliation.affiliationSubheader',
    defaultMessage: 'Affiliation',
    description: 'Affilation subtitle',
  },
  add2Affiliation: {
    id: 'Affiliation.add2Affiliation',
    defaultMessage: 'Add 2nd affiliation',
    description: 'Add second position button label',
  },
  removeAffiliation: {
    id: 'Affiliation.removeAffiliation',
    defaultMessage: 'Remove',
    description: 'Remove affiliation button label',
  },
});
