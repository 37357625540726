import React from 'react';
import Select from 'react-select';
import { Props as SelectProps } from 'react-select/src/Select';
import { OptionType } from 'components/Autocomplete';

import './StatusDropdown.scss';

export interface StatusDropdownProps<T> extends SelectProps<T> {
  isStatusHidden: boolean;
}

export const StatusDropdown: React.FC<StatusDropdownProps<OptionType>> = ({
  isStatusHidden,
  ...props
}) => (
  <div className="status-dropdown">
    <Select
      isSearchable={false}
      classNamePrefix="dropdown"
      isDisabled={isStatusHidden}
      {...props}
    />
  </div>
);
