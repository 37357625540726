import React from 'react';
import { Spinner } from 'components/Spinner/Spinner';

export interface LoadingProps {
  isLarge?: boolean;
  isLoading: boolean;
  className?: string;
}

export const Loading: React.FC<LoadingProps> = ({ isLarge, isLoading, children, className }) =>
  isLoading ? <Spinner isLarge={isLarge} className={className} /> : <>{children}</>;
