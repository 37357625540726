import React from 'react';
import { components } from 'react-select';

import { MenuProps } from './types';
import './Autocomplete.scss';

export interface MenuWithHintProps extends MenuProps {
  hint: React.ReactNode;
  openOnFocus?: boolean;
}
export const MenuWithHint: React.FC<MenuWithHintProps> = ({
  hint,
  openOnFocus,
  children,
  selectProps,
  ...props
}) => {
  if (!openOnFocus && !selectProps.inputValue) return null;

  return (
    <components.Menu selectProps={selectProps} {...props}>
      <div>
        {children}
        <p className="react-select__menu__hint">{hint}</p>
      </div>
    </components.Menu>
  );
};
