import { defineMessages } from 'react-intl.macro';

export const commonMessages = defineMessages({
  firstNameLabel: {
    id: 'common.firstNameLabel',
    defaultMessage: 'First name',
    description: 'First Name field label',
  },
  firstNamePlaceholder: {
    id: 'common.firstNamePlaceholder',
    defaultMessage: 'e.g. Maria',
    description: 'First Name field placeholder',
  },
  lastNameLabel: {
    id: 'common.lastNameLabel',
    defaultMessage: 'Last name',
    description: 'Last Name field label',
  },
  lastNamePlaceholder: {
    id: 'common.lastNamePlaceholder',
    defaultMessage: 'e.g. Curie',
    description: 'Last Name field placeholder',
  },
  emailLabel: {
    id: 'common.emailLabel',
    defaultMessage: 'E-mail',
    description: 'E-mail field label',
  },
  emailPlaceholder: {
    id: 'common.emailPLaceholder',
    defaultMessage: 'e.g. info@polonium.com',
    description: 'E-mail field placeholder',
  },
  changeEmailInfo: {
    id: 'common.changeEmailInfo',
    defaultMessage:
      'We have sent you confirmation email on {email} com to make sure its you who want to change email. Please confirm the change by clicking the link provided in the message. The link will be valid for 24h, during this time you cannot request a new email change.',
    description: 'Change E-mail information',
  },
  socialMediaPrompt: {
    id: 'common.socialMediaPrompt',
    defaultMessage:
      "This is how we verify your profile. Remember, it can be LinkedIn or Researchgate too. If you're not on social media, feel free to send us your institute's website. Alternatively, contact us directly on {networkEmail}",
    description: 'Social Media Prompt',
  },
  passwordLabel: {
    id: 'common.passwordLabel',
    defaultMessage: 'Password',
    description: 'Password field label',
  },
  passwordPrompt: {
    id: 'common.passwordPrompt',
    defaultMessage: 'Use at least 8 characters including letters, numbers and symbols.',
    description: 'Password field prompt',
  },
  passwordConfirmationLabel: {
    id: 'common.passwordConfirmationLabel',
    defaultMessage: 'Repeat password',
    description: 'Password Confirmation field label',
  },
  passwordPlaceholder: {
    id: 'common.passwordPlaceholder',
    defaultMessage: 'password',
    description: 'Password field placeholder',
  },
  passwordValuePlaceholder: {
    id: 'common.passwordValuePlaceholder',
    defaultMessage: '**********',
    description: 'Password field placeholder',
  },
  termsOfServiceLabel: {
    id: 'common.termsOfServiceLabel',
    defaultMessage: 'I agree with {termsOfServiceLink} and acknowledge the {privacyPolicyLink}',
    description: 'Terms of service checkbox field label',
  },
  termsOfServiceLabelRequired: {
    id: 'common.termsOfServiceLabelRequired',
    defaultMessage: '* I agree with {termsOfServiceLink} and acknowledge the {privacyPolicyLink}',
    description: 'Required Terms of service checkbox field label',
  },
  termsOfServiceLink: {
    id: 'common.termsOfServiceLink',
    defaultMessage: 'Terms of Service',
    description: 'Terms of service link',
  },
  privacyPolicyLink: {
    id: 'common.privacyPolicyLink',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy link',
  },
  changePasswordSuccess: {
    id: 'common.changePasswordSuccess',
    defaultMessage: 'Password updated',
    description: 'Password updated message',
  },
  forgotPassword: {
    id: 'common.forgotPassword',
    defaultMessage: 'Forgot your password?',
    description: 'Terms of service checkbox field label',
  },
  homeButton: {
    id: 'common.homeButton',
    defaultMessage: 'Home',
    description: 'Home button label',
  },
  cancelButton: {
    id: 'common.cancelButton',
    defaultMessage: 'Cancel',
    description: 'Cancel Button Label',
  },
  editButton: {
    id: 'common.editButton',
    defaultMessage: 'Edit',
    description: 'Edit Button Label',
  },
  saveButton: {
    id: 'common.saveButton',
    defaultMessage: 'Save',
    description: 'Save Button Label',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
    description: 'Remove Button Label',
  },
  addNew: {
    id: 'common.addNew',
    defaultMessage: 'Add new',
    description: 'Add new entry button label',
  },
  loginLink: {
    id: 'common.loginLink',
    defaultMessage: 'Go to login page',
    description: 'Login Link Label',
  },
  positionAutocompleteHint: {
    id: 'common.positionAutocompleteHint',
    defaultMessage:
      'Add any position we might have missed by writing down its full oficial name in English and pressing enter',
    description: 'Position Autocomplete Hint',
  },
  positionLabel: {
    id: 'common.positionLabel',
    defaultMessage: 'Current position',
    description: 'Position Label',
  },
  positionPlaceholder: {
    id: 'common.positionPlaceholder',
    defaultMessage: 'Select your position',
    description: 'Position Select Placeholder',
  },
  affiliationAutocompleteHint: {
    id: 'common.affiliationAutocompleteHint',
    defaultMessage:
      'Add any institution we might have missed by writing down its full oficial name in English and pressing enter',
    description: 'Affiliation Autocomplete Hint',
  },
  affiliationLabel: {
    id: 'common.affiliationLabel',
    defaultMessage: 'Affiliation Institution',
    description: 'Affiliation Label',
  },
  affiliationPlaceholder: {
    id: 'common.affiliationPlaceholder',
    defaultMessage: 'Start typing to see the institutions list',
    description: 'Affiliation Label',
  },
  locationLabel: {
    id: 'common.locationLabel',
    defaultMessage: 'Place of current residence',
    description: 'Location Label',
  },
  born: {
    id: 'common.born',
    defaultMessage: 'Born',
    description: 'Born Label',
  },
  locationPlaceholder: {
    id: 'common.locationPlaceholder',
    defaultMessage: 'Select a city of your current residence',
    description: 'Location Label',
  },
  institutionsPlaceholder: {
    id: 'common.institutionsPlaceholder',
    defaultMessage: 'To add institution start writing its name',
    description: 'Institutions Multiselect Field Placeholder',
  },
  universityLabel: {
    id: 'common.universityLabel',
    defaultMessage: 'University name',
    description: 'Education university field label',
  },
  degreeLabel: {
    id: 'common.degreeLabel',
    defaultMessage: 'Education degree',
    description: 'Education degree multiselect field label',
  },
  degreeAdditionalInfo: {
    id: 'common.degreeAdditionalInfo',
    defaultMessage: 'You can add more than one education degree per each University.',
    description: 'Education degree multiselect field additional info',
  },
  degreePlaceholder: {
    id: 'common.degreePlaceholder',
    defaultMessage: 'Start typing to see degree list',
    description: 'Education degree multiselect field placeholder',
  },
  degreeAutocompleteHint: {
    id: 'common.degreeAutocompleteHint',
    defaultMessage:
      'Add any degree we might have missed by writing down its full oficial name in English and pressing enter',
    description: 'Degree Autocomplete Hint',
  },
  grantProviderLabel: {
    id: 'common.grantProviderLabel',
    defaultMessage: 'Institutions name',
    description: 'Grant Institution Field Label',
  },
  grantProviderPlaceholder: {
    id: 'common.grantProviderPlaceholder',
    defaultMessage: 'Write the institution name',
    description: 'Grant Institution Field Placeholder',
  },
  grantNameLabel: {
    id: 'common.grantNameLabel',
    defaultMessage: 'Grant name',
    description: 'Grant name Field Label',
  },
  grantNamePlaceholder: {
    id: 'common.grantNamePlaceholder',
    defaultMessage: 'Pick grant from the list',
    description: 'Grant name Field Placeholder',
  },
  grantNameWithoutInstitutionPlaceholder: {
    id: 'common.grantNameWithoutInstitutionPlaceholder',
    defaultMessage: 'You must select grant provider first',
    description: 'Grant name Field Placeholder when institution is not selected',
  },
  becomePartner: {
    id: 'common.becomePartner',
    defaultMessage: 'Become a partner',
    description: 'Become a partner page link label',
  },
  about: {
    id: 'common.about',
    defaultMessage: 'About',
    description: 'About page link label',
  },
  login: {
    id: 'common.login',
    defaultMessage: 'Login',
    description: 'Login page link label',
  },
  logout: {
    id: 'common.logout',
    defaultMessage: 'Logout',
    description: 'Logout button label',
  },
  register: {
    id: 'common.register',
    defaultMessage: 'Register',
    description: 'Register link label',
  },
  profile: {
    id: 'common.profile',
    defaultMessage: 'Profile',
    description: 'Profile link label',
  },
  messages: {
    id: 'common.messages',
    defaultMessage: 'Messages',
    description: 'Messages link label',
  },
  settings: {
    id: 'common.settings',
    defaultMessage: 'Settings',
    description: 'Settings link label',
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading...',
    description: 'Loading message label',
  },
  grant: {
    id: 'common.grant',
    defaultMessage: 'Grants',
    description: 'Grant link label',
  },
  community: {
    id: 'common.community',
    defaultMessage: 'Community',
    description: 'Community link label',
  },
  career: {
    id: 'common.career',
    defaultMessage: 'Careers',
    description: 'Career link label',
  },
  editModeButton: {
    id: 'common.editModeButton',
    defaultMessage: 'Edit Mode.',
    description: 'Edit mode button',
  },
  partnership: {
    id: 'common.partnership',
    defaultMessage: 'In Partnership with',
    description: 'Partnership title',
  },
  genderOptionsLabel: {
    id: 'common.genderOptionsLabel',
    defaultMessage: 'Sex',
    description: 'Gender options label',
  },
  genderPlaceholder: {
    id: 'common.genderPlaceholder',
    defaultMessage: 'Pick your sex',
    description: 'Sex options placeholder',
  },
  profileHiddenLabel: {
    id: 'common.profileHiddenLabel',
    defaultMessage: 'I want my profile to stay hidden for the community',
    description: 'Hide Profile Label',
  },
  profileNewsletter: {
    id: 'common.profileNewsletter',
    defaultMessage: 'Polonium Newsletter',
    description: 'Newsletter Label',
  },
  profileNewsletterGossip: {
    id: 'common.profileNewsletterGossip',
    defaultMessage: 'Newsletter about gossips regarding your discipline',
    description: 'Newsletter Gossip Label',
  },
  profileNewsletterEvents: {
    id: 'common.profileNewsletterEvents',
    defaultMessage: 'Invitations to Polonium Foundation events',
    description: 'Newsletter Events Label',
  },
  example: {
    id: 'common.example',
    defaultMessage: 'Example: ',
    description: 'Example Label',
  },
});
