import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  firstStat: {
    id: 'Home.firstStat',
    defaultMessage: 'Researchers in Poland',
    description: 'Hero stats: name of the first stat',
  },
  firstStatNumber: {
    id: 'Home.firstStatNumber',
    defaultMessage: '150,000',
    description: 'Hero stats: number of the first stat',
  },
  secondStat: {
    id: 'Home.secondStat',
    defaultMessage: 'Polish Researchers abroad',
    description: 'Hero stats: name of the second stat',
  },
  secondStatNumber: {
    id: 'Home.secondStatNumber',
    defaultMessage: '15,000',
    description: 'Hero stats: number of the second stat',
  },
});
