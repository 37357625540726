import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';

import styles from './ClearableLabel.module.scss';
import { messages } from './messages';

interface ClearableLabelProps {
  name?: string;
  label?: string;
  onClear: () => void;
  disabled: boolean;
}

export const ClearableLabel: React.FC<ClearableLabelProps> = ({
  name,
  label,
  onClear,
  disabled,
}) => {
  return (
    <div className={styles.clearableLabel}>
      <span>{name}</span>
      <button
        type="button"
        onClick={onClear}
        className={styles.clearFiltersButton}
        disabled={disabled}
      >
        <Clear className={styles.clearIcon} />
        {label || <FormattedMessage {...messages.clear} />}
      </button>
    </div>
  );
};
