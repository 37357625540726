import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { ABOUT_MAX_LENGTH } from 'config/errors/validators';
import { useApiErrorHandler } from 'helpers';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { useApi } from 'api/ApiContext';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';

import { messages } from '../../messages';
import { ProfileBox, ProfileBoxFormButtons } from '../';

import styles from './AboutBox.module.scss';

interface AboutBoxProps {
  about: string;
}

export const AboutBox: React.FC<AboutBoxProps> = ({ about }) => {
  const { formatMessage } = useIntl();
  const [description, setDescription] = useState<string>(about);
  const [isEdited, setEdited] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  const handleUpdate = async () => {
    setSubmitting(true);
    try {
      const { data } = await profile.updateCurrentUser({ about: description });
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
      setEdited(false);
    }
  };

  const cancel = () => {
    setEdited(false);
    setDescription(about);
  };

  const handleDescriptionChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(target.value);
  };

  return (
    <ProfileBox
      title={<FormattedMessage {...messages.aboutTitle} />}
      onEdit={() => setEdited(true)}
      isEdited={isEdited}
    >
      {isEdited ? (
        <>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className={styles.textarea}
            placeholder={formatMessage(messages.aboutPlaceholder)}
            maxLength={ABOUT_MAX_LENGTH}
          />
          <span className={styles.counter}>
            {description.length}/{ABOUT_MAX_LENGTH}
          </span>
          <ProfileBoxFormButtons
            onSubmit={handleUpdate}
            onCancel={cancel}
            isSubmitting={isSubmitting}
          />
        </>
      ) : (
        <AboutPreview description={description} />
      )}
    </ProfileBox>
  );
};

export interface AboutPreviewProps {
  description: string;
}

const MAX_NODE_HEIGHT = 94;

const AboutPreview: React.FC<AboutPreviewProps> = ({ description }) => {
  const [isShownMore, setShownMore] = useState<boolean>(false);
  const [isTruncated, setTruncated] = useState<boolean>(false);
  const descriptionNode = useRef<HTMLParagraphElement | null>(null);

  const toogleShownMore = () => {
    setShownMore(isShownMore => !isShownMore);
  };

  const updateTruncation = useCallback(() => {
    const height =
      descriptionNode && descriptionNode.current && descriptionNode.current.scrollHeight;

    height && setTruncated(height > MAX_NODE_HEIGHT);
  }, []);

  useLayoutEffect(() => {
    updateTruncation();

    window.addEventListener('resize', updateTruncation);

    return () => {
      window.removeEventListener('resize', updateTruncation);
    };
  }, [updateTruncation]);

  return description ? (
    <>
      <p
        ref={descriptionNode}
        className={classnames(styles.aboutText, {
          [styles.truncated]: isTruncated,
          [styles.fullHeight]: isShownMore,
        })}
      >
        {description}
      </p>
      {isTruncated && (
        <IconButton
          icon={isShownMore ? MinusIcon : PlusIcon}
          className={styles.showButton}
          onClick={toogleShownMore}
        >
          <FormattedMessage {...messages[isShownMore ? 'showLess' : 'showMore']} />
        </IconButton>
      )}
    </>
  ) : (
    <p className={styles.boxText}>
      <FormattedMessage {...messages.aboutEmptyDescription} />
    </p>
  );
};

export const AboutPreviewBox: React.FC<AboutBoxProps> = ({ about }) => (
  <ProfileBox title={<FormattedMessage {...messages.aboutTitle} />}>
    <AboutPreview description={about} />
  </ProfileBox>
);
