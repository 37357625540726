import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  messageFieldLabel: {
    id: 'Communicator.messageFieldLabel',
    defaultMessage: 'Write new...',
    description: 'New message field label',
  },
  sendMessageButtonLabel: {
    id: 'Communicator.sendMessageButtonLabel',
    defaultMessage: 'SEND',
    description: 'Send message button label',
  },
});
