import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PAGE_TITLE } from 'config/constants';
import { ROUTES } from 'config/routes';
import { ApiProvider } from 'api/ApiContext';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import { Layout } from 'components/Layout/Layout';
import { AuthProvider } from 'modules/auth/AuthContext';
import { OnboardingRoutes } from 'modules/onboarding/OnboardingRoutes';
import { NotificationProvider } from 'modules/notifications/NotificationContext';
import { NavContextProvider } from 'modules/navigation/NavContext';
import { Home } from 'pages/landings/Home/Home';
import { About } from 'pages/landings/About/About';
import { BecomePartner } from 'pages/landings/BecomePartner/BecomePartner';
import { ProfileRoutes } from 'modules/profiles/ProfileRoutes';
import { PrivacyPolicy, ErrorPage, TermsOfService, FindTheRightPeople } from 'pages/staticPages';
import { authorizationRoutes } from 'modules/auth/AuthorizationRoutes';
import { AuthorizationRoute } from 'modules/auth/components/AuthorizationRoute/AuthorizationRoute';
import { JobOffersRoutes } from 'modules/jobOffers/pages/JobOffersRoutes';
import { GrantsRoutes } from 'modules/grants/pages/GrantsRoutes';

import { messages } from './intl';
import { CookiePolicy } from './pages/staticPages/CookiePolicy/CookiePolicy';

const locale = 'en';

export const App: React.FC = () => (
  <IntlProvider locale={locale} key={locale} defaultLocale="en" messages={messages[locale]}>
    <HelmetProvider>
      <Helmet
        titleTemplate={`${PAGE_TITLE} | %s`}
        defaultTitle={PAGE_TITLE}
        htmlAttributes={{ lang: 'en' }}
      />

      <ApiProvider>
        <AuthProvider>
          <Router>
            <NavContextProvider>
              <NotificationProvider>
                <Layout
                  routesWithoutNav={[...authorizationRoutes, { path: ROUTES.ONBOARDING }]}
                  routesWithFloatingNav={[
                    { path: ROUTES.HOME, exact: true },
                    { path: ROUTES.ABOUT, exact: true },
                    { path: ROUTES.PARTNERSHIP, exact: true },
                  ]}
                >
                  <ScrollToTop>
                    <Switch>
                      <Route exact path={ROUTES.HOME} component={Home} />
                      <Route exact path={ROUTES.PARTNERSHIP} component={BecomePartner} />
                      <Route exact path={ROUTES.ABOUT} component={About} />
                      <Route
                        exact
                        path={ROUTES.FIND_THE_RIGHT_PEOPLE}
                        component={FindTheRightPeople}
                      />
                      <Route exact path={ROUTES.PRIVACY} component={PrivacyPolicy} />
                      <Route exact path={ROUTES.COOKIES} component={CookiePolicy} />
                      <Route exact path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
                      <Route exact path={ROUTES.TERMS_OF_SERVICE} component={FindTheRightPeople} />

                      <OnboardingRoutes path={ROUTES.ONBOARDING} />

                      <ProfileRoutes path={ROUTES.COMMUNITY} />

                      <JobOffersRoutes path={ROUTES.CAREER} />
                      {authorizationRoutes.map(({ path, ...routeProps }) => (
                        <AuthorizationRoute key={path} path={path} {...routeProps} />
                      ))}

                      <GrantsRoutes path={ROUTES.GRANTS} />
                      {authorizationRoutes.map(({ path, ...routeProps }) => (
                        <AuthorizationRoute key={path} path={path} {...routeProps} />
                      ))}

                      <Route component={ErrorPage} />
                    </Switch>
                  </ScrollToTop>
                </Layout>
              </NotificationProvider>
            </NavContextProvider>
          </Router>
        </AuthProvider>
      </ApiProvider>
    </HelmetProvider>
  </IntlProvider>
);
