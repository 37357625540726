import React from 'react';
import { ReactComponent as Github } from 'assets/icons/github.svg';
import { ReactComponent as Academia } from 'assets/icons/academia-edu.svg';
import { ReactComponent as Mendeley } from 'assets/icons/mendeley.svg';
import { ReactComponent as ResearchGate } from 'assets/icons/researchgate.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as Humaninit } from 'assets/icons/humaninit.svg';
import { ReactComponent as GoogleScholar } from 'assets/icons/google-scholar.svg';

interface SocialMediaType {
  icon: SVGComponent;
  name: React.ReactNode;
}

export const SOCIAL_MEDIA_TYPES: { [type: string]: SocialMediaType } = {
  github: {
    icon: Github,
    name: 'GitHub',
  },
  academia_edu: {
    icon: Academia,
    name: 'Academia edu',
  },
  mendeley: {
    icon: Mendeley,
    name: 'Mendeley',
  },
  research_gate: {
    icon: ResearchGate,
    name: 'Research gate',
  },
  twitter: {
    icon: Twitter,
    name: 'Twitter',
  },
  youtube: {
    icon: Youtube,
    name: 'Youtube',
  },
  instagram: {
    icon: Instagram,
    name: 'Instagram',
  },
  linkedin: {
    icon: LinkedIn,
    name: 'LinkedIn',
  },
  facebook: {
    icon: Facebook,
    name: 'Facebook',
  },
  humaninit: {
    icon: Humaninit,
    name: 'Humaninit',
  },
  google_scholar: {
    icon: GoogleScholar,
    name: 'Google scholar',
  },
};
