import React from 'react';
import { Discipline } from 'types';
import { ReactComponent as ArrowIcon } from 'assets/icons/fat-arrow.svg';

import styles from './CategoryItem.module.scss';

export interface CategoryItemProps {
  category: Discipline;
  onClick: (category: Discipline) => void;
}

export const CategoryItem: React.FC<CategoryItemProps> = ({ category, onClick }) => (
  <li className={styles.category}>
    <button type="button" onClick={() => onClick(category)} className={styles.button}>
      <ArrowIcon className={styles.icon} />
      {category.attributes.name}
    </button>
  </li>
);
