import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  title: {
    id: 'ErrorPage.title',
    defaultMessage: 'Ups. Something went {emphasizedTitle}.',
    description: 'Error page title',
  },
  emphasizedTitle: {
    id: 'ErrorPage.emphasizedTitle',
    defaultMessage: 'wrong',
    description: 'Error page title',
  },
  subtitle: {
    id: 'ErrorPage.subtitle',
    defaultMessage: 'If you keep experiencing any technical isssues, contact us at {contactEmail}.',
    description: 'Error page subtitle',
  },
});
