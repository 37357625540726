export class FileUploadConfig {
  constructor(public maxSizeInMB: number, public acceptedFormatsList: string[]) {}

  get maxSizeInBytes() {
    return this.maxSizeInMB * 1024 * 1024;
  }

  get acceptedFormats() {
    return this.acceptedFormatsList.join(', ');
  }
}

export const AVATAR_UPLOAD_CONFIG = new FileUploadConfig(4, ['.jpg', '.jpeg', '.png']);
