import axios, { AxiosInstance } from 'axios';
import humps from 'humps';
import { Headers } from 'modules/auth/models';

import { AuthClient } from './AuthClient';
import { ProfileClient } from './ProfileClient';
import { OnboardingClient } from './OnboardingClient';
import { ProfilesListClient } from './ProfilesListClient';
import { JobOffersClient } from './JobOffersClient';
import { GrantsClient } from './GrantsClient';
import { NewsletterSubscriptionsClient } from './NewsletterSubscriptionsClient';

export class Api {
  client: AxiosInstance;
  auth: AuthClient;
  profile: ProfileClient;
  onboarding: OnboardingClient;
  profilesList: ProfilesListClient;
  jobOffers: JobOffersClient;
  grants: GrantsClient;
  newsletterSubsciptions: NewsletterSubscriptionsClient;

  constructor(client?: AxiosInstance) {
    this.client = client || this.initializeClient();
    this.initializeResources();
  }

  private initializeClient = () => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      transformResponse: [...axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
      transformRequest: [
        data => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
        ...axios.defaults.transformRequest,
      ],
    });
  };

  private initializeResources() {
    this.auth = new AuthClient(this.client);
    this.profile = new ProfileClient(this.client);
    this.onboarding = new OnboardingClient(this.client);
    this.profilesList = new ProfilesListClient(this.client);
    this.jobOffers = new JobOffersClient(this.client);
    this.grants = new GrantsClient(this.client);
    this.newsletterSubsciptions = new NewsletterSubscriptionsClient(this.client);
  }

  addHeaders = (headers: Headers) => {
    this.client.defaults.headers.common = {
      ...this.client.defaults.headers.common,
      ...headers,
    };
  };

  removeHeaders = (headerKeys: string[]) => {
    headerKeys.forEach(key => {
      delete this.client.defaults.headers.common[key];
    });
  };
}

export const apiManager = new Api();
