import { useLayoutEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useScrollPosition = (
  effect: ({ currentPosition, lastPosition }: ScrollPositions) => void
) => {
  const storedScrollPosition = useRef(window.scrollY);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const lastPosition = storedScrollPosition.current;
      const currentPosition = window.scrollY;

      effect({ currentPosition, lastPosition });

      storedScrollPosition.current = currentPosition;
    };

    window.addEventListener('scroll', debounce(handleScroll, 20));

    return () => {
      window.removeEventListener('scroll', debounce(handleScroll, 20));
    };
  }, [effect]);
};

export interface ScrollPositions {
  currentPosition: number;
  lastPosition: number;
}
