import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NETWORK_EMAIL } from 'config/constants';
import { Button } from 'components/Button/Button';

import { messages } from '../../messages';

import styles from './TerminateModal.module.scss';

interface TerminateModalProps {
  onClose: () => void;
}

export const TerminateModal: React.FC<TerminateModalProps> = ({ onClose }) => (
  <div className={styles.container}>
    <h3 className={styles.title}>
      <FormattedMessage {...messages.terminateModalSubtitle} />
    </h3>
    <p className={styles.paragraph}>
      <FormattedMessage {...messages.terminateModalText} />
      <a href={`mailto: ${NETWORK_EMAIL}`}>{NETWORK_EMAIL}</a>
    </p>
    <Button onClick={onClose} className={styles.button}>
      <FormattedMessage {...messages.terminateButton} />
    </Button>
  </div>
);
