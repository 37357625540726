import React, { createContext, useState, useContext, useMemo } from 'react';
import { apiManager, Api } from 'api/ApiClient';
import { User } from 'api/AuthClient';
import { StorageManager, AUTH_HEADERS, InterceptedResponseError } from 'helpers';

import { AuthorizationData } from './models';

export interface AuthContextValues {
  isAuthorized: boolean;
  isOnboarded: boolean;
  user: User | null;
  updateUser: (user: User) => void;
  logIn: (authData: AuthorizationData) => void;
  logOut: () => void;
}

export const AuthContext = createContext<AuthContextValues | undefined>(undefined);

export const AuthProvider: React.FC<{ storage?: StorageManager; api?: Api }> = ({
  children,
  storage = new StorageManager(),
  api = apiManager,
}) => {
  const [isAuthorized, setAuthorized] = useState<boolean>(storage.hasAuthData);
  const [user, setUser] = useState<User | null>(storage.user);

  const isOnboarded = useMemo(
    () =>
      !!(
        user &&
        user.disciplinesCompleted &&
        user.affiliationsAndResidenceCompleted &&
        isAuthorized
      ),
    [user, isAuthorized]
  );

  const logOut = () => {
    storage.clearAll();
    setUser(null);
    setAuthorized(false);
    api.removeHeaders(Object.values(AUTH_HEADERS));
  };

  const addAuthInterceptor = () => {
    api.client.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          const responseError = new InterceptedResponseError(error);
          responseError.isUnauthorized && logOut();
        }
        throw error;
      }
    );
  };

  const logIn = ({ headers, user }: AuthorizationData) => {
    const { client, uid, 'access-token': accessToken } = headers;

    if (!client || !uid || !accessToken) return;

    storage.saveAuthHeaders({ ...headers, accessToken });
    storage.saveUser(user.data.data.attributes);

    setUser(user.data.data.attributes);

    setAuthorized(true);

    api.addHeaders({ client, uid, 'access-token': accessToken });
    addAuthInterceptor();
  };

  const updateUser = (user: User) => {
    setUser(user);
    storage.saveUser(user);
  };

  api.addHeaders(storage.getAuthHeaders());

  addAuthInterceptor();

  return (
    <AuthContext.Provider value={{ user, updateUser, isAuthorized, isOnboarded, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth have to be used within AuthProvider');

  return context;
};
