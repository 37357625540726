import React from 'react';
import classnames from 'classnames';
import { FieldError } from 'components/FieldError/FieldError';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  label: React.ReactNode;
}

export const Checkbox: React.FC<CheckboxProps> = ({ name, label, checked, hasError, ...props }) => (
  <label className={styles.wrapper}>
    <input
      type="checkbox"
      checked={checked}
      name={name}
      className={styles.input}
      data-testid={name}
      {...props}
    />
    <div
      className={classnames(styles.box, {
        [styles.checked]: checked,
        [styles.invalid]: hasError,
      })}
    >
      <TickIcon className={styles.tick} />
    </div>
    <p className={styles.labelWrapper}>
      <span className={styles.label}>{label}</span>
      {hasError && name && <FieldError name={name} />}
    </p>
  </label>
);
