import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NETWORK_EMAIL } from 'config/constants';

import { Step } from './Step/Step';
import { messages } from './messages';
import styles from './Stepper.module.scss';

type Step = {
  title: React.ReactNode;
  description: React.ReactNode;
};

interface StepperProps {
  activeStep: number;
  steps: Step[];
}

export const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.stepsContainer}>
        {steps.map((item, index) => (
          <Step
            key={index}
            isActive={activeStep === index}
            isDone={index < activeStep}
            number={index + 1}
            title={item.title}
            description={item.description}
          />
        ))}
      </ul>

      <div className={styles.help}>
        <h4 className={styles.helpTitle}>
          <FormattedMessage {...messages.helpTitle} />
        </h4>
        <p className={styles.helpDescription}>
          <FormattedMessage {...messages.helpDescription} />
          <a href={`mailto:${NETWORK_EMAIL}`} className={styles.mail}>
            {NETWORK_EMAIL}
          </a>
        </p>
      </div>
    </div>
  );
};
