import { useState, useEffect, useCallback } from 'react';
import { DeserializedGrantInstitution, DeserializedGrantName } from 'types';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';

export const useGrantInstitutions = () => {
  const [grantInstitutions, setGrantInstitutions] = useState<DeserializedGrantInstitution[]>([]);
  const { profile } = useApi();
  const handleError = useApiErrorHandler();

  const getGrantInstitutions = useCallback(async () => {
    try {
      const response = await profile.getGrantInstitutions();
      setGrantInstitutions(response.data.data);
    } catch (error) {
      handleError(error);
    }
  }, [profile, handleError]);

  useEffect(() => {
    getGrantInstitutions();
  }, [getGrantInstitutions]);

  return grantInstitutions;
};

export const useGrantNames = (): {
  getGrantNames: (institutionId: string | undefined) => void;
  grantNames: DeserializedGrantName[];
  areNamesLoading: boolean;
} => {
  const [grantNames, setGrantNames] = useState<DeserializedGrantName[]>([]);
  const [areNamesLoading, setNamesLoading] = useState<boolean>(false);

  const { profile } = useApi();
  const handleError = useApiErrorHandler();

  const getGrantNames = async (institutionId: string | undefined) => {
    try {
      if (institutionId) {
        setNamesLoading(true);
        const response = await profile.getGrantNames(institutionId);
        setGrantNames(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setNamesLoading(false);
    }
  };

  return { getGrantNames, grantNames, areNamesLoading };
};
