import { User } from 'api/AuthClient';

export const mockUser: User = {
  id: 12345,
  provider: 'email',
  uid: 'han.solo@rebellion.com',
  allowPasswordChange: true,
  email: 'han.solo@rebellion.com',
  firstName: 'Han',
  lastName: 'Solo',
  termsAccepted: true,
  newsletterAccepted: true,
  disciplinesCompleted: true,
  affiliationsAndResidenceCompleted: true,
  disciplines: { data: [] },
};

export const mockStoredUser = JSON.stringify(mockUser);
