import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonPolonium } from 'components/Button/ButtonPolonium/ButtonPolonium';

import { messages } from './poloniumMessages';
import styles from './PoloniumSection.module.scss';

export const PoloniumSection: React.FC = () => (
  <section>
    <div className={styles.container}>
      <h2 className={styles.title}>
        <FormattedMessage {...messages.poloniumTitle} />
      </h2>

      <h5 className={styles.subtitle}>
        <FormattedMessage {...messages.poloniumSubtitle} />
      </h5>

      <ButtonPolonium />
    </div>
  </section>
);
