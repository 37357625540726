import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  gainTitle: {
    id: 'Home.gainTitle',
    defaultMessage: 'What’s in it for you?',
    description: 'What do You gain: title',
  },
  gainSubtitle: {
    id: 'Home.gainSubtitle',
    defaultMessage: 'create your profile',
    description: 'What do You gain: subtitle',
  },
  gainFirst: {
    id: 'Home.gainFirst',
    defaultMessage: 'Are you planning to go abroad and searching for opportunities?',
    description: 'What do You gain: First feature title',
  },
  gainFirstBullet1: {
    id: 'Home.gainFirstBullet1',
    defaultMessage:
      'Be the first to hear about new funding opportunities, exchange programmes, funding schemes, and more – with a special focus on Polish researchers or others affiliated in Poland!',
    description: 'What do You gain: First feature bullet 1',
  },
  gainFirstBullet2: {
    id: 'Home.gainFirstBullet2',
    defaultMessage: 'Find collaborations and supportive mentors from all over the world',
    description: 'What do You gain: First feature bullet 2',
  },
  gainFirstBullet3: {
    id: 'Home.gainFirstBullet3',
    defaultMessage: 'Ask fellow Poles directly about their experience doing research abroad',
    description: 'What do You gain: First feature bullet 3',
  },
  gainSecond: {
    id: 'Home.gainSecond',
    defaultMessage: 'Are you interested in career and funding opportunities in Poland?',
    description: 'What do You gain: Second feature title',
  },
  gainSecondBullet1: {
    id: 'Home.gainSecondBullet1',
    defaultMessage: 'Learn about cutting edge research happening in Poland',
    description: 'What do You gain: Second feature bullet 1',
  },
  gainSecondBullet2: {
    id: 'Home.gainSecondBullet2',
    defaultMessage:
      'Be the first to hear about new vacancies or funding opportunities in PL, including those open in particular for foreign researchers',
    description: 'What do You gain: Second feature bullet 2',
  },
  gainSecondBullet3: {
    id: 'Home.gainSecondBullet3',
    defaultMessage:
      'Ask other researchers about life and their career in Poland – hear from your potential colleagues directly!',
    description: 'What do You gain: Second feature bullet 3',
  },
  gainThird: {
    id: 'Home.gainThird',
    defaultMessage: 'Do you want to exchange experiences and ideas with others?',
    description: 'What do You gain: Second feature title',
  },
  gainThirdBullet1: {
    id: 'Home.gainThirdBullet1',
    defaultMessage: 'Connect with people in your field to exchange knowledge and ideas',
    description: 'What do You gain: Third feature bullet 1',
  },
  gainThirdBullet2: {
    id: 'Home.gainThirdBullet2',
    defaultMessage:
      'Ask for feedback and advice – find someone who applied successfully for that programme or funding you’re interested in and benefit from their experience',
    description: 'What do You gain: Third feature bullet 2',
  },
  gainThirdBullet3: {
    id: 'Home.gainThirdBullet3',
    defaultMessage: 'Get early access & special discounts for our SPP conferences and other events',
    description: 'What do You gain: Third feature bullet 3',
  },
  gainFourth: {
    id: 'Home.gainFourth',
    defaultMessage: 'Or do you simply want to keep growing as a researcher and as a person?',
    description: 'What do You gain: Second feature title',
  },
  gainFourthBullet1: {
    id: 'Home.gainFourthBullet1',
    defaultMessage:
      'Talk to people with different careers, experiences and backgrounds – explore and find inspiration ',
    description: 'What do You gain: Fourth feature bullet 1',
  },
  gainFourthBullet2: {
    id: 'Home.gainFourthBullet2',
    defaultMessage:
      'Meet people you wouldn’t cross paths with otherwise, including business and institution representatives',
    description: 'What do You gain: Fourth feature bullet 2',
  },
  gainFourthBullet3: {
    id: 'Home.gainFourthBullet3',
    defaultMessage:
      'Find fellow Poles and Polonophiles to discuss research with over a nostalgic glass of kompot, wherever you are',
    description: 'What do You gain: Fourth feature bullet 3',
  },
  gainTagline: {
    id: 'Home.gainTagline',
    defaultMessage: 'Be where the <strike>magic</strike> science happens!',
    description: 'What do You gain: Fourth feature bullet 3',
  },
});
