import React from 'react';
import classnames from 'classnames';
import { CustomArrowProps } from 'react-slick';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

import styles from './SliderArrowButton.module.scss';

export interface SliderArrowButtonProps extends CustomArrowProps {
  prev?: boolean;
}

export const SliderArrowButton: React.FC<SliderArrowButtonProps> = ({ prev, onClick }) => (
  <div className={styles.container}>
    <div className={styles.buttonWrapper}>
      <button onClick={onClick} className={classnames(styles.button, prev && styles.prev)}>
        <Arrow className={styles.icon} />
      </button>
    </div>
  </div>
);
