import React from 'react';
import { ReactComponent as LinkIcon } from 'assets/icons/new-link.svg';
import { FormattedMessage } from 'react-intl';

import styles from '../GrantCard.module.scss';
import { messages } from '../messages';
import { Button } from '../../../../../../../components/Button/Button';

const GrantLink: React.FC<{
  urlReference: string;
  grantId: number;
  urlRefId: number | undefined;
}> = ({ urlReference, grantId, urlRefId }) => {
  return (
    <div className={styles.linkContainer}>
      <a href={urlReference} target="_blank" rel="noopener noreferrer">
        {/*<LinkIcon className={styles.linkIcon} id={urlRefId === grantId ? 'ninth-step' : ''} />*/}
        <Button
          type={'button'}
          id={urlRefId === grantId ? 'ninth-step' : ''}
          className={styles.linkButton}
          iconClassName={styles.linkIcon}
          icon={<LinkIcon />}
        >
          <FormattedMessage {...messages.grantLinkButtonText} />
        </Button>
      </a>
    </div>
  );
};

export default GrantLink;
