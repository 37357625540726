import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter_list.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { SearchGrantBar } from '../../../../components/SearchGrantBar/SearchGrantBar';
import { GrantListFilterValues } from '../../../../../../types';
import { DropdownSelectMenu } from '../../../../components/DropdownSelectMenu/DropdownSelectMenu';
import { Button } from '../../../../../../components/Button/Button';
import { GetGrantsProps } from '../../GrantsList';
import { Modal } from '../../../../../../components/Modal/Modal';

import { messages } from './messages';
import styles from './SearchContainer.module.scss';

type SearchContainerProps = {
  results: number;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setLocationsQuery: React.Dispatch<React.SetStateAction<string[]>>;
  setStagesQuery: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isFetchingGrants: boolean;
  getGrants: ({ query }: GetGrantsProps) => Promise<void>;
  stagesQuery: string[];
  locationsQuery: string[];
  searchQuery: string;
  stages?: string[];
  countries?: string[];
};

const validationSchema = object().shape({
  search: string(),
  locations: array(),
  stages: array(),
});

const MailtoLink = () => {
  return (
    <a href="mailto:network@poloniumfoundation.org?subject=Add%20a%20Grant%20to%20the%20List&body=Hello,%0D%0A%0D%0AI%20would%20like%20to%20add%20a%20grant%20from%20my%20institution%20to%20the%20list.%20Here%20are%20the%20details:%0D%0A%0D%0A[Please%20include%20details%20about%20the%20grant%20here]%0D%0A%0D%0AThank%20you!">
      contact us
    </a>
  );
};

export const SearchContainer: React.FC<SearchContainerProps> = ({
  results,
  setSearchQuery,
  setLocationsQuery,
  setStagesQuery,
  setCurrentPage,
  isFetchingGrants,
  getGrants,
  searchQuery,
  stagesQuery,
  locationsQuery,
  stages,
  countries,
}: SearchContainerProps) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const handleFilterGrantSubmit = (values: GrantListFilterValues) => {
    setCurrentPage(1);
    setSearchQuery(values.search as string);
    setLocationsQuery(values.locations as string[]);
    setStagesQuery(values.stages as string[]);
    const params = new URLSearchParams();

    if (values.search) {
      params.append('search', values.search);
    }
    if (values.locations) {
      values.locations.forEach(country => params.append('countries[]', country));
    }
    if (values.stages) {
      values.stages.forEach(stage => params.append('stages[]', stage));
    }
    const finalUrl = `?page=${1}&items=${48}&${params.toString()}`;
    if (values.stages || values.locations || values.search) {
      getGrants({ query: finalUrl }).catch(e => console.error(e.response.data ?? e.message ?? e));
    } else {
      getGrants({ query: `?page=${1}&items=${48}` }).catch(e =>
        console.error(e.response.data ?? e.message ?? e)
      );
    }
    setIsModalOpen(false);
  };

  const [selectedLocationItems, setSelectedLocationItems] = useState(0);
  const [selectedStagesItems, setSelectedStagesItems] = useState(0);

  const { formatMessage } = useIntl();

  const handleResetForm = () => {
    setSearchQuery('');
    setLocationsQuery([]);
    setStagesQuery([]);
    setSelectedLocationItems(0);
    setSelectedStagesItems(0);
    getGrants({ query: `?page=${1}&items=${48}` }).catch(e =>
      console.error(e.response.data ?? e.message ?? e)
    );
  };

  const stagesOptions = stages?.map(stage => ({
    label: stage,
    value: stage,
    disabled: false,
  }));

  const countriesOptions = countries?.map(country => ({
    label: country,
    value: country,
    disabled: false,
  }));

  useEffect(() => {
    if (locationsQuery.length === 0) {
      setSelectedLocationItems(0);
    }
    if (stagesQuery.length === 0) {
      setSelectedStagesItems(0);
    }
  }, [stagesQuery, locationsQuery]);

  return (
    <div className={styles.searchContainer}>
      <Formik<GrantListFilterValues>
        initialValues={{
          search: '',
          locations: [],
          stages: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleFilterGrantSubmit}
        onReset={handleResetForm}
      >
        {({ values, resetForm, setFieldValue, submitForm }) => (
          <>
            <Form className={styles.form} noValidate>
              <DropdownSelectMenu
                id={'tenth-step'}
                name={'locations'}
                label={'Location'}
                query={locationsQuery}
                setQuery={setLocationsQuery}
                setFieldValue={setFieldValue}
                placeholder={'Select Location'}
                selectedItems={selectedLocationItems}
                setSelectedItems={setSelectedLocationItems}
                submit={submitForm}
                wrapperClass={styles.dropdownSelect}
                options={
                  countriesOptions ?? [
                    { label: 'Poland', value: 'Poland', disabled: false },
                    { label: 'Slovakia', value: 'Slovakia', disabled: false },
                  ]
                }
              />
              <DropdownSelectMenu
                id={'eleventh-step'}
                name={'stages'}
                label={'Stage'}
                query={stagesQuery}
                setQuery={setStagesQuery}
                setFieldValue={setFieldValue}
                placeholder={'Select Stage'}
                selectedItems={selectedStagesItems}
                setSelectedItems={setSelectedStagesItems}
                submit={submitForm}
                wrapperClass={styles.dropdownSelect}
                options={
                  stagesOptions ?? [
                    { label: 'Bachelors', value: 'bachelors', disabled: false },
                    { label: 'Masters', value: 'masters', disabled: false },
                    { label: 'Pre-phd', value: 'pre_phd', disabled: false },
                    { label: 'Post-phd', value: 'post_phd', disabled: false },
                    { label: 'Faulty', value: 'faculty', disabled: false },
                  ]
                }
              />
              <SearchGrantBar
                id={'twelfth-step'}
                name={'search'}
                values={values}
                query={searchQuery}
                setFieldValue={setFieldValue}
                onSearchSubmit={handleFilterGrantSubmit}
              />
              <div className={styles.filterButtonContainer}>
                <button
                  type={'button'}
                  className={styles.clearContainer}
                  onClick={() => resetForm()}
                >
                  <TrashIcon className={styles.trashIcon} />
                  <p className={styles.clearText}>
                    <FormattedMessage {...messages.searchLabelClear} />
                  </p>
                </button>
                <Button
                  type="submit"
                  small
                  className={styles.submit}
                  isProcessing={isFetchingGrants}
                  disabled={isFetchingGrants}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      submitForm().catch(e => console.error(e));
                    }
                  }}
                >
                  {formatMessage(messages.submitButton)}
                </Button>
                <button
                  type={'button'}
                  className={styles.filterIconMobile}
                  onClick={() => setIsModalOpen(true)}
                >
                  <FilterIcon className={styles.filterIcon} />
                </button>
              </div>
              <div className={styles.resultsContainer}>
                <div className={styles.comprehensiveListMessage}>
                  {<FormattedMessage {...messages.comprehensiveListMessage} />}
                  &nbsp;
                  <MailtoLink />.
                </div>
                <div className={styles.ministryGrantMessage}>
                  {<FormattedMessage {...messages.ministryGrantMessage} />}
                </div>
                <div className={styles.results}>
                  <span>({results ?? 0}) </span>
                  {<FormattedMessage {...messages.results} />}
                </div>
                <button
                  type={'button'}
                  className={styles.clearContainerMobile}
                  onClick={() => resetForm()}
                >
                  <TrashIcon className={styles.trashIcon} />
                  <p className={styles.clearText}>
                    <FormattedMessage {...messages.searchLabelClear} />
                  </p>
                </button>
              </div>
              <Modal
                onClose={() => setIsModalOpen(false)}
                modalClassName={styles.modal}
                isOpen={modalIsOpen}
              >
                <div className={styles.modalForm}>
                  <div className={styles.closeIconContainer}>
                    <CloseIcon className={styles.closeIcon} onClick={() => setIsModalOpen(false)} />
                  </div>
                  <div className={styles.dropdownsWithButtonsContainer}>
                    <div className={styles.dropdownsContainer}>
                      <DropdownSelectMenu
                        id={'tenth-step'}
                        name={'locations'}
                        label={'Location'}
                        query={locationsQuery}
                        setQuery={setLocationsQuery}
                        setFieldValue={setFieldValue}
                        placeholder={'Select Location'}
                        selectedItems={selectedLocationItems}
                        setSelectedItems={setSelectedLocationItems}
                        submit={submitForm}
                        wrapperClass={styles.dropdownSelectMobile}
                        options={
                          countriesOptions ?? [
                            { label: 'Poland', value: 'Poland', disabled: false },
                            { label: 'Slovakia', value: 'Slovakia', disabled: false },
                          ]
                        }
                      />
                      <DropdownSelectMenu
                        id={'eleventh-step'}
                        name={'stages'}
                        label={'Stage'}
                        query={stagesQuery}
                        setQuery={setStagesQuery}
                        setFieldValue={setFieldValue}
                        placeholder={'Select Stage'}
                        selectedItems={selectedStagesItems}
                        setSelectedItems={setSelectedStagesItems}
                        submit={submitForm}
                        wrapperClass={styles.dropdownSelectMobile}
                        options={
                          stagesOptions ?? [
                            { label: 'Bachelors', value: 'bachelors', disabled: false },
                            { label: 'Masters', value: 'masters', disabled: false },
                            { label: 'Pre-phd', value: 'pre_phd', disabled: false },
                            { label: 'Post-phd', value: 'post_phd', disabled: false },
                            { label: 'Faulty', value: 'faculty', disabled: false },
                          ]
                        }
                      />
                    </div>
                    <div className={styles.buttonsMobileContainer}>
                      <Button
                        type="submit"
                        onClick={() => {
                          submitForm().catch(e => console.error(e.response.data ?? e.message ?? e));
                          setIsModalOpen(false);
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            submitForm().catch(e => console.error(e));
                            setIsModalOpen(false);
                          }
                        }}
                        small
                        className={styles.mobileSubmit}
                        isProcessing={isFetchingGrants}
                        disabled={isFetchingGrants}
                      >
                        {formatMessage(messages.submitButton)}
                      </Button>
                      <button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>
                        {formatMessage(messages.cancelButton)}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
