import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'About.pageTitle',
    defaultMessage: 'About Us',
    description: 'About us landing page',
  },
  heroTitle: {
    id: 'About.heroTitle',
    defaultMessage: 'Find out more about Polonium Project.',
    description: 'Hero title',
  },
  heroMessage1: {
    id: 'About.heroMessage',
    defaultMessage:
      'Polonium Foundation is NGO established in 2016. We are turning Polish brain drain into brain circulation by unlocking the potential of Polish research diaspora. We connect its members at our events, the community, and provide policy recommendations.',
    description: 'Hero message',
  },
  heroMessage2: {
    id: 'About.heroMessage2',
    defaultMessage:
      '{bold} We are turning Polish brain drain into brain circulation by unlocking the potential of Polish research diaspora. We connect its members at our events, the community, and provide policy recommendations.',
    description: 'Hero message',
  },
  heroMessageEmphasized: {
    id: 'About.heroMessageEmphasized',
    defaultMessage: 'Polonium Foundation is NGO established in 2016.',
    description: 'Hero message emphasize',
  },
  heroButton: {
    id: 'About.heroButton',
    defaultMessage: 'Join for free',
    description: 'Join for free',
  },
  becomePartnerButton: {
    id: 'About.becomePartnerButton',
    defaultMessage: 'Become A Partner',
    description: 'Become A Partner',
  },
  poloniumTitle: {
    id: 'About.poloniumTitle',
    defaultMessage: 'About Polonium',
    description: 'About Polonium title',
  },
  poloniumSubtitle: {
    id: 'About.poloniumSubtitle',
    defaultMessage:
      '{bold} We are turning Polish brain drain into brain circulation by unlocking the potential of Polish research diaspora. We connect its members at our events, the community, and provide policy recommendations.',
    description: 'About Polonium subtitle',
  },
  poloniumSubtitleEmphasized: {
    id: 'About.poloniumSubtitleEmphasized',
    defaultMessage: 'Polonium Foundation is NGO established in 2016.',
    description: 'Hero message emphasize',
  },
});
