import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import Logo from 'assets/icons/logo_bright.png';

import { getRandomBackground } from './getRandomBackground';
import { AuthHomeButton } from './AuthHomeButton/AuthHomeButton';
import styles from './AuthLayout.module.scss';

const randomBackground = getRandomBackground();

export interface AuthLayoutProps {
  background?: string;
  classNames?: {
    title?: string;
    description?: string;
  };
  description?: React.ReactNode;
  title?: React.ReactNode;
  authNavbar?: React.ReactNode;
  stepperComponent?: React.ReactNode;
  withOnboarding?: boolean;
  withPhotoBackground?: boolean;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  background,
  children,
  classNames = {},
  description,
  stepperComponent,
  title,
  authNavbar,
  withOnboarding,
  withPhotoBackground,
}) => (
  <div className={styles.layout}>
    <aside
      className={classnames(styles.sidebar, { [styles.stretched]: !withPhotoBackground })}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div
        className={classnames(styles.wrapper, {
          [styles.withPhotoBackground]: withPhotoBackground,
        })}
      >
        <Link to={ROUTES.HOME}>
          <img src={Logo} className={styles.logo} alt="Logo" />
        </Link>
        {withPhotoBackground && <AuthHomeButton className={styles.backButton} />}
        {stepperComponent}
      </div>
    </aside>
    {authNavbar}
    <div className={styles.navbarMobile}>{stepperComponent}</div>
    <div
      className={classnames(styles.content, {
        [styles.withOnboarding]: withOnboarding,
      })}
    >
      <h1 className={classnames(styles.title, classNames.title)}>{title}</h1>
      {description && (
        <p className={classnames(styles.description, classNames.description)}>{description}</p>
      )}
      {children}
    </div>
  </div>
);

AuthLayout.defaultProps = {
  background: randomBackground,
};
