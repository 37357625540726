import React from 'react';
import { ButtonLink } from 'components/Button/ButtonLink';

import { AuthHomeButton } from '../AuthHomeButton/AuthHomeButton';

import styles from './AuthNavbar.module.scss';

interface AuthNavbarProps {
  description: string;
  path: string;
  link: string;
}

export const AuthNavbar: React.FC<AuthNavbarProps> = ({ description, path, link }) => (
  <nav className={styles.navbar}>
    <AuthHomeButton />
    <span className={styles.text}>{description}</span>
    <ButtonLink to={path} secondary={true} className={styles.linkButton}>
      {link}
    </ButtonLink>
  </nav>
);
