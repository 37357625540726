import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';

import { Notification, NotificationContextValues, NotificationTimeout } from './types';
import { NotificationList } from './components';

export const NotificationContext = createContext<NotificationContextValues | null>(null);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const timeoutList = useRef<NotificationTimeout[]>([]);
  const defaultDuration = 8000;

  const deleteNotification = useCallback((id: number) => {
    timeoutList.current.filter(item => item.id === id);
    setNotifications(prevNotifications => prevNotifications.filter(item => item.id !== id));
  }, []);

  const createNotification = useCallback(
    (notificationBase: Notification) => {
      const id = +new Date();

      const newNotification = {
        ...notificationBase,
        id,
        duration: notificationBase.duration || defaultDuration,
      };

      const timeout = setTimeout(() => deleteNotification(id), newNotification.duration);

      setNotifications(prevNotifications => [...prevNotifications, newNotification]);
      timeoutList.current.push({ id, timeout });
    },
    [deleteNotification]
  );

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutList.current.forEach(({ timeout }) => clearTimeout(timeout));
    };
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, createNotification, deleteNotification }}>
      {children}
      <NotificationList />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) throw new Error('useNotification have to be used within NotificationProvider');

  return context;
};
