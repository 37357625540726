import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../config/routes';

import styles from './CookiePolicy.module.scss';
import { CookiePolicyStatements } from './CookiePolicyStatements';

export const CookiePolicy: React.FC = () => {
  return (
    <section className={styles.pageWrapper}>
      <h1 className={styles.title}>Cookie policy</h1>
      <p className={styles.paragraph}>
        This cookie policy ("Policy") describes what cookies are an agreement between Fundacja
        Polonium, Pl. Bankowy 2, 00-095 Warszawa ("Fundacja Polonium, Pl. Bankowy 2, 00-095
        Warszawa", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth
        the general terms and conditions of your use of the poloniumnetwork.org website and any of
        its products or services (collectively, "Website" or "Services").
      </p>
      <p className={styles.paragraph}>
        You should read this Policy so you can understand what type of cookies we use, the
        information we collect using cookies and how that information is used. It also describes the
        choices available to you regarding accepting or declining the use of cookies. For further
        information on how we use, store and keep your personal data secure, see our&nbsp;
        <Link to={ROUTES.PRIVACY} target="_blank">
          Privacy Policy
        </Link>
        .
      </p>
      {CookiePolicyStatements.map((statement, idx) => {
        return (
          <div key={idx}>
            <h2 className={styles.statementTitle}>{statement.title}</h2>
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: statement.content }}
            />
          </div>
        );
      })}
    </section>
  );
};
