import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { validators } from 'config/errors/validators';
import { object } from 'yup';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { commonMessages } from 'intl/commonMessages';
import { useNotification } from 'modules/notifications/NotificationContext';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { SaveButton, CancelButton } from 'components/Button/Button';
import { EditButton } from 'modules/profiles/components';

import styles from '../../ProfileSettings.module.scss';

interface PasswordFormProps {
  password: string;
  passwordConfirmation: string;
}

const validationSchema = object().shape({
  password: validators.password,
  passwordConfirmation: validators.passwordConfirmation,
});

export const PasswordForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const handleError = useApiErrorHandler();
  const { profile } = useApi();
  const { createNotification } = useNotification();

  const [isEdited, setEdited] = useState<boolean>(false);

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const handleSubmit = async ({ password, passwordConfirmation }: PasswordFormProps) => {
    try {
      await profile.updatePassword(password, passwordConfirmation);
      createNotification({
        type: 'success',
        message: <FormattedMessage {...commonMessages.changePasswordSuccess} />,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setEdited(false);
    }
  };

  const cancel = () => {
    setEdited(false);
  };

  const onEdit = () => {
    setEdited(true);
  };

  return (
    <div className={styles.inputsContainer}>
      <EditButton onClick={onEdit} disabled={isEdited} className={styles.editButton} />
      {isEdited ? (
        <Formik<PasswordFormProps>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <PasswordField
                name="password"
                label={formatMessage(commonMessages.passwordLabel)}
                prompt={formatMessage(commonMessages.passwordPrompt)}
                placeholder={formatMessage(commonMessages.passwordPlaceholder)}
              />
              <PasswordField
                name="passwordConfirmation"
                label={formatMessage(commonMessages.passwordConfirmationLabel)}
                placeholder={formatMessage(commonMessages.passwordPlaceholder)}
              />
              <div className={styles.buttonContainer}>
                <CancelButton onClick={cancel} className={styles.button} disabled={isSubmitting} />
                <SaveButton
                  isProcessing={isSubmitting}
                  disabled={isSubmitting}
                  className={styles.button}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <p className={styles.label}>
          <FormattedMessage {...commonMessages.passwordLabel} />
          <span className={styles.input}>
            <FormattedMessage {...commonMessages.passwordValuePlaceholder} />
          </span>
        </p>
      )}
    </div>
  );
};
