import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import { object, array } from 'yup';
import { useApi } from 'api/ApiContext';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { getName, useGrantInstitutions, useApiErrorHandler } from 'helpers';
import { validators } from 'config/errors/validators';
import { Grants, NestedEntity, GrantFormField } from 'types';

import { ProfileBox, ProfileBoxFormButtons, AddButton } from '../';
import { messages } from '../../messages';

import { GrantField } from './GrantField';
import styles from './GrantBox.module.scss';

const emptyGrant = {
  provider: '',
  name: '',
};

const generateSelectOption = (entity: NestedEntity) => ({
  label: getName(entity),
  value: getName(entity),
});

const validationSchema = object().shape({
  grants: array().of(
    object().shape({
      provider: validators.grantProvider,
      name: validators.grantName,
    })
  ),
});

export interface GrantsForm {
  grants: GrantFormField[];
}

export interface GrantBoxProps {
  grants: Grants[];
}

export const GrantBox: React.FC<GrantBoxProps> = ({ grants }) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isAddingGrant, setAddingGrant] = useState<boolean>(false);
  const grantInstitutions = useGrantInstitutions();
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  const edit = () => setEditing(true);

  const handleSubmit = async (
    { grants }: GrantsForm,
    { setSubmitting }: FormikHelpers<GrantsForm>
  ) => {
    try {
      const { data } = await profile.updateGrants(grants);
      setUserData(data);
      setSubmitting(false);
      setEditing(false);
      setAddingGrant(false);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  return (
    <ProfileBox
      title={<FormattedMessage {...messages.grantsTitle} />}
      onEdit={grants.length ? edit : undefined}
      isEdited={isEditing || isAddingGrant}
    >
      <Formik<GrantsForm>
        initialValues={{
          grants: grants.map(({ attributes: { grantInstitution, grantName } }) => ({
            provider: generateSelectOption(grantInstitution),
            name: generateSelectOption(grantName),
          })),
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form className={styles.form}>
            <FieldArray name="grants">
              {({ push, remove, form }) => {
                const grantFields: GrantFormField[] = form.values.grants;
                const lastIndex = grantFields.length - 1;

                const addGrant = () => {
                  setAddingGrant(true);
                  push(emptyGrant);
                };

                const removeGrant = (index: number) => {
                  remove(index);
                };

                const cancel = () => {
                  setEditing(false);
                  setAddingGrant(false);
                  resetForm();
                };

                return (
                  <>
                    {isEditing ? (
                      grantFields.map((_grant, index) => (
                        <GrantField
                          key={`grant-${index}`}
                          index={index}
                          onRemove={removeGrant}
                          institutions={grantInstitutions}
                        />
                      ))
                    ) : (
                      <>
                        <GrantsPreview grants={grants} onAdd={addGrant} isAdding={isAddingGrant} />
                        {isAddingGrant && (
                          <GrantField index={lastIndex} institutions={grantInstitutions} />
                        )}
                      </>
                    )}
                    {(isAddingGrant || isEditing) && (
                      <ProfileBoxFormButtons
                        className={styles.submit}
                        onCancel={cancel}
                        isSubmitting={isSubmitting}
                      />
                    )}
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </ProfileBox>
  );
};

interface GrantPreviewProps extends GrantBoxProps {
  onAdd?: () => void;
  isAdding?: boolean;
  isViewing?: boolean;
}

const GrantsPreview: React.FC<GrantPreviewProps> = ({ grants, onAdd, isAdding, isViewing }) => {
  if (!grants.length && !isAdding) {
    return (
      <>
        <p className={styles.boxText}>
          <FormattedMessage {...messages.grantsDefaultText} />
        </p>
        <AddButton onClick={onAdd} />
      </>
    );
  }
  return (
    <>
      {grants.map(({ attributes: { grantInstitution, grantName } }) => (
        <div
          key={`${getName(grantInstitution)}_${getName(grantName)}`}
          className={styles.container}
        >
          <h5 className={styles.institution}>{getName(grantInstitution)}</h5>
          <p className={styles.title}>{getName(grantName)}</p>
        </div>
      ))}
      {!isAdding && !isViewing && <AddButton onClick={onAdd} />}
    </>
  );
};

export const GrantsPreviewBox: React.FC<GrantBoxProps> = ({ grants }) => (
  <ProfileBox title={<FormattedMessage {...messages.grantsTitle} />}>
    <GrantsPreview grants={grants} isViewing={true} />
  </ProfileBox>
);
