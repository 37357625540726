import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonPolonium } from 'components/Button/ButtonPolonium/ButtonPolonium';

import { messages } from '../../messages';

import styles from './AboutPoloniumSection.module.scss';

export const AboutPoloniumSection: React.FC = () => (
  <section className={styles.section}>
    <div className={styles.container}>
      <h2 className={styles.title}>
        <FormattedMessage {...messages.poloniumTitle} />
      </h2>

      <div className={styles.paragraphCentered}>
        <FormattedMessage
          {...messages.poloniumSubtitle}
          values={{
            bold: (
              <span className={styles.bold}>
                <FormattedMessage {...messages.poloniumSubtitleEmphasized} />
              </span>
            ),
          }}
        />
      </div>

      <ButtonPolonium />
    </div>
  </section>
);
