import { UserProfile, DeserializedUserProfile } from 'types';

import { deserializeAffiliations } from './deserializeAffiliations';
import { deserializeLocation } from './deserializeLocation';

export const deserializeProfile = (profile: { data: UserProfile }): DeserializedUserProfile => {
  const { attributes } = profile.data;
  return {
    ...attributes,
    affiliations: deserializeAffiliations(attributes.affiliations),
    currentResidence: deserializeLocation(attributes.currentResidence),
    birthPlace: deserializeLocation(attributes.birthPlace),
    id: `${profile.data.id}`,
  };
};
