import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  titleHighlighted: {
    id: 'Home.newsletterTitleHighlighted',
    defaultMessage: 'Register & subscribe – , ',
    description: 'Newsletter section title - highlighted',
  },
  newsletterTitle: {
    id: 'Home.newsletterTitle',
    defaultMessage: 'be the first to know.',
    description: 'Newsletter section title',
  },
  emailLabel: {
    id: 'Home.newsletterEmailLabel',
    defaultMessage: 'e-mail:',
    description: 'Newsletter email field label',
  },
  newsletterSubmitButton: {
    id: 'Home.newsletterSubmitButton',
    defaultMessage: 'Subscribe',
    description: 'Newsletter submit button label',
  },
  subscriptionSubmitted: {
    id: 'Home.subscriptionSubmitted',
    defaultMessage:
      'Your e-mail address has been saved. Thank you for subscribing to our newsletter!',
    description: 'Newsletter submit button label',
  },
});
