import { ROUTES } from 'config/routes';
import { useAuthContext } from 'modules/auth/AuthContext';

export const useRedirect = () => {
  const { user, isOnboarded } = useAuthContext();

  if (isOnboarded || !user) return ROUTES.HOME;

  return user && user.disciplinesCompleted ? ROUTES.AFFILIATION : ROUTES.DISCIPLINE;
};
