import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export const messages: { [key: string]: MessageDescriptor } = defineMessages({
  pageTitle: {
    id: 'ResetPassword.pageTitle',
    defaultMessage: 'Set up a new password',
    description: 'ResetPassword page title',
  },
  header: {
    id: 'ResetPassword.header',
    defaultMessage: 'Set up a new password',
    description: 'ResetPassword form main header',
  },
  description: {
    id: 'ResetPassword.description',
    defaultMessage: 'Set up a new password for Polonium Network',
    description: 'ResetPassword form description',
  },
  newPasswordLabel: {
    id: 'ResetPassword.newPasswordLabel',
    defaultMessage: 'New Password',
    description: 'New Password field label',
  },
  lengthRequirement: {
    id: 'ResetPassword.lengthRequirement',
    defaultMessage: 'Use at least {details}',
    description: 'New Password length requirement',
  },
  lengthDetails: {
    id: 'ResetPassword.lengthDetails',
    defaultMessage: '{minLength} characters',
    description: 'New Password length requirement details',
  },
  charactersRequirement: {
    id: 'ResetPassword.charactersRequirement',
    defaultMessage: 'Use {details}',
    description: 'New Password characters type requirement',
  },
  charactersDetails: {
    id: 'ResetPassword.charactersDetails',
    defaultMessage: 'letters, numbers and symbols',
    description: 'New Password characters type requirement details',
  },
  uniquenessRequirement: {
    id: 'ResetPassword.uniquenessRequirement',
    defaultMessage: 'Use a password {details}',
    description: 'New Password uniqueness requirement',
  },
  uniquenessDetails: {
    id: 'ResetPassword.uniquenessDetails',
    defaultMessage: 'different than the previous one',
    description: 'New Password uniqueness requirement details',
  },
  submitButton: {
    id: 'ResetPassword.submitButton',
    defaultMessage: 'Save',
    description: 'ResetPassword form submit button label',
  },
  successTitle: {
    id: 'ResetPassword.successTitle',
    defaultMessage: 'You have successfully changed your password',
    description: 'ResetPassword success screen title',
  },
  successDescription: {
    id: 'ResetPassword.successDescription',
    defaultMessage: 'Go to the login page to use the most of Polonium Platform.',
    description: 'ResetPassword success screen description',
  },
});
