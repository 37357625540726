import React from 'react';
import classnames from 'classnames';

import styles from './WhiteBox.module.scss';

interface BoxProps {
  landing?: boolean;
  focus?: boolean;
  className?: string;
}

export const WhiteBox: React.FC<BoxProps> = ({ className, landing, focus, children }) => (
  <div
    className={classnames(
      styles.box,
      {
        [styles.landing]: landing,
        [styles.focus]: focus,
      },
      className
    )}
  >
    {children}
  </div>
);
