import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ROUTES } from 'config/routes';
import reportCover from 'assets/images/about-report.jpg';
import { NextButtonLink } from 'components/Button/ButtonLink';
import { RESEARCH_WEBSITE, NETWORK_EMAIL } from 'config/constants';

import { messages } from './messages';
import styles from './DataSection.module.scss';

export const DataSection: React.FC = () => (
  <section className={styles.section}>
    <div className={classnames(styles.container, styles.mobile)}>
      <div>
        <h2 className={styles.title}>
          <FormattedMessage {...messages.dataTitle} />
        </h2>
        <h5 className={styles.subtitle}>
          <FormattedMessage {...messages.dataSubtitle} />
        </h5>
      </div>
    </div>
    <div className={styles.colorBg} />
    <div className={styles.container}>
      <div className={styles.photoContainer}>
        <img
          src={reportCover}
          alt="report cover"
          className={classnames(styles.photo, styles.photo2)}
        />
      </div>
      <div className={styles.halfWidthContainer}>
        <h2 className={classnames(styles.title, styles.desktop)}>
          <FormattedMessage {...messages.dataTitle} />
        </h2>
        <h5 className={classnames(styles.subtitle, styles.desktop)}>
          <a href={RESEARCH_WEBSITE} rel="noreferrer noopener" target="_blank">
            <FormattedMessage {...messages.dataSubtitle} />
          </a>
        </h5>

        <div className={styles.featureContainer}>
          <p className={styles.featureText}>
            <FormattedMessage {...messages.dataFirstFeature} />
          </p>
        </div>
        <div className={styles.featureContainer}>
          <p className={styles.featureText}>
            <FormattedMessage {...messages.dataSecondFeature} />
          </p>
        </div>
        <div className={styles.featureContainer}>
          <p className={styles.featureText}>
            <FormattedMessage {...messages.dataThirdFeature} />
          </p>
        </div>

        <div className={styles.featureContainer}>
          <p className={styles.contactUsText}>
            <FormattedMessage {...messages.contactUs} />
          </p>
          <a href={`mailto:${NETWORK_EMAIL}`} className={styles.contactUsLink}>
            {NETWORK_EMAIL}
          </a>
        </div>
        <NextButtonLink to={ROUTES.SIGNUP} secondary>
          <FormattedMessage {...messages.helpCTA} />
        </NextButtonLink>
      </div>
    </div>
  </section>
);
