import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserPhoto } from 'components/UserPhoto/UserPhoto';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { ReactComponent as RemoveIcon } from 'assets/icons/delete.svg';

import { messages } from './messages';
import styles from './UserAvatar.module.scss';

export interface UserAvatarProps {
  avatarUrl: string | null;
  onRemoveClick: () => void;
  isEditing: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ avatarUrl, onRemoveClick, isEditing }) => (
  <>
    <UserPhoto photo={avatarUrl} />
    {isEditing ? (
      <span className={styles.confirmation}>
        <FormattedMessage {...messages.removeConfirmation} />
      </span>
    ) : (
      <IconButton icon={RemoveIcon} className={styles.button} type="button" onClick={onRemoveClick}>
        <FormattedMessage {...messages.removePhoto} />
      </IconButton>
    )}
  </>
);
