import { useState, useCallback } from 'react';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const open = useCallback(() => setIsVisible(true), []);

  const close = useCallback(() => setIsVisible(false), []);

  return {
    open,
    close,
    modalProps: { isOpen: isVisible, onClose: close },
  };
};
