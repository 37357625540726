import authBackground1 from 'assets/images/2.png';
import authBackground2 from 'assets/images/3.png';
import authBackground3 from 'assets/images/4.png';

export const getRandomBackground = (): string => {
  const backgroundUrls = [authBackground1, authBackground2, authBackground3];
  const randomIndex = Math.floor(Math.random() * backgroundUrls.length);

  return backgroundUrls[randomIndex];
};
