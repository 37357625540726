import React from 'react';
import { commonMessages } from 'intl/commonMessages';
import { FormattedMessage } from 'react-intl';
import PlacesAutocomplete, {
  PropTypes as PlacesAutocompletePropTypes,
  Suggestion,
} from 'react-places-autocomplete';
import { useField, useFormikContext, FormikContextType } from 'formik';
import { TextField, TextFieldProps } from 'components/TextField/TextField';
import { FieldError } from 'components/FieldError/FieldError';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { FormikValueSetter } from 'types';

import styles from './LocationField.module.scss';

const PLACES_SEARCH_OPTIONS = { types: ['(cities)'] };

export interface LocationFieldProps
  extends Omit<PlacesAutocompletePropTypes, 'onChange' | 'onSelect' | 'children'>,
    Omit<TextFieldProps, keyof PlacesAutocompletePropTypes> {
  residenceFieldName: string;
  onChange: (value: string, setFieldValue: FormikValueSetter) => void;
  onSelect: (value: string, setFieldValue: FormikValueSetter) => void;
}

export const LocationField: React.FC<LocationFieldProps> = ({
  name,
  residenceFieldName = 'residence',
  onChange,
  onSelect,
  label,
  placeholder,
}) => {
  const [field] = useField({ name });
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  }: FormikContextType<any> = useFormikContext();

  const isValid = (!errors[residenceFieldName] && touched[residenceFieldName]) as boolean;
  const isInvalid = (!!errors[residenceFieldName] && touched[residenceFieldName]) as boolean;

  return (
    <PlacesAutocomplete
      value={field.value}
      onChange={value => onChange(value, setFieldValue)}
      onSelect={value => onSelect(value, setFieldValue)}
      searchOptions={PLACES_SEARCH_OPTIONS}
    >
      {({ suggestions, getInputProps, getSuggestionItemProps, loading }) => (
        <div className={styles.outerWrapper}>
          <TextField
            {...getInputProps()}
            name={name}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              getInputProps().onBlur(event);
              setFieldTouched(residenceFieldName, true);
            }}
            label={label}
            placeholder={placeholder}
            isValid={isValid}
            isInvalid={isInvalid}
            icon={<LocationIcon className={isInvalid ? styles.iconError : styles.icon} />}
            autoComplete="off"
            classNames={{
              wrapper: styles.wrapper,
              inputWrapper: styles.inputWrapper,
              input: styles.input,
            }}
          />

          {(suggestions.length > 0 || loading) && (
            <ul className={styles.menu}>
              {loading ? (
                <div className={styles.loading}>
                  <FormattedMessage {...commonMessages.loading} />
                </div>
              ) : (
                suggestions.map((suggestion: Suggestion) => {
                  const className = suggestion.active ? styles.itemActive : styles.item;

                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })
              )}
              <div className={styles.mapsLogo} />
            </ul>
          )}
          {errors[residenceFieldName] && touched[residenceFieldName] && (
            <FieldError name={residenceFieldName} />
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};
