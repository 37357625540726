import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageName: {
    id: 'JobOfferPage.pageName',
    defaultMessage: 'Job Offer',
    description: 'Job Offer Page page name',
  },
  descriptionHeader: {
    id: 'JobOfferPage.descriptionHeader',
    defaultMessage: 'Position description',
    description: 'Job Offer description header content',
  },
  howToApply: {
    id: 'JobOfferPage.howToApply',
    defaultMessage: 'How to apply',
    description: 'Job Offer how to apply header content',
  },
  howToApplyContent: {
    id: 'JobOfferPage.howToApplyContent',
    defaultMessage:
      'If you are interested in applying to this position, send an email to the address given in the offer. Remember to format the subject line to include the job title you are applying for and reference to Polonium Network. ',
    description: 'Job Offer how to apply card content',
  },
  questionsContent: {
    id: 'JobOfferPage.questionsContent',
    defaultMessage: 'In case of any questions, please contact us: {email}',
    description: 'Job Offer how to apply questions area content',
  },
  scrollToTopMessage: {
    id: 'JobOfferPage.scrollToTopMessage',
    defaultMessage: 'Scroll to the top',
    description: 'Job Offer scroll to top content',
  },
  backToJobOffersMessage: {
    id: 'JobOfferPage.backToJobOffersMessage',
    defaultMessage: 'Back to job offers',
    description: 'Job Offer back to job offers content',
  },
  summary: {
    id: 'JobOfferPage.summary',
    defaultMessage: 'Offer summary',
    description: 'Job Offer sumary card title content',
  },
});
