import React from 'react';
import { StorybookFormikWrapper } from 'components/storybook/StorybookFormikWrapper/StorybookFormikWrapper';
import { object, mixed } from 'yup';
import { autocompleteBasicOptions } from 'mocks';
import { validators } from 'config/errors/validators';

import { Autocomplete } from './Autocomplete';
import { AutocompleteField } from './AutocompleteField';

const filterOptions = (inputValue: string) => {
  return autocompleteBasicOptions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = (inputValue: string) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterOptions(inputValue));
    }, 1000);
  });

export const AutocompleteComponent = () => <Autocomplete loadOptions={promiseOptions} />;

const initialValues = {
  position: '',
};

const validationSchema = object().shape({
  position: mixed().required(),
});

export const AutocompleteWithValidation = () => (
  <StorybookFormikWrapper initialValues={initialValues} validationSchema={validationSchema}>
    {values => (
      <AutocompleteField name="position" value={values.position} loadOptions={promiseOptions} />
    )}
  </StorybookFormikWrapper>
);

const multiSelectInitialValues = {
  institutions: [],
};
const multiValueValidationSchema = object().shape({
  institutions: validators.profileInstitutions,
});

export const MultiSelectCreatableAutocomplete = () => (
  <StorybookFormikWrapper
    initialValues={multiSelectInitialValues}
    validationSchema={multiValueValidationSchema}
  >
    {() => <AutocompleteField name="institutions" loadOptions={promiseOptions} isMulti={true} />}
  </StorybookFormikWrapper>
);

export default {
  title: 'AutocompleteComponent',
  component: AutocompleteComponent,
};
