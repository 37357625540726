import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ForgotPassword.pageTitle',
    defaultMessage: 'Forgot password?',
    description: 'ForgotPassword page title',
  },
  header: {
    id: 'ForgotPassword.header',
    defaultMessage: 'Forgot your password?',
    description: 'ForgotPassword form main header',
  },
  description: {
    id: 'ForgotPassword.description',
    defaultMessage:
      'We know you are busy with more important future changing questions and data. Write down your email and we will send you a  link to set up a new password.',
    description: 'ForgotPassword form description',
  },
  submitButton: {
    id: 'ForgotPassword.submitButton',
    defaultMessage: 'Send me a link',
    description: 'ForgotPassword form submit button label',
  },
});
