import { User } from 'api/AuthClient';
import { AuthHeaders, StoredAuthHeaders } from 'modules/auth/models';

export const AUTH_HEADERS = {
  UID: 'uid',
  CLIENT: 'client',
  TOKEN: 'access-token',
};

export const AUTH_KEYS = {
  ...AUTH_HEADERS,
  TOKEN: 'accessToken',
};

export const KEYS = {
  ...AUTH_KEYS,
  USER_DATA: 'user',
};

export interface AuthCookies extends Omit<StoredAuthHeaders, 'expiry'> {}

export class StorageManager {
  constructor(private localStorage: Storage = window.localStorage) {}

  get hasAuthData() {
    return !!Object.keys(this.getAuthHeaders()).length;
  }

  get user(): User | null {
    const user = this.localStorage.getItem(KEYS.USER_DATA);
    if (!user) return null;

    try {
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  }

  getAuthHeaders = (): AuthHeaders | {} => {
    const uid = this.localStorage.getItem(AUTH_KEYS.UID);
    const token = this.localStorage.getItem(AUTH_KEYS.TOKEN);
    const client = this.localStorage.getItem(AUTH_KEYS.CLIENT);

    if (!uid || !token || !client) return {};

    return {
      [AUTH_HEADERS.UID]: uid,
      [AUTH_HEADERS.TOKEN]: token,
      [AUTH_HEADERS.CLIENT]: client,
    };
  };

  saveAuthHeaders = ({ client, uid, accessToken }: StoredAuthHeaders): void => {
    this.localStorage.setItem(AUTH_KEYS.UID, uid);
    this.localStorage.setItem(AUTH_KEYS.TOKEN, accessToken);
    this.localStorage.setItem(AUTH_KEYS.CLIENT, client);
  };

  saveUser = (user: User): void => {
    this.localStorage.setItem(KEYS.USER_DATA, JSON.stringify(user));
  };

  clearAll = () => {
    Object.values(KEYS).forEach(key => {
      this.localStorage.removeItem(key);
    });
  };
}
