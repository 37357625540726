import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApiErrorHandler } from 'helpers';
import { useApi } from 'api/ApiContext';
import { ROUTES } from 'config/routes';
import { DeserializedUserProfile } from 'types';
import { UserPhoto } from 'components/UserPhoto/UserPhoto';
import { Spinner } from 'components/Spinner/Spinner';

import {
  ExpertiseBox,
  GrantsPreviewBox,
  EducationPreviewBox,
  SocialMediaPreviewBox,
  UserDetails,
  StatusPreviewBox,
  AboutPreviewBox,
  InstitutionsPreviewBox,
} from '../../components/';
import styles from '../Profile.module.scss';
import { messages } from '../../messages';

export const Profile: React.FC = () => {
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { userId } = useParams();

  const [userData, setUserData] = useState<DeserializedUserProfile | null>(null);
  const [isFetchingProfile, setFetchingProfile] = useState<boolean>(true);

  const getUserData = useCallback(async () => {
    setFetchingProfile(true);
    try {
      const { data } = await profile.getUser(userId!);
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setFetchingProfile(false);
    }
  }, [profile, handleError, userId]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  if (isFetchingProfile) {
    return <Spinner isLarge={true} className={styles.spinnerContainer} />;
  }

  if (!userData) return <Redirect to={ROUTES.COMMUNITY} />;

  const {
    firstName,
    lastName,
    about,
    disciplines,
    currentResidence,
    affiliations,
    institutions,
    avatarUrl,
    socialMedia,
    educations,
    currentStatus,
    grants,
    id,
  } = userData;

  const { statement, isVisible } = currentStatus.data.attributes;

  const isStatusVisible = statement && isVisible;

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <section
        className={classnames(styles.headerwithoutEdit, {
          [styles.statusHidden]: !isStatusVisible,
        })}
        data-testid="header"
      >
        <div className={styles.headerContainer}>
          <UserPhoto photo={avatarUrl} className={styles.photo} />

          <h1 className={styles.name}>
            {firstName} {lastName}
          </h1>

          <div className={styles.details}>
            {affiliations && (
              <UserDetails affiliations={affiliations} residence={currentResidence} />
            )}
          </div>
        </div>
        <Link to={`${ROUTES.MESSAGES}?newContact=${id}`} className={styles.contactButton}>
          <FormattedMessage {...messages.contactButton} />
        </Link>
      </section>
      <section className={styles.main} data-testid="main">
        <div className={styles.statusContainer}>
          {isVisible && statement && <StatusPreviewBox status={currentStatus.data.attributes} />}
        </div>
        <div className={styles.container}>
          <div className={styles.leftSide}>
            {about && <AboutPreviewBox about={about} />}

            {disciplines.data.length > 0 && <ExpertiseBox expertise={disciplines.data} />}
            {institutions.data.length > 0 && (
              <InstitutionsPreviewBox institutions={institutions.data} />
            )}
            {educations.data.length > 0 && <EducationPreviewBox educations={educations.data} />}
            {grants.data.length > 0 && <GrantsPreviewBox grants={grants.data} />}
          </div>

          {socialMedia.data.length > 0 && <SocialMediaPreviewBox socialMedia={socialMedia.data} />}
        </div>
      </section>
    </>
  );
};
