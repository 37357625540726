import React from 'react';
import { Switch, Redirect, RouteProps } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { useSearchParams } from 'helpers';

import { DisciplineSetup } from './pages/DisciplineSetup/DisciplineSetup';
import { AffiliationSetup } from './pages/AffiliationSetup/AffiliationSetup';
import { OnboardingRoute } from './components/OnboardingRoute/OnboardingRoute';

export const OnboardingRoutes: React.FC<RouteProps> = () => {
  const [accountConfirmationSuccess] = useSearchParams(['account_confirmation_success']);

  return (
    <Switch>
      <OnboardingRoute exact path={ROUTES.DISCIPLINE} component={DisciplineSetup} />
      <OnboardingRoute exact path={ROUTES.AFFILIATION} component={AffiliationSetup} />
      <Redirect to={accountConfirmationSuccess ? ROUTES.LOGIN : ROUTES.HOME} />
    </Switch>
  );
};
