import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dataTitle: {
    id: 'BecomePartner.dataTitle',
    defaultMessage: 'Take a Look into some data',
    description: 'Data section title',
  },
  statsFirstTitle: {
    id: 'BecomePartner.statsFirstTitle',
    defaultMessage: 'Researchers in Poland',
    description: 'Data first stats title',
  },
  statsFirst: {
    id: 'BecomePartner.statsFirst',
    defaultMessage: '41020',
    description: 'Data first stat',
  },
  statsSecondTitle: {
    id: 'BecomePartner.statsSecondTitle',
    defaultMessage: 'Researchers abroad',
    description: 'Data second stats title',
  },
  statsSecond: {
    id: 'BecomePartner.statsSecond',
    defaultMessage: '11020',
    description: 'Data second stats',
  },
  statsThirdTitle: {
    id: 'BecomePartner.statsThirdTitle',
    defaultMessage: 'Looking for an opportunity',
    description: 'Data third stats title',
  },
  statsThird: {
    id: 'BecomePartner.statsThird',
    defaultMessage: '48%',
    description: 'Data third stats',
  },
  statsFourthTitle: {
    id: 'BecomePartner.statsFourthTitle',
    defaultMessage: 'Registered users',
    description: 'Data fourth stats title',
  },
  statsFourth: {
    id: 'BecomePartner.statsFourth',
    defaultMessage: '1020',
    description: 'Data fourth stats',
  },
  paragraph1: {
    id: 'BecomePartner.paragraph1',
    defaultMessage: 'Short text explaining where this data comes from to give it more credibility.',
    description: 'Data short text pt 1',
  },
  paragraph2: {
    id: 'BecomePartner.paragraph2',
    defaultMessage:
      'We strengthen your chances of reaching the right people significantly. You don’t have to trust our word, we have the data. ',
    description: 'Data short text pt 2',
  },
  paragraph3: {
    id: 'BecomePartner.paragraph3',
    defaultMessage:
      'We strengthen your chances of reaching the right people significantly. You don’t have to trust our word, we have the data. ',
    description: 'Data short text pt 3',
  },
  paragraph4: {
    id: 'BecomePartner.paragraph4',
    defaultMessage: 'Contact us directly',
    description: 'Data short text pt 4',
  },
});
