import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../../../../components/Button/Button';
import { TextField } from '../../../../../components/TextField/TextField';
import { messages } from '../messages';

import styles from './SendMessage.module.scss';

export interface SendMessageProps {
  value: string;
  onChange: (value: string) => void;
  send: () => void;
}

export const SendMessage: React.FC<SendMessageProps> = ({ value, onChange, send }) => {
  const { formatMessage } = useIntl();

  return (
    <Formik initialValues={{ message: undefined }} onSubmit={send}>
      <Form className={styles.formWrapper} noValidate>
        <TextField
          value={value}
          onChange={e => onChange(e.target.value)}
          name="message"
          label={formatMessage(messages.messageFieldLabel)}
        />
        <Button className={styles.sendButton} type="submit" small>
          <FormattedMessage {...messages.sendMessageButtonLabel} />
        </Button>
      </Form>
    </Formik>
  );
};
