import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { object } from 'yup';
import { validators } from 'config/errors/validators';
import { TextField } from 'components/TextField/TextField';
import { commonMessages } from 'intl/commonMessages';
import { SaveButton, CancelButton } from 'components/Button/Button';
import { useAuthContext } from 'modules/auth/AuthContext';
import { EditButton } from 'modules/profiles/components';

import styles from '../../ProfileSettings.module.scss';

interface EmailFormProps {
  email: string;
}

const validationSchema = object().shape({
  email: validators.email,
});

export const EmailForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const handleError = useApiErrorHandler();
  const { profile } = useApi();

  const [isEdited, setEdited] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(user!.email);

  const initialValues = { email };

  const handleSubmit = async ({ email }: EmailFormProps) => {
    try {
      await profile.updateEmail(email);
      setEmail(email);
      setIsSubmitted(true);
    } catch (error) {
      handleError(error);
    }
  };

  const onEdit = () => {
    setEdited(true);
  };

  const cancel = () => {
    setEdited(false);
  };

  return (
    <div className={styles.inputsContainer}>
      <EditButton onClick={onEdit} disabled={isEdited} className={styles.editButton} />
      {isEdited || isSubmitted ? (
        <Formik<EmailFormProps>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <TextField
                name="email"
                type="email"
                label={formatMessage(commonMessages.emailLabel)}
                placeholder={formatMessage(commonMessages.emailPlaceholder)}
                disabled={isSubmitted}
              />
              {isSubmitted ? (
                <p className={styles.changeEmailInfo}>
                  <FormattedMessage
                    {...commonMessages.changeEmailInfo}
                    values={{ email: <span className={styles.email}>{email}</span> }}
                  />
                </p>
              ) : (
                <div className={styles.buttonContainer}>
                  <CancelButton
                    onClick={cancel}
                    className={styles.button}
                    disabled={isSubmitting}
                  />
                  <SaveButton
                    isProcessing={isSubmitting}
                    disabled={isSubmitting}
                    className={styles.button}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <p className={styles.label}>
          <FormattedMessage {...commonMessages.emailLabel} />
          <span className={styles.input}>{email}</span>
        </p>
      )}
    </div>
  );
};
