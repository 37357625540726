import React, { useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeserializedUserProfile } from 'types';
import { useApiErrorHandler, useModal } from 'helpers';
import { UserPhoto } from 'components/UserPhoto/UserPhoto';
import { Spinner } from 'components/Spinner/Spinner';
import { useApi } from 'api/ApiContext';
import { DisciplineForm } from 'modules/onboarding/pages/DisciplineSetup/DisciplineForm';
import { useNotification } from 'modules/notifications/NotificationContext';
import { Modal } from 'components/Modal/Modal';
import { Loading } from 'components/Loading/Loading';

import {
  AboutBox,
  EditButton,
  InstitutionsBox,
  ExpertiseBox,
  GrantBox,
  EducationBox,
  ProfileHeaderForm,
  SocialMediaBox,
  StatusBox,
  UserDetails,
} from '../../components/';
import styles from '../Profile.module.scss';
import { messages } from '../../messages';

import { useProfile } from './ProfileContext';

export const MyProfile: React.FC = () => {
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { createNotification } = useNotification();
  const { userData, setUserData } = useProfile();

  const [isFetchingProfile, setFetchingProfile] = useState<boolean>(true);
  const [isFetchingsStatusOptions, setFetchingStatusOptions] = useState<boolean>(true);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);

  const {
    open: openAffiliationModal,
    close: closeAffiliationModal,
    modalProps: affiliationModalProps,
  } = useModal();

  const {
    open: openExpertiseModal,
    close: closeExpertiseModal,
    modalProps: expertiseModalProps,
  } = useModal();

  const getUserData = useCallback(async () => {
    setFetchingProfile(true);
    try {
      const { data } = await profile.getCurrentUser();
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setFetchingProfile(false);
    }
  }, [profile, setUserData, handleError]);

  const getStatusOptions = useCallback(async () => {
    setFetchingStatusOptions(true);
    try {
      const { data } = await profile.getStatusOptions();
      setStatusOptions(data);
    } catch (error) {
      handleError(error);
    } finally {
      setFetchingStatusOptions(false);
    }
  }, [profile, handleError]);

  const handleExpertisesUpdate = useCallback(() => {
    closeExpertiseModal();
    createNotification({
      type: 'success',
      message: <FormattedMessage {...messages.expertiseUpdated} />,
    });
    getUserData();
  }, [closeExpertiseModal, createNotification, getUserData]);

  const handleHeaderUpdate = useCallback(
    (updatedProfile: DeserializedUserProfile) => {
      setUserData(updatedProfile);
      createNotification({
        type: 'success',
        message: <FormattedMessage {...messages.headerUpdated} />,
      });
      closeAffiliationModal();
    },
    [setUserData, closeAffiliationModal, createNotification]
  );

  useEffect(() => {
    getUserData();
    getStatusOptions();
  }, [getUserData, getStatusOptions]);

  if (isFetchingProfile) {
    return <Spinner isLarge={true} className={styles.spinnerContainer} />;
  }

  if (!userData) return null;

  const {
    firstName,
    lastName,
    about,
    disciplines,
    currentResidence,
    currentStatus,
    affiliations,
    institutions,
    avatarUrl,
    socialMedia,
    educations,
    grants,
  } = userData;

  const { location: residence, city, coordinates } = currentResidence;

  const affiliationFormValues = {
    residence,
    city,
    coordinates,
    affiliations,
  };

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <section className={styles.header} data-testid="header">
        <div className={styles.headerContainer}>
          <UserPhoto photo={avatarUrl} className={styles.photo} />

          <h1 className={styles.name}>
            {firstName} {lastName}
          </h1>

          <div className={styles.details}>
            <UserDetails affiliations={affiliations} residence={currentResidence} />
          </div>
        </div>
        <EditButton onClick={openAffiliationModal} className={styles.editHeader} />
      </section>
      <section className={styles.main} data-testid="main">
        <div className={styles.statusContainer}>
          <Loading isLoading={isFetchingsStatusOptions}>
            <StatusBox status={currentStatus.data.attributes} statusOptions={statusOptions} />
          </Loading>
        </div>
        <div className={styles.container}>
          <div className={styles.leftSide}>
            <AboutBox about={about} />

            <ExpertiseBox expertise={disciplines.data} onEditClick={openExpertiseModal} />
            <InstitutionsBox institutions={institutions.data} />
            <EducationBox educations={educations.data} />

            <GrantBox grants={grants.data} />
          </div>
          <SocialMediaBox socialMedia={socialMedia.data} />
        </div>
      </section>

      <Modal
        header={<FormattedMessage {...messages.updateExpertises} />}
        {...expertiseModalProps}
        isLarge
      >
        <h2 className={styles.modalHeader}>
          <FormattedMessage {...messages.updateExpertisesTitle} />
        </h2>
        <DisciplineForm onSubmit={handleExpertisesUpdate} />
      </Modal>

      <Modal
        header={<FormattedMessage {...messages.updateHeader} />}
        {...affiliationModalProps}
        isLarge
      >
        <ProfileHeaderForm
          userName={`${firstName} ${lastName}`}
          initialValues={affiliationFormValues}
          onCancel={closeAffiliationModal}
          onSubmit={handleHeaderUpdate}
        />
      </Modal>
    </>
  );
};
