import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { useAuthContext } from 'modules/auth/AuthContext';
import { RouteProps } from 'types';

export const OnboardingRoute: React.FC<RouteProps> = ({ component: Component, ...routeProps }) => {
  const { isAuthorized, isOnboarded } = useAuthContext();

  return (
    <Route
      {...routeProps}
      render={props =>
        isAuthorized && !isOnboarded ? <Component {...props} /> : <Redirect to={ROUTES.HOME} />
      }
    />
  );
};
