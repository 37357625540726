import React from 'react';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Arrow } from 'assets/icons/arrow-head.svg';

import styles from './Pagination.module.scss';

interface ListMeta {
  pageCount: number;
  currentPage: number;
}
interface PaginationProps {
  listMeta: ListMeta;
  onPageChange: (selectedItem: { selected: number }) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  listMeta: { pageCount, currentPage },
  onPageChange,
}) => {
  if (pageCount <= 1) return <></>;

  return (
    <ReactPaginate
      previousLabel={<Arrow className={styles.arrow} />}
      nextLabel={<Arrow className={styles.arrow} />}
      pageCount={pageCount}
      onPageChange={onPageChange}
      forcePage={currentPage}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      containerClassName={styles.pagination}
      activeClassName={styles.pageActive}
      activeLinkClassName={styles.linkActive}
      pageClassName={styles.page}
      pageLinkClassName={styles.link}
      previousClassName={styles.pagePrev}
      nextClassName={styles.pageNext}
      previousLinkClassName={styles.linkPrev}
      nextLinkClassName={styles.linkNext}
      breakClassName={styles.pageBrake}
      breakLinkClassName={styles.linkBrake}
    />
  );
};
