import React from 'react';

import styles from './PrivacyPolicy.module.scss';
import { PrivacyPolicyStatements } from './PrivacyPolicyStatements';

export const PrivacyPolicy: React.FC = () => {
  return (
    <section className={styles.pageWrapper}>
      <h1 className={styles.title}>Privacy Policy</h1>
      <p className={styles.paragraph}>
        This privacy policy ("Policy") describes how Polonium Foundation I (Fundacja Polonium), Pl.
        Bankowy 2, 00-095 Warszawa ("Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa", "we", "us"
        or "our") collects, protects and uses the personally identifiable information ("Personal
        Information") you ("User", "you" or "your") may provide on the{' '}
        <a href="https://poloniumnetwork.org">poloniumnetwork.org</a> website and any of its
        products or services (collectively, "Website" or "Services").
      </p>
      <p className={styles.paragraph}>
        It also describes the choices available to you regarding our use of your Personal
        Information and how you can access and update this information. This Policy does not apply
        to the practices of companies that we do not own or control, or to individuals that we do
        not employ or manage.
      </p>
      <p className={styles.paragraph}>
        The administrator of your personal data is Polonium Foundation I (Fundacja Polonium), Pl.
        Bankowy 2, 00-095 Warszawa, KRS 0000621890, NIP 7010584408, Regon 364666620.
      </p>
      {PrivacyPolicyStatements.map((statement, idx) => {
        return (
          <div key={idx}>
            <h2 className={styles.statementTitle}>{statement.title}</h2>
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: statement.content }}
            />
          </div>
        );
      })}
    </section>
  );
};
