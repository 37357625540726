import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  viewProfileLink: {
    id: 'ProfileCard.viewProfileLink',
    defaultMessage: 'View profile',
    description: 'View profile link content',
  },
  currentResidenceLabel: {
    id: 'ProfileCard.currentResidenceLabel',
    defaultMessage: 'Current residence: ',
    description: 'Label for current residence',
  },
  institutionInfo: {
    id: 'ProfileCard.institutionInfo',
    defaultMessage: 'at {institutionName}',
    description: 'Information about affiliations institution',
  },
});
