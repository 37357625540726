import { AxiosResponse } from 'axios';
import { Discipline } from 'types';

import { ResourceClient } from './ResourceClient';

interface NewAccount {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  yearOfBirth: string;
  birthPlaceAttributes: object;
  termsAccepted: boolean;
  newsletterAccepted?: boolean;
}

export interface User {
  id: number;
  provider: string;
  uid: string;
  allowPasswordChange: boolean;
  image?: string;
  email: string;
  firstName: string;
  lastName: string;
  termsAccepted: boolean;
  newsletterAccepted: boolean;
  disciplinesCompleted: boolean;
  affiliationsAndResidenceCompleted: boolean;
  disciplines: {
    data: Discipline[];
  };
}

export interface UserData {
  data: {
    attributes: User;
  };
}

export interface NewUser extends User {
  createdAt: string;
  updatedAt: string;
}

export interface UserCredentials {
  email: string;
  password: string;
}

type UpdatePasswordParams = {
  password: string;
  passwordConfirmation: string;
  headers: {
    accessToken: string | null;
    uid: string | null;
    client: string | null;
  };
};

export class AuthClient extends ResourceClient {
  signUp(newAccount: NewAccount): Promise<AxiosResponse<NewUser>> {
    return this.client.post<NewUser>('/auth', newAccount);
  }

  signIn(userCredentials: UserCredentials): Promise<AxiosResponse> {
    return this.client.post('/auth/sign_in', userCredentials);
  }

  signOut(): Promise<AxiosResponse> {
    return this.client.delete('/auth/sign_out');
  }

  resendConfirmation(email: string): Promise<AxiosResponse<User>> {
    return this.client.get<User>('/auth/confirmation/new', { params: { email } });
  }

  forgotPassword(email: string): Promise<AxiosResponse<User>> {
    return this.client.post<User>('/auth/password', { email });
  }

  updatePassword({
    headers,
    ...passwordParams
  }: UpdatePasswordParams): Promise<AxiosResponse<{ data: User }>> {
    return this.client.put<{ data: User }>('/auth/password', passwordParams, {
      headers: { ...headers, 'access-token': headers.accessToken },
    });
  }
}
