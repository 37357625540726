import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { object, array } from 'yup';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import { validators } from 'config/errors/validators';
import { useApi } from 'api/ApiContext';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { useApiErrorHandler, capitalizeFirstLetter } from 'helpers';
import { SocialMedia, SocialMediaAttributes } from 'types';
import { OptionType } from 'components/Autocomplete/types';
import { SaveButton, CancelButton } from 'components/Button/Button';

import { SocialTag, ProfileBox, AddButton } from '../';
import { messages } from '../../messages';

import { SocialMediumField, NewSocialMediumField } from './SocialMediumField';
import styles from './SocialMediaBox.module.scss';

const emptyMedium = {
  platform: '',
  url: '',
};

const validationSchema = object().shape({
  media: array().of(
    object().shape({
      platform: validators.socialMediaPlatform,
      url: validators.socialMediaUrl,
    })
  ),
});

const getInitialFormValues = (socialMedia: SocialMedia[]): SocialForm => ({
  media: socialMedia.map(({ attributes: { platform, url } }) => ({
    platform: {
      label: capitalizeFirstLetter(platform),
      value: capitalizeFirstLetter(platform),
    },
    url,
  })),
});

export interface SocialField {
  platform: OptionType;
  url: string;
}

export interface SocialForm {
  media: SocialField[];
}

interface SocialMediaBoxProps {
  socialMedia: SocialMedia[];
}

export const SocialMediaBox: React.FC<SocialMediaBoxProps> = ({ socialMedia }) => {
  const [platforms, setPlatforms] = useState<OptionType[]>([]);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isAddingMedia, setAddingMedia] = useState<boolean>(false);
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  useEffect(() => {
    const getSocialMediaPlatforms = async () => {
      try {
        const { data } = await profile.getSocialMediaPlatforms();
        setPlatforms(data.map(value => ({ label: value, value })));
      } catch (error) {
        handleError(error);
      }
    };

    getSocialMediaPlatforms();
  }, [handleError, profile]);

  const edit = () => setEditing(true);

  const handleSubmit = async (
    { media }: SocialForm,
    { setSubmitting }: FormikHelpers<SocialForm>
  ) => {
    const socialMediaAttributes = media.map(({ platform, url }) => ({
      platform: platform.value.toLowerCase(),
      url,
    }));

    try {
      const { data } = await profile.updateCurrentUser({ socialMediaAttributes });
      setUserData(data);

      setEditing(false);
      setAddingMedia(false);
      setSubmitting(false);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  return (
    <ProfileBox
      title={<FormattedMessage {...messages.socialMediaTitle} />}
      onEdit={socialMedia.length ? edit : undefined}
      isEdited={isEditing || isAddingMedia}
      className={styles.socialBox}
    >
      <Formik<SocialForm>
        initialValues={getInitialFormValues(socialMedia)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form className={styles.form}>
            <FieldArray name="media">
              {({ push, remove, form }) => {
                const mediaFields: SocialMediaAttributes[] = form.values.media;
                const lastIndex = mediaFields.length - 1;

                const addMedium = () => {
                  setAddingMedia(true);
                  push(emptyMedium);
                };

                const removeMedium = (index: number) => {
                  remove(index);
                };

                const cancel = () => {
                  setEditing(false);
                  setAddingMedia(false);
                  resetForm();
                };

                return (
                  <>
                    {isEditing ? (
                      mediaFields.map((_media, index) => (
                        <SocialMediumField
                          key={`media-${index}`}
                          index={index}
                          onRemove={removeMedium}
                        />
                      ))
                    ) : (
                      <>
                        <SocialMediaPreview socialMedia={socialMedia} />
                        {isAddingMedia && mediaFields.length ? (
                          <NewSocialMediumField index={lastIndex} platforms={platforms} />
                        ) : (
                          <AddButton onClick={addMedium} />
                        )}
                      </>
                    )}
                    {(isAddingMedia || isEditing) && (
                      <div className={styles.buttons}>
                        <CancelButton
                          onClick={cancel}
                          small={true}
                          className={styles.cancel}
                          disabled={isSubmitting}
                        />
                        <SaveButton
                          small={true}
                          className={styles.submit}
                          isProcessing={isSubmitting}
                          disabled={isSubmitting}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </ProfileBox>
  );
};

export const SocialMediaPreview: React.FC<SocialMediaBoxProps> = ({ socialMedia }) => (
  <>
    {socialMedia.map(({ attributes: { platform, url } }) => (
      <SocialTag key={platform} type={capitalizeFirstLetter(platform)} link={url} />
    ))}
  </>
);

export const SocialMediaPreviewBox: React.FC<SocialMediaBoxProps> = ({ socialMedia }) => (
  <ProfileBox
    title={<FormattedMessage {...messages.socialMediaTitle} />}
    className={styles.socialBox}
  >
    <SocialMediaPreview socialMedia={socialMedia} />
  </ProfileBox>
);
