import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useApiErrorHandler } from 'helpers';
import { useApi } from 'api/ApiContext';
import { Spinner } from 'components/Spinner/Spinner';

import { ROUTES } from '../../../../config/routes';
import { useProfile } from '../MyProfile/ProfileContext';
import { DeserializedUserProfile } from '../../../../types';

import styles from './Communicator.module.scss';
import { Contact } from './components/Contact';
import { Messages } from './components/Messages';
import { SendMessage } from './components/SendMessage';
import { MessageProps } from './components/Message';

export interface ContactsData {
  [id: string]: {
    name: string;
    unread: number;
    avatarUrl?: string | null;
    messages: Array<MessageProps>;
    muted: boolean;
  };
}

export const Communicator: React.FC = () => {
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { userData, setUserData } = useProfile();
  const history = useHistory();
  const [message, setMessage] = useState<string>('');
  const [contacts, setContacts] = useState<ContactsData>({});
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [isFetchingCommunicatorData, setFetchingCommunicatorData] = useState<boolean>(true);

  const getNewContactIdFromQueryParam = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get('newContact');
  };

  const fetchAndAddNewContactDataToContacts = useCallback(
    async (newContactId: string, contacts: ContactsData) => {
      const { data: newContact } = await profile.getUser(newContactId);
      if (newContact.id && newContact.firstName && newContact.lastName) {
        contacts[newContact.id] = {
          name: `${newContact.firstName} ${newContact.lastName}`,
          unread: 0,
          avatarUrl: newContact.avatarUrl,
          messages: [],
          muted: false,
        };
      }
    },
    [profile]
  );

  const setNewState = useCallback(
    (
      newSelectedContact: string,
      newContacts: ContactsData,
      newUserData: DeserializedUserProfile
    ) => {
      if (newSelectedContact !== '') {
        setSelectedContact(newSelectedContact);
        history.push(ROUTES.MESSAGES);
      }
      if (newContacts) {
        setContacts(newContacts);
      }
      setUserData(newUserData);
    },
    [history, setUserData]
  );

  const prepareContacts = useCallback(
    async (newContacts: ContactsData, newUserData: DeserializedUserProfile) => {
      let newSelectedContact = `${Object.keys(newContacts)[0]}`;
      const newContactId = getNewContactIdFromQueryParam();
      if (newContactId && newContactId !== `${newUserData.id}`) {
        if (!newContacts[newContactId]) {
          await fetchAndAddNewContactDataToContacts(newContactId, newContacts);
        }
        newSelectedContact = newContactId;
      }
      await profile.markMessagesAsRead(newUserData.id, newSelectedContact);
      setNewState(newSelectedContact, newContacts, newUserData);
    },
    [profile, fetchAndAddNewContactDataToContacts, setNewState]
  );

  const getCommunicatorData = useCallback(async () => {
    if (isFetchingCommunicatorData) {
      try {
        const { data: newUserData } = await profile.getCurrentUser();
        if (newUserData) {
          const { data } = await profile.getMessages();
          const newContacts: ContactsData = data.contacts;
          await prepareContacts(newContacts, newUserData);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setFetchingCommunicatorData(false);
      }
    }
  }, [handleError, profile, isFetchingCommunicatorData, prepareContacts]);

  useEffect(() => {
    getCommunicatorData();
  }, [getCommunicatorData]);

  const onSendMessage = async () => {
    const { data } = await profile.sendMessage(
      message,
      userData ? userData.id : '0',
      selectedContact
    );
    if (data.sent) {
      setContacts({
        ...contacts,
        [selectedContact]: {
          name: contacts[selectedContact].name,
          unread: contacts[selectedContact].unread,
          avatarUrl: contacts[selectedContact].avatarUrl,
          messages: [
            { text: message, isSender: true, date: new Date().getTime() / 1000 },
            ...contacts[selectedContact].messages,
          ],
          muted: contacts[selectedContact].muted,
        },
      });
    } else {
      handleError({ response: { data } });
    }
    setMessage('');
  };

  const onDeleteAllMessages = async () => {
    if (userData) {
      const { data } = await profile.deleteMessages(`${userData.id}`, selectedContact);
      if (data.deleted) {
        const newContacts = { ...contacts };
        delete newContacts[selectedContact];
        setSelectedContact(`${Object.keys(newContacts)[0]}`);
        setContacts(newContacts);
        setMessage('');
      }
    }
  };

  const onMuteConversation = async () => {
    if (userData) {
      const { data } = await profile.blockUser(selectedContact);
      if (data.blocked) {
        setContacts({
          ...contacts,
          [selectedContact]: { ...contacts[selectedContact], muted: true },
        });
      }
    }
  };

  const onUnmuteConversation = async () => {
    if (userData) {
      const { data } = await profile.unblockUser(selectedContact);
      if (data.unblocked) {
        setContacts({
          ...contacts,
          [selectedContact]: { ...contacts[selectedContact], muted: false },
        });
      }
    }
  };

  const contactClicked = (id: string) => {
    if (userData) {
      profile.markMessagesAsRead(id, selectedContact);
    }
    setSelectedContact(id);
  };

  if (isFetchingCommunicatorData) {
    return <Spinner isLarge={true} className={styles.spinnerContainer} />;
  }

  if (selectedContact === 'undefined') {
    handleError({ response: { data: { errors: ['messageToYourself'] } } });
    return <Redirect to={ROUTES.COMMUNITY} />;
  }

  const sortedContacts = function() {
    return Object.entries(contacts).sort(function(a, b) {
      return b[1].unread - a[1].unread;
    });
  };

  return (
    <section className={styles.pageWrapper}>
      <div className={styles.leftContainer}>
        {sortedContacts().map(([id, contact]) => (
          <Contact
            key={`${contact.name} + ${id}`}
            unread={contact.unread}
            name={contact.name}
            avatarUrl={contact.avatarUrl}
            lastMessage={contact.messages[0] && contact.messages[0].text}
            onClick={() => contactClicked(id)}
          />
        ))}
      </div>
      <div className={styles.rightContainer}>
        <Messages
          deleteConversation={onDeleteAllMessages}
          muteConversation={onMuteConversation}
          unmuteConversation={onUnmuteConversation}
          receiver={contacts[selectedContact].name}
          messages={contacts[selectedContact].messages}
          muted={contacts[selectedContact].muted}
        />
        <SendMessage
          value={message}
          onChange={newValue => setMessage(newValue)}
          send={onSendMessage}
        />
      </div>
    </section>
  );
};
