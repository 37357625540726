import { Tag } from 'components/Tag/Tag';
import { Discipline } from 'types';
import React from 'react';

import styles from './DisciplinesContainer.module.scss';

export const DisciplinesContainer: React.FC<{ data: Discipline[] }> = ({
  data: disciplinesCollection,
}) => {
  const firstThreeDisciplines = disciplinesCollection.slice(0, 3);
  const remainingDisciplinesLength = disciplinesCollection.slice(3, disciplinesCollection.length)
    .length;

  return (
    <div className={styles.disciplines}>
      {firstThreeDisciplines.map(({ attributes: { id, name } }) => (
        <Tag key={id}>{name}</Tag>
      ))}
      {remainingDisciplinesLength > 0 && <Tag>+{remainingDisciplinesLength}</Tag>}
    </div>
  );
};
