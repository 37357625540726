import React from 'react';
import { DeserializedEntity } from 'types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Tag } from 'components/Tag/Tag';

import styles from './SelectedItem.module.scss';

interface SelectedItemProps {
  selectedItem: DeserializedEntity;
  onRemoveClick: (selectedItem: DeserializedEntity) => void;
}

export const SelectedItem: React.FC<SelectedItemProps> = ({ selectedItem, onRemoveClick }) => (
  <li className={styles.selectedItem}>
    <Tag className={styles.selectedItemTag}>
      {selectedItem.attributes.name}
      <button type="button" className={styles.button} onClick={() => onRemoveClick(selectedItem)}>
        <CloseIcon className={styles.icon} />
      </button>
    </Tag>
  </li>
);
