import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './IconButton.module.scss';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: SVGComponent;
}

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  icon: Icon,
  ...props
}) => (
  <button className={classnames(styles.button, className)} {...props}>
    <Icon className={styles.icon} />
    {children}
  </button>
);
