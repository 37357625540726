import React from 'react';
import { DeserializedJobOffer } from 'types/jobOffer';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ReactComponent as BriefcaseIcon } from 'assets/icons/briefcase.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as PapersheetIcon } from 'assets/icons/papersheet.svg';
import { ReactComponent as SalaryIcon } from 'assets/icons/salary.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';

import styles from './JobOfferPage.module.scss';
import { messages } from './messages';

interface SummaryItemProps {
  icon: SVGComponent;
  content: React.ReactNode;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ icon: Icon, content }) => (
  <div className={styles.summaryItem}>
    <Icon className={styles.icon} />
    {content}
  </div>
);

export const JobOfferSummary: React.FC<DeserializedJobOffer> = ({
  workingHours,
  contractType,
  contractDuration,
  locationName,
  salary,
  url,
}) => {
  return (
    <div className={styles.detailsArea}>
      <h2 className={classnames(styles.cardHeader, styles.margined)}>
        <FormattedMessage {...messages.summary} />
      </h2>
      <SummaryItem
        icon={WebsiteIcon}
        content={
          <a href={url} rel="noreferrer noopener" target="_blank">
            {url}
          </a>
        }
      />
      <SummaryItem icon={LocationIcon} content={locationName} />
      <SummaryItem icon={BriefcaseIcon} content={workingHours} />
      <SummaryItem icon={PapersheetIcon} content={contractType} />
      <SummaryItem icon={ClockIcon} content={contractDuration} />
      <SummaryItem icon={SalaryIcon} content={salary} />
    </div>
  );
};
