import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Education, EducationFormField } from 'types';
import { getName, useApiErrorHandler } from 'helpers';
import { Tag } from 'components/Tag/Tag';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import { useApi } from 'api/ApiContext';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { object, array } from 'yup';
import { validators } from 'config/errors/validators';

import { InlineEditButton, ProfileBox, AddButton, ProfileBoxFormButtons } from '../';
import { messages } from '../../messages';

import { EducationField } from './EducationField';
import styles from './EducationBox.module.scss';

export interface EducationForm {
  educations: EducationFormField[];
}

const emptyEducation = {
  institution: '',
  degrees: [],
};

const getInitialValues = (educations: Education[]) =>
  educations.map(({ attributes: { institution, degrees } }) => ({
    institution: {
      label: getName(institution),
      value: getName(institution),
    },
    degrees: degrees.data.map(({ attributes: { name, id } }) => ({
      label: name,
      value: id,
    })),
  }));

interface EducationBoxProps {
  educations: Education[];
}

const validationSchema = object().shape({
  educations: array().of(
    object().shape({
      institution: validators.university,
      degrees: validators.degrees,
    })
  ),
});

export const EducationBox: React.FC<EducationBoxProps> = ({ educations }) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isAddingEducation, setAddingEducation] = useState<boolean>(false);
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  const handleSubmit = async (
    { educations }: EducationForm,
    { setSubmitting }: FormikHelpers<EducationForm>
  ) => {
    try {
      const { data } = await profile.updateEducation(educations);
      setUserData(data);
      setSubmitting(false);
      setEditing(false);
      setAddingEducation(false);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
      throw error;
    }
  };

  const edit = () => setEditing(true);

  return (
    <ProfileBox
      title={<FormattedMessage {...messages.educationTitle} />}
      onEdit={educations.length ? edit : undefined}
      isEdited={isEditing || isAddingEducation}
    >
      <Formik<EducationForm>
        initialValues={{ educations: getInitialValues(educations) }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form className={styles.form}>
            <FieldArray name="educations">
              {({ push, remove, form }) => {
                const educationFields: EducationFormField[] = form.values.educations;
                const lastIndex = educationFields.length - 1;

                const addEducation = () => {
                  setAddingEducation(true);
                  push(emptyEducation);
                };

                const removeEducation = (index: number) => {
                  remove(index);
                };

                const cancel = () => {
                  setEditing(false);
                  setAddingEducation(false);
                  resetForm();
                };

                return (
                  <>
                    {isEditing ? (
                      educationFields.map((_education, index) => (
                        <EducationField
                          key={`edu-${index}`}
                          index={index}
                          onRemove={removeEducation}
                        />
                      ))
                    ) : (
                      <>
                        <EducationPreview
                          educations={educations}
                          onEdit={addEducation}
                          isAdding={isAddingEducation || isEditing}
                        />
                        {isAddingEducation ? (
                          <EducationField
                            index={lastIndex}
                            withHint={educationFields.length === 1}
                          />
                        ) : (
                          <AddButton onClick={addEducation} />
                        )}
                      </>
                    )}
                    {(isAddingEducation || isEditing) && (
                      <ProfileBoxFormButtons onCancel={cancel} isSubmitting={isSubmitting} />
                    )}
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </ProfileBox>
  );
};

interface EducationPreviewProps extends EducationBoxProps {
  onEdit?: () => void;
  isAdding?: boolean;
}

export const EducationPreview: React.FC<EducationPreviewProps> = ({
  educations,
  onEdit,
  isAdding,
}) => {
  if (!educations.length && !isAdding) {
    return (
      <p className={styles.boxText}>
        <FormattedMessage
          {...messages.educationDefaultText}
          values={{
            button: <InlineEditButton onClick={onEdit} />,
          }}
        />
      </p>
    );
  }

  return (
    <>
      {educations.map(({ attributes: { institution, degrees } }) => (
        <div key={getName(institution)} className={styles.container}>
          <h5 className={styles.title}>{getName(institution)}</h5>
          {degrees.data.map(({ attributes: { name } }, index) => (
            <Tag className={styles.educationTag} key={`${name}-${index}`}>
              {name}
            </Tag>
          ))}
        </div>
      ))}
    </>
  );
};

export const EducationPreviewBox: React.FC<EducationBoxProps> = ({ educations }) => (
  <ProfileBox title={<FormattedMessage {...messages.educationTitle} />}>
    <EducationPreview educations={educations} />
  </ProfileBox>
);
