import React from 'react';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { Tag } from 'components/Tag/Tag';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { OptionType } from './types';
import styles from './MultiValue.module.scss';

export const MultiValue: React.FC<MultiValueProps<OptionType>> = ({
  removeProps,
  children,
  innerProps,
}) => (
  <Tag {...innerProps} className={styles.tag}>
    {children}
    <div {...removeProps} className={styles.button}>
      <CloseIcon className={styles.icon} />
    </div>
  </Tag>
);
