import React from 'react';
import classnames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';

import styles from './NavigationItem.module.scss';

export interface NavigationItemProps extends React.HTMLAttributes<HTMLLIElement> {}

export const NavigationItem: React.FC<NavigationItemProps> = ({ className, children }) => (
  <li className={classnames(styles.navItem, className)}>{children}</li>
);

export interface NavigationLinkProps extends NavLinkProps {}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  to,
  onClick,
  className,
  children,
}) => (
  <NavigationItem className={className}>
    <NavLink
      exact
      to={to}
      onClick={onClick}
      className={classnames(styles.link, className)}
      activeClassName={styles.active}
    >
      {children}
    </NavLink>
  </NavigationItem>
);

export const DropdownNavLink: React.FC<NavigationLinkProps> = ({ children, ...props }) => (
  <NavigationLink className={styles.dropdown} {...props}>
    {children}
  </NavigationLink>
);

export const LogoutButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({
  onClick,
  ...props
}) => (
  <NavigationItem className={styles.dropdown}>
    <button className={styles.logoutButton} onClick={onClick} {...props}>
      <FormattedMessage {...commonMessages.logout} />
    </button>
  </NavigationItem>
);
