import React, { useState, useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { validators } from 'config/errors/validators';
import { ValueType } from 'react-select';
import { object } from 'yup';
import { useApi } from 'api/ApiContext';
import { capitalizeFirstLetter, useApiErrorHandler } from 'helpers';
import { OptionType } from 'components/Autocomplete/types';
import { TextField } from 'components/TextField/TextField';
import { commonMessages } from 'intl/commonMessages';
import { SaveButton, CancelButton } from 'components/Button/Button';
import { SelectField } from 'components/Autocomplete/SelectField';
import { EditButton } from 'modules/profiles/components';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';

import styles from '../../ProfileSettings.module.scss';
import { DeserializedLocation } from '../../../../../../types';

import { GenderOptions } from './GenderOptions';

interface UserDetailsFormProps {
  firstName: string;
  lastName: string;
  yearOfBirth: number;
  birthPlace: DeserializedLocation;
  gender: string;
  genderOptions: string[];
}

interface UserForm {
  firstName: string;
  lastName: string;
  gender: ValueType<OptionType>;
}

const validationSchema = object().shape({
  firstName: validators.firstName,
  lastName: validators.lastName,
  gender: validators.gender,
});

export const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  firstName,
  lastName,
  yearOfBirth,
  birthPlace,
  gender: initialGender,
  genderOptions,
}) => {
  const initialState = useMemo(() => new GenderOptions(initialGender, genderOptions), [
    initialGender,
    genderOptions,
  ]);
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { setUserData } = useProfile();

  const [isEdited, setEdited] = useState<boolean>(false);

  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    gender: initialState.option,
  };

  const handleSubmit = async (
    { firstName, lastName, gender }: UserForm,
    actions: FormikHelpers<UserForm>
  ) => {
    try {
      const { data } = await profile.updateSettings({
        firstName: firstName,
        lastName: lastName,
        gender: (gender as OptionType).value,
      });
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      actions.setSubmitting(false);
      setEdited(false);
    }
  };

  const cancel = () => {
    setEdited(false);
  };

  const edit = () => {
    setEdited(true);
  };

  return (
    <div className={styles.inputsContainer}>
      <EditButton onClick={edit} disabled={isEdited} className={styles.editButton} />
      {isEdited ? (
        <Formik<UserForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <TextField
                name="firstName"
                label={formatMessage(commonMessages.firstNameLabel)}
                placeholder={formatMessage(commonMessages.firstNamePlaceholder)}
              />
              <TextField
                name="lastName"
                label={formatMessage(commonMessages.lastNameLabel)}
                placeholder={formatMessage(commonMessages.lastNamePlaceholder)}
              />
              <SelectField
                name="gender"
                isSearchable={false}
                isIndicatorVisible={true}
                options={initialState.options}
                label={formatMessage(commonMessages.genderOptionsLabel)}
                placeholder={formatMessage(commonMessages.genderPlaceholder)}
              />
              <div className={styles.buttonContainer}>
                <CancelButton onClick={cancel} className={styles.button} disabled={isSubmitting} />
                <SaveButton
                  className={styles.button}
                  isProcessing={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <p className={styles.label}>
            <FormattedMessage {...commonMessages.firstNameLabel} />
            <span className={styles.input}>{firstName}</span>
          </p>
          <p className={styles.label}>
            <FormattedMessage {...commonMessages.lastNameLabel} />
            <span className={styles.input}>{lastName}</span>
          </p>
          <p className={styles.label}>
            <FormattedMessage {...commonMessages.genderOptionsLabel} />
            <span className={styles.input}>
              {initialGender ? (
                capitalizeFirstLetter(initialGender)
              ) : (
                <FormattedMessage {...commonMessages.genderPlaceholder} />
              )}
            </span>
          </p>
          {yearOfBirth != null && (
            <p className={styles.label}>
              <FormattedMessage {...commonMessages.born} />
              <span className={styles.input}>{`${birthPlace.city}, ${yearOfBirth}`}</span>
            </p>
          )}
        </>
      )}
    </div>
  );
};
