import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { DeserializedJobOffer } from 'types/jobOffer';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FatArrow } from 'assets/icons/fat-arrow.svg';
import classnames from 'classnames';
import { Button } from 'components/Button/Button';
import { Spinner } from 'components/Spinner/Spinner';
import { NETWORK_EMAIL } from 'config/constants';
import { ROUTES } from 'config/routes';
import { useApiErrorHandler } from 'helpers';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { DeadlineInfo } from '../JobOffersList/components/JobOfferCard/JobOfferCard';

import { JobOfferSummary } from './JobOfferSummary';
import styles from './JobOfferPage.module.scss';
import { messages } from './messages';

const BackLink: React.FC<{ reversed?: boolean }> = ({ reversed }) => (
  <Link to={ROUTES.CAREER} className={classnames(styles.backLink, reversed && styles.white)}>
    <FatArrow className={styles.arrowIcon} />
    <FormattedMessage {...messages.backToJobOffersMessage} />
  </Link>
);

export const JobOfferPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const api = useApi();
  const { jobOfferId } = useParams();
  const handleError = useApiErrorHandler();

  const [jobOfferData, setJobOfferData] = useState<DeserializedJobOffer | null>(null);
  const [isFetchingJobOffer, setIsFetchingJobOffer] = useState<boolean>(true);

  useEffect(() => {
    const getJobOfferData = async () => {
      setIsFetchingJobOffer(true);

      try {
        const { data } = await api.jobOffers.getJobOffer(jobOfferId!);
        setJobOfferData(data.data.attributes);
      } catch (error) {
        // @todo handle 404 errors with a more meaningful message
        handleError(error);
      } finally {
        setIsFetchingJobOffer(false);
      }
    };
    getJobOfferData();
  }, [api.jobOffers, handleError, jobOfferId]);

  if (isFetchingJobOffer) {
    return <Spinner isLarge={true} className={styles.spinnerContainer} />;
  }

  if (!jobOfferData) return <Redirect to={ROUTES.CAREER} />;

  const { title, institutionName, description, deadline, createdAt, apply } = jobOfferData;

  return (
    <section className={styles.wrapper}>
      <Helmet title={formatMessage(messages.pageName)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <BackLink reversed />
        </div>
      </section>

      <section className={styles.jobOfferWrapper}>
        <h1 className={styles.pageTitle}>{title}</h1>
        <h2 className={styles.jobOfferInstitution}>{institutionName}</h2>
        <DeadlineInfo deadline={deadline} />
        <div className={styles.container}>
          <div className={styles.infoArea}>
            <WhiteBox>
              <div className={styles.descriptionHeading}>
                <h2 className={styles.cardHeader}>
                  <FormattedMessage {...messages.descriptionHeader} />
                </h2>
                <div className={styles.jobOfferCreatedAt}>
                  <CalendarIcon className={styles.icon} />
                  {createdAt}
                </div>
              </div>
              <div
                className={styles.descriptionContent}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </WhiteBox>
            <WhiteBox>
              <h2 className={styles.applyHeader}>
                <FormattedMessage {...messages.howToApply} />
              </h2>
              <div
                className={styles.descriptionContent}
                dangerouslySetInnerHTML={{ __html: apply }}
              />
              <p className={styles.boldDescription}>
                <FormattedMessage
                  {...messages.questionsContent}
                  values={{
                    email: <span className={styles.contactEmail}>{NETWORK_EMAIL}</span>,
                  }}
                />
              </p>
            </WhiteBox>
            <section className={styles.footerNavigation}>
              <BackLink />
              <Button
                secondary
                className={styles.scrollTopButton}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <FormattedMessage {...messages.scrollToTopMessage} />
              </Button>
            </section>
          </div>
          <JobOfferSummary {...jobOfferData} />
        </div>
      </section>
    </section>
  );
};
