import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import debounce from 'debounce-promise';
import { useApiErrorHandler } from 'helpers';
import { useApi } from 'api/ApiContext';

import { AutocompleteFieldProps, MenuProps } from './types';

import { AutocompleteField, MenuWithHint } from './';
import './Autocomplete.scss';

const Menu: React.FC<MenuProps> = props => (
  <MenuWithHint
    hint={<FormattedMessage {...commonMessages.affiliationAutocompleteHint} />}
    {...props}
  />
);

export const AffiliationAutocompleteField: React.FC<Omit<
  AutocompleteFieldProps,
  'loadOptions'
>> = ({ components, name, ...props }) => {
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();

  const getInstitutions = useCallback(
    async (query: string) => {
      try {
        const response = await profile.getInstitutions(query);
        return response.data.data;
      } catch (error) {
        handleError(error);
      }
    },
    [profile, handleError]
  );

  return (
    <AutocompleteField
      name={name}
      components={{ Menu }}
      label={formatMessage(commonMessages.affiliationLabel)}
      placeholder={formatMessage(commonMessages.affiliationPlaceholder)}
      loadOptions={debounce(getInstitutions, 400)}
      {...props}
    />
  );
};
