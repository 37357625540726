import React from 'react';
import AsyncSelect from 'react-select/async';

import { AutocompleteFieldProps } from './types';

export const AutocompleteSearch: React.FC<AutocompleteFieldProps> = ({ name, label, ...props }) => {
  return (
    <div className="select-container">
      <label className="select-container__label">{label}</label>
      <AsyncSelect isClearable classNamePrefix="react-select" {...props} />
    </div>
  );
};
