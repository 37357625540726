import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dragAndDrop: {
    id: 'PhotoUploadField.dragAndDrop',
    defaultMessage: 'Drag & drop or <strong>browse</strong> to choose a profile photo',
    description: 'Drag and drop call to action',
  },

  profilePhoto: {
    id: 'PhotoUploadField.profilePhoto',
    defaultMessage: 'Profile Photo',
    description: 'Photo upload title',
  },
  availableFormats: {
    id: 'PhotoUploadField.availableFormats',
    defaultMessage: 'available file formats: {formats}',
    description: 'Available file formats',
  },
  maxPhotoSize: {
    id: 'PhotoUploadField.maxPhotoSize',
    defaultMessage: 'file size max. 1MB',
    description: 'Max photo file size',
  },
});
