import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  offerCTATitleHighlighted: {
    id: 'Home.offerCTATitleHighlighted',
    defaultMessage: 'How to add an offer? ',
    description: 'Offer CTA section title - highlighted',
  },
  offerCTATitle: {
    id: 'Home.offerCTATitle',
    defaultMessage: 'Contact us and receive proper instructions',
    description: 'Offer CTA section title',
  },
  description: {
    id: 'Home.offerCTAdescription',
    defaultMessage: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, enim.',
    description: 'Offer CTA description',
  },
  buttonLabel: {
    id: 'Home.offerCTAButtonLabel',
    defaultMessage: 'Get in touch',
    description: 'Offer CTA button label',
  },
});
