import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Login.pageTitle',
    defaultMessage: 'Sign in',
    description: 'Login page title',
  },
  header: {
    id: 'Login.header',
    defaultMessage: 'Sign in to Polonium Network',
    description: 'Login form main header',
  },
  description: {
    id: 'Login.description',
    defaultMessage: 'Please enter your details below.',
    description: 'Login form description',
  },
  submitButton: {
    id: 'Login.submitButton',
    defaultMessage: 'Sign in',
    description: 'Login form submit button label',
  },
  authNavbarLink: {
    id: 'AuthLayout.signup',
    defaultMessage: 'Join free',
    description: 'Signup CTA link label',
  },
  authNavbarDescription: {
    id: 'AuthLayout.signupDescription',
    defaultMessage: 'New to Polonium?',
    description: 'Signup CTA description',
  },
});
