import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Props as SelectProps } from 'react-select/src/Select';
import { ValueType } from 'react-select';
import { useApi } from 'api/ApiContext';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';
import { SaveButton, CancelButton } from 'components/Button/Button';
import { OptionType } from 'components/Autocomplete';
import { useApiErrorHandler } from 'helpers';
import { Status } from 'types';

import { messages } from '../../messages';

import styles from './StatusBox.module.scss';
import { StatusDropdown } from './StatusDropdown/StatusDropdown';
import { StatusVisibility } from './StatusVisibility/StatusVisibility';
import { UserStatus } from './UserStatus';

export interface StatusBoxProps<T> extends SelectProps<T> {
  status: Status;
  statusOptions: string[];
}

export const StatusBox: React.FC<StatusBoxProps<OptionType>> = ({
  status,
  statusOptions,
  children,
  ...props
}) => {
  const initialStatus = useMemo(() => new UserStatus(status, statusOptions), [
    status,
    statusOptions,
  ]);
  const { formatMessage } = useIntl();
  const [isEdited, setEdited] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState<ValueType<OptionType>>(initialStatus.option);
  const [isVisible, setIsVisible] = useState<boolean>(initialStatus.isVisible);
  const [statement, setStatement] = useState<string>(initialStatus.statement);
  const { profile } = useApi();
  const { setUserData } = useProfile();
  const handleError = useApiErrorHandler();

  const handleOptionChange = (newValue: ValueType<OptionType>) => {
    setStatusOption(newValue);
    setEdited(true);
  };

  const toggleStatusVisibility = () => {
    setEdited(true);
    setIsVisible(isStatusHidden => !isStatusHidden);
  };

  const handleStatusChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStatement(target.value);
    setEdited(true);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const { data } = await profile.updateCurrentUser({
        currentStatusAttributes: {
          prefix: (statusOption as OptionType).value,
          statement,
          isVisible,
        },
      });
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
      setEdited(false);
    }
  };

  const cancel = () => {
    setEdited(false);
    setStatement(initialStatus.statement);
    setStatusOption(initialStatus.option);
    setIsVisible(initialStatus.isVisible);
  };

  return (
    <>
      <div
        className={classnames(styles.container, styles.isEditable, {
          [styles.isStatusHidden]: !isVisible,
        })}
      >
        <div className={classnames(styles.mobileContainer, styles.isEditable)}>
          <h5 className={styles.title}>
            <FormattedMessage {...messages.status} />
          </h5>
          <StatusDropdown
            options={initialStatus.options}
            isStatusHidden={!isVisible}
            onChange={handleOptionChange}
            value={statusOption}
            {...props}
          />
          <textarea
            className={styles.textarea}
            placeholder={formatMessage(messages.statusPlaceholder)}
            onChange={handleStatusChange}
            value={statement}
            disabled={!isVisible}
          />
          <StatusVisibility
            isVisible={isVisible}
            isEmpty={!statement}
            onToggle={toggleStatusVisibility}
          />
        </div>
      </div>
      {isEdited && (
        <div className={styles.buttons}>
          <CancelButton
            small={true}
            onClick={cancel}
            className={styles.cancel}
            disabled={isSubmitting}
          />
          <SaveButton
            small={true}
            onClick={handleSubmit}
            className={styles.saveButton}
            disabled={isSubmitting}
            isProcessing={isSubmitting}
          />
        </div>
      )}
    </>
  );
};

export interface StatusPreviewBoxProps {
  status: Status;
}

export const StatusPreviewBox: React.FC<StatusPreviewBoxProps> = ({
  status: { prefix, statement },
}) => (
  <div className={styles.container}>
    <div className={styles.mobileContainer}>
      <h5 className={styles.title}>
        <FormattedMessage {...messages.status} />
      </h5>
      <p className={styles.text}>
        {prefix} {statement}
      </p>
    </div>
  </div>
);
