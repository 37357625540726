import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'Community.pageTitle',
    defaultMessage: 'Community',
    description: 'Community page name',
  },
  title: {
    id: 'Community.title',
    defaultMessage: 'Our community',
    description: 'Main title',
  },
  subtitle: {
    id: 'Community.subtitle',
    defaultMessage:
      'Exchange your thoughs and experiences. Create with us a global polish scholars family.',
    description: 'Main subtitle',
  },
  searchBySubjectTitle: {
    id: 'Community.searchBySubjectTitle',
    defaultMessage: 'Search contacts by subject area',
    description: 'Search by subject title',
  },
  searchByResidenceTitle: {
    id: 'Community.searchByResidenceTitle',
    defaultMessage: 'Search contacts by popular city of residence',
    description: 'Search by residence title',
  },
  viewAllButton: {
    id: 'Community.viewAllButton',
    defaultMessage: 'View all users',
    description: 'View all button',
  },
  profilesCount: {
    id: 'Community.profilesCount',
    defaultMessage: ' {count, plural, one {contact} other {contacts}}',
    description: 'Contact count',
  },
});
