import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from 'assets/icons/eye-crossed.svg';

import { messages } from '../../../messages';

import styles from './StatusVisibility.module.scss';

export interface StatusVisibilityProps {
  isVisible: boolean;
  isEmpty: boolean;
  onToggle: () => void;
}

export const StatusVisibility: React.FC<StatusVisibilityProps> = ({
  isVisible,
  isEmpty,
  onToggle,
}) => {
  const Icon = isVisible ? EyeCrossedIcon : EyeIcon;

  return (
    <div className={styles.wrapper}>
      {isVisible && isEmpty ? (
        <p className={styles.hint}>
          <FormattedMessage {...messages.hint} />
        </p>
      ) : (
        <button className={styles.button} onClick={onToggle}>
          <Icon className={styles.icon} />
          <FormattedMessage {...messages[isVisible ? 'hideStatus' : 'showStatus']} />
        </button>
      )}
    </div>
  );
};
