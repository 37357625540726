import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'components/Tag/Tag';

import { ProfileBox } from '../';
import { messages } from '../../messages';

import styles from './ExpertiseBox.module.scss';

interface ExpertiseType {
  attributes: {
    id: number;
    name: string;
  };
}

interface ExpertiseBoxProps {
  expertise: ExpertiseType[];
  onEditClick?: () => void;
}

export const ExpertiseBox: React.FC<ExpertiseBoxProps> = ({ expertise, onEditClick }) => (
  <ProfileBox title={<FormattedMessage {...messages.expertiseTitle} />} onEdit={onEditClick}>
    <ul className={styles.list}>
      {expertise.map(({ attributes: { name, id } }) => (
        <li key={id} className={styles.listItem}>
          <Tag>{name}</Tag>
        </li>
      ))}
    </ul>
  </ProfileBox>
);
