import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, array } from 'yup';
import { useApi } from 'api/ApiContext';
import { validators } from 'config/errors/validators';
import { ROUTES } from 'config/routes';
import { useAuthContext } from 'modules/auth/AuthContext';
import { OnboardingLayout } from 'modules/onboarding/components/OnboardingLayout';
import { AffiliationFormContent, emptyAffiliation } from 'modules/profiles/components/';
import { AffiliationForm, AffiliationFilledForm } from 'types';
import { useApiErrorHandler } from 'helpers/errors/useApiErrorHandler';
import { Button } from 'components/Button/Button';
import { ButtonLink } from 'components/Button/ButtonLink';
import { Helper } from 'components/Helper/Helper';
import background from 'assets/images/background-2.svg';

import { messages } from './messages';
import styles from './AffiliationSetup.module.scss';

export interface AffiliationAndPrivacyForm extends AffiliationForm {
  hiddenProfile: boolean;
}

export interface AffiliationAndPrivacyFilledForm extends AffiliationFilledForm {
  hiddenProfile: boolean;
}

const validationSchema = object().shape({
  residence: validators.residence,
  affiliations: array().of(
    object().shape({
      position: validators.position,
      institution: validators.institution,
    })
  ),
});

const initialValues = {
  hiddenProfile: false,
  residence: null,
  city: '',
  coordinates: null,
  affiliations: [emptyAffiliation],
};

export const AffiliationSetup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { onboarding } = useApi();
  const { updateUser } = useAuthContext();
  const { push } = useHistory();
  const handleError = useApiErrorHandler();

  const handleSubmit = async (
    values: AffiliationAndPrivacyForm,
    { setSubmitting }: FormikHelpers<AffiliationAndPrivacyForm>
  ) => {
    try {
      const { data } = await onboarding.saveAffiliations(values as AffiliationAndPrivacyFilledForm);
      updateUser(data.data.attributes);
      push(ROUTES.MY_PROFILE);
    } catch (error) {
      handleError(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <OnboardingLayout
        background={background}
        activeStep={2}
        title={formatMessage(messages.header)}
      >
        <Formik<AffiliationAndPrivacyForm>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <div className={styles.row}>
                <Helper
                  rotated
                  title={<FormattedMessage {...messages.helperTitle} />}
                  description={<FormattedMessage {...messages.helperDescription} />}
                />
                <AffiliationFormContent />
              </div>

              <div className={styles.bottomContainer}>
                <div className={styles.buttonsContainer}>
                  <ButtonLink to={ROUTES.DISCIPLINE} secondary={true} className={styles.button}>
                    <FormattedMessage {...messages.backButton} />
                  </ButtonLink>
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    disabled={isSubmitting}
                    isProcessing={isSubmitting}
                  >
                    <FormattedMessage {...messages.nextButton} />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </OnboardingLayout>
    </>
  );
};
