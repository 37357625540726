import { OptionType } from 'components/Autocomplete';
import { Status } from 'types';

export class UserStatus {
  prefix: string;
  statement: string;
  isVisible: boolean;
  options: OptionType[];

  constructor({ prefix, statement, isVisible }: Status, options: string[]) {
    this.prefix = prefix;
    this.statement = statement;
    this.isVisible = isVisible;
    this.options = this.generateOptions(options);
  }

  get option() {
    return this.findOption(this.prefix);
  }

  private generateOptions(pulledOptions: string[]) {
    return pulledOptions.map(value => ({
      label: value,
      value,
    }));
  }

  private findOption(prefix: string): OptionType {
    const option = this.options.find(({ value }) => value === prefix);

    if (!option) {
      console.error('Cannot find option matching the status prefix');
      return this.options[0];
    }

    return option;
  }
}
