import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'AccountConfirmation.pageTitle',
    defaultMessage: 'Confirm your account',
    description: 'Account confirmation page title',
  },
  header: {
    id: 'AccountConfirmation.header',
    defaultMessage:
      'Thank you for registering with Polonium Network. Check your email for the activation link.',
    description: 'Account Confirmation main header',
  },
  description: {
    id: 'AccountConfirmation.description',
    defaultMessage:
      'To activate your account, follow the link sent to {email} and set up your password.',
    description: 'Account Confirmation description',
  },
  resendSuccess: {
    id: 'AccountConfirmation.resendSuccess',
    defaultMessage: 'We have resent a link to set up new password.',
    description: 'Account Confirmation - successful resend message',
  },
});
