import React from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { AffiliationAutocompleteField, DegreeSelectField } from 'components/Autocomplete';
import { Helper } from 'components/Helper/Helper';

import { RemoveButton } from '../';
import { messages } from '../../messages';

import styles from './EducationBox.module.scss';

export interface EducationFieldProps {
  index: number;
  onRemove?: (index: number) => void;
  withHint?: boolean;
}

export const EducationField: React.FC<EducationFieldProps> = ({ index, onRemove, withHint }) => (
  <div className={styles.formRow}>
    {onRemove && <RemoveButton onClick={() => onRemove(index)} />}
    <AffiliationAutocompleteField
      name={`educations[${index}]institution`}
      label={<FormattedMessage {...commonMessages.universityLabel} />}
    />
    <DegreeSelectField name={`educations[${index}]degrees`} />
    {withHint && (
      <Helper
        title={<FormattedMessage {...messages.educationHelperTitle} />}
        description={<FormattedMessage {...messages.educationHelperDescription} />}
      />
    )}
  </div>
);
