import React from 'react';
import classnames from 'classnames';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';

import styles from './Step.module.scss';

interface StepProps {
  isActive: boolean;
  isDone: boolean;
  number: number;
  title: React.ReactNode;
  description?: React.ReactNode;
}

export const Step: React.FC<StepProps> = ({ isDone, isActive, number, title, description }) => (
  <li
    className={classnames(styles.step, {
      [styles.active]: isActive,
      [styles.done]: isDone,
    })}
  >
    <div
      className={classnames(styles.circle, {
        [styles.nextStep]: !isDone && !isActive,
      })}
    >
      {isDone ? <TickIcon className={styles.tickIcon} /> : number}
    </div>
    {isActive && <span className={styles.activeLine} />}
    <div className={styles.textContainer}>
      <h5 className={styles.stepTitle}>{title}</h5>
      {isActive && <p className={styles.stepDescription}>{description}</p>}
    </div>
  </li>
);
