import ministry from 'assets/images/ministry_of_science.svg';
import nawa from 'assets/images/NAWA.png';
import dialog from 'assets/images/dialog.png';

export const logos = [
  {
    image: ministry,
    title: 'Ministry of Science and Higher Education',
    link: 'https://www.gov.pl/web/science',
  },
  {
    image: nawa,
    title: 'NAWA: Narodowa Agencja Wymiany Akademickiej',
    link: 'https://nawa.gov.pl/',
  },
  {
    image: dialog,
    title: 'Program Dialog',
    link: 'https://www.gov.pl/web/nauka/dialog',
  },
];
