import { ReactourStep } from 'reactour';
import React from 'react';

import styles from './GrantsList.module.scss';

export const steps = [
  {
    selector: '#first-step',
    content: () => (
      <span className={styles.stepContent}>
        Welcome to Polonium Network! Dive into the realm of scientific opportunities.
        <br />
        Our comprehensive grants database is tailored for researchers like you. Find the right fit
        for your next big idea!
      </span>
    ),
    position: 'top',
  },
  // {
  //   selector: '#second-step',
  //   content: () => (
  //     <span className={styles.stepContent}>
  //       Our comprehensive grants database is tailored for researchers like you. Find the right fit
  //       for your next big idea!
  //     </span>
  //   ),
  //   position: 'bottom',
  // },
  {
    selector: '#third-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Your Comprehensive Grant List</h4>
        <br />
        <span className={styles.stepContent}>
          Browse through numerous grant opportunities tailored to your research needs. Each entry
          provides vital information about the grant.
        </span>
      </>
    ),
    position: [20, 20],
  },
  {
    selector: '#fourth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Career Stage</h4>
        <br />
        <span className={styles.stepContent}>
          Identify the career stages suitable for this grant. If unsure, always refer to the grant
          link for specifics.
        </span>
      </>
    ),
    position: 'top',
  },
  {
    selector: '#fifth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Grant Operational Countries</h4>
        <br />
        <span className={styles.stepContent}>
          The countries where the grant is active or where research projects can be conducted.
        </span>
      </>
    ),
    position: 'left',
  },
  {
    selector: '#sixth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Granting Institution</h4>
        <br />
        <span className={styles.stepContent}>
          The institution or organization offering this grant opportunity.
        </span>
      </>
    ),
    position: 'left',
  },
  {
    selector: '#seventh-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Granting Title</h4>
        <br />
        <span className={styles.stepContent}>
          A brief title or description of the grant opportunity.
        </span>
      </>
    ),
    position: 'left',
  },
  {
    selector: '#eighth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Eligibility Criteria</h4>
        <br />
        <span className={styles.stepContent}>
          Criteria that need to be met to be considered for this grant. For detailed information,
          follow the grant link.
        </span>
      </>
    ),
    position: 'left',
  },
  {
    selector: '#ninth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Direct Grant Link</h4>
        <br />
        <span className={styles.stepContent}>
          Click here to view complete details and apply for this grant.
        </span>
      </>
    ),
    position: 'left',
  },
  window.innerWidth > 992
    ? {
        selector: '#tenth-step',
        content: () => (
          <>
            <h4 className={styles.stepTitle}>Filter by Country</h4>
            <br />
            <span className={styles.stepContent}>
              Select the desired country or countries to narrow down grant opportunities. Missing
              fields in grants will still be included in your results.
            </span>
          </>
        ),
        position: 'top',
      }
    : undefined,
  window.innerWidth > 992
    ? {
        selector: '#eleventh-step',
        content: () => (
          <>
            <h4 className={styles.stepTitle}>Filter by Career Stage</h4>
            <br />
            <span className={styles.stepContent}>
              Choose the relevant career stage to find grants tailored to your professional level.
              Grants with unspecified stages will also appear in your search.
            </span>
          </>
        ),
        position: 'right',
      }
    : undefined,
  {
    selector: '#twelfth-step',
    content: () => (
      <>
        <h4 className={styles.stepTitle}>Keyword Search</h4>
        <br />
        <span className={styles.stepContent}>
          Enter keywords related to the grant you're looking for. This will help refine your search
          results to match your interests.
        </span>
      </>
    ),
    position: 'top',
  },
].filter(step => step !== undefined) as ReactourStep[];
