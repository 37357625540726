import React from 'react';
import { Switch, Redirect, RouteProps } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';

import { MyProfile } from './pages/MyProfile/MyProfile';
import { ProfileSettings } from './pages/ProfileSettings/ProfileSettings';
import { Profile } from './pages/Profile/Profile';
import { ProfilesList } from './pages/ProfilesList/ProfilesList';
import { ProfileContextProvider } from './pages/MyProfile/ProfileContext';
import { Community } from './pages/Community/Community';
import { Communicator } from './pages/Communicator/Communicator';

export const ProfileRoutes: React.FC<RouteProps> = () => (
  <ProfileContextProvider>
    <Switch>
      <ProtectedRoute exact path={ROUTES.MY_PROFILE} component={MyProfile} />
      <ProtectedRoute exact path={ROUTES.SETTINGS} component={ProfileSettings} />
      <ProtectedRoute exact path={ROUTES.COMMUNITY_LIST} component={ProfilesList} />
      <ProtectedRoute exact path={ROUTES.COMMUNITY} component={Community} />
      <ProtectedRoute exact path={ROUTES.MESSAGES} component={Communicator} />
      <ProtectedRoute exact path={ROUTES.PROFILE} component={Profile} />
      <Redirect to={ROUTES.COMMUNITY} />
    </Switch>
  </ProfileContextProvider>
);
