import React, { useState, useCallback, createContext, useContext } from 'react';

export interface NavContextValues {
  isMobileMenuOpen: boolean;
  toggleMobileMenuOpen: () => void;
  closeMobileMenu: () => void;
}

export const NavContext = createContext<NavContextValues | null>(null);

export interface NavContextProviderProps {
  isMobileInitiallyOpen?: boolean;
}

export const NavContextProvider: React.FC<NavContextProviderProps> = ({
  isMobileInitiallyOpen = false,
  children,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(isMobileInitiallyOpen);

  const toggleMobileMenuOpen = useCallback(() => {
    setMobileMenuOpen(prevIsOpen => !prevIsOpen);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpen(false);
  }, []);

  return (
    <NavContext.Provider value={{ isMobileMenuOpen, toggleMobileMenuOpen, closeMobileMenu }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNavContext = () => {
  const context = useContext(NavContext);

  if (!context) throw new Error('useNavContext have to be used within NavContextProvider');

  return context;
};
