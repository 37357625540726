import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  searchLabel: {
    id: 'SearchGrantBar.searchLabel',
    defaultMessage: 'Search',
    description: 'Label for search input',
  },
  searchPlaceholder: {
    id: 'SearchGrantBar.searchPlaceholder',
    defaultMessage: 'Search by Keywords',
    description: 'Placeholder for search query',
  },
  searchInstructions: {
    id: 'SearchGrantBar.searchInstructions',
    defaultMessage: 'Use a comma to separate the keywords',
    description: 'Instructions for search query',
  },
  searchLabelClear: {
    id: 'DropdownSelectMenu.searchLabelClear',
    defaultMessage: 'Clear',
    description: 'Clear button text for location input',
  },
});
