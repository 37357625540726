import { AxiosResponse } from 'axios';
import {
  AffiliationFilledForm,
  Degree,
  DeserializedDegree,
  DeserializedDiscipline,
  DeserializedGrantInstitution,
  DeserializedGrantName,
  DeserializedNestedInstitution,
  DeserializedPosition,
  DeserializedUserProfile,
  Discipline,
  EducationFormField,
  GrantFormField,
  GrantInstitution,
  GrantName,
  Institution,
  Position,
  UpdatedProfile,
  UpdatedSettings,
  UserProfile,
} from 'types';
import { capitalizeFirstLetter } from 'helpers';

import { ResourceClient } from './ResourceClient';
import { deserializePosition as deserializeEntityList } from './deserializers/deserializeEntityList';
import { serializeAffiliations, serializeLocation } from './serializers';
import { deserializeProfile } from './deserializers/deserializeProfile';

export class ProfileClient extends ResourceClient {
  getCategories(): Promise<AxiosResponse<{ data: Discipline[] }>> {
    return this.client.get<{ data: Discipline[] }>('/disciplines');
  }

  getDisciplines(id: string): Promise<AxiosResponse<{ data: Discipline[] }>> {
    return this.client.get<{ data: Discipline[] }>(`/disciplines/${id}`);
  }

  async getPositions(): Promise<AxiosResponse<{ data: DeserializedPosition[] }>> {
    const response = await this.client.get<{ data: Position[] }>('/academic_titles');

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getDegrees(): Promise<AxiosResponse<{ data: DeserializedDegree[] }>> {
    const response = await this.client.get<{ data: Degree[] }>('/degrees');

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getGrantInstitutions(): Promise<AxiosResponse<{ data: DeserializedGrantInstitution[] }>> {
    const response = await this.client.get<{ data: GrantInstitution[] }>('/grant_institutions');

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getGrantNames(
    institutionId: string
  ): Promise<AxiosResponse<{ data: DeserializedGrantName[] }>> {
    const response = await this.client.get<{ data: GrantName[] }>(
      `/grant_institutions/${institutionId}`
    );

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getInstitutions(
    query = ''
  ): Promise<AxiosResponse<{ data: DeserializedNestedInstitution[] }>> {
    const response = await this.client.get<{ data: Institution[] }>(`/institutions?query=${query}`);

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getSubdisciplines(query = ''): Promise<AxiosResponse<{ data: DeserializedDiscipline[] }>> {
    const response = await this.client.get<{ data: Discipline[] }>(
      `/subdisciplines?query=${query}`
    );

    return {
      ...response,
      data: {
        ...response.data,
        data: deserializeEntityList(response.data.data),
      },
    };
  }

  async getStatusOptions(): Promise<AxiosResponse<string[]>> {
    return this.client.get<string[]>('/status_prefixes');
  }

  async getSocialMediaPlatforms(): Promise<AxiosResponse<string[]>> {
    const response = await this.client.get<string[]>('/social_media_platforms');

    return {
      ...response,
      data: response.data.map(capitalizeFirstLetter),
    };
  }

  async getCurrentUser(): Promise<AxiosResponse<DeserializedUserProfile>> {
    const response = await this.client.get<{ data: UserProfile }>('/profiles/current_user');

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateCurrentUser(data: Partial<UpdatedProfile>) {
    const response = await this.client.patch('/profiles/current_user', { user: data });

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateHeader(userHeader: AffiliationFilledForm) {
    const { residence, affiliations } = userHeader;

    const user = {
      currentResidenceAttributes: serializeLocation(residence),
      affiliationsAttributes: serializeAffiliations(affiliations),
    };

    const response = await this.client.put('/profiles/current_user/header', { user });

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updatePhoto(avatarFile: File | null): Promise<AxiosResponse<DeserializedUserProfile>> {
    let response: AxiosResponse<{ data: UserProfile }>;

    if (!avatarFile) {
      response = await this.client.put('/profiles/current_user/avatar', { user: { avatar: null } });
    } else {
      const formData = new FormData();
      avatarFile && formData.append('user[avatar]', avatarFile);

      response = await this.client.put('/profiles/current_user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateInstitutions(
    institutions: string[]
  ): Promise<AxiosResponse<DeserializedUserProfile>> {
    const response = await this.client.patch<{ data: UserProfile }>(
      '/profiles/current_user/institutions_assignment',
      {
        user: { institutions: institutions.map(name => ({ name })) },
      }
    );

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateEducation(
    educations: EducationFormField[]
  ): Promise<AxiosResponse<DeserializedUserProfile>> {
    const educationsAttributes = educations.map(({ institution, degrees }) => ({
      institution: institution.label,
      degrees: degrees.map(({ label }) => ({ name: label })),
    }));

    const response = await this.client.put<{ data: UserProfile }>(
      '/profiles/current_user/educations_assignment',
      { user: { educationsAttributes } }
    );

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateGrants(grants: GrantFormField[]): Promise<AxiosResponse<DeserializedUserProfile>> {
    const response = await this.client.put<{ data: UserProfile }>('/profiles/current_user/grants', {
      user: {
        userGrantsAttributes: grants.map(({ provider, name }) => ({
          grantInstitution: provider.label,
          grantName: name.label,
        })),
      },
    });

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async getUser(userId: string): Promise<AxiosResponse<DeserializedUserProfile>> {
    const response = await this.client.get<{ data: UserProfile }>(`/profiles/${userId}`);

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async updateSettings(data: Partial<UpdatedSettings>) {
    const response = await this.client.patch('/profiles/current_user/settings', { user: data });

    return {
      ...response,
      data: deserializeProfile(response.data),
    };
  }

  async getGenderOptions(): Promise<AxiosResponse<string[]>> {
    return this.client.get<string[]>('/genders');
  }

  updateEmail(email: string): Promise<AxiosResponse> {
    return this.client.put('/auth/email', { email });
  }

  updatePassword(password: string, passwordConfirmation: string): Promise<AxiosResponse> {
    return this.client.put('/auth/password', { password, passwordConfirmation });
  }

  sendMessage(
    body: string,
    sender: string,
    receiver: string
  ): Promise<AxiosResponse<{ sent: boolean }>> {
    const message = { body, receiver_id: receiver, sender_id: sender };

    return this.client.post<{ sent: boolean }>('/send_message', {
      message,
    });
  }

  async getMessages(): Promise<AxiosResponse> {
    return await this.client.get(`/messages`);
  }

  async getMessageCounts(): Promise<AxiosResponse> {
    return await this.client.get(`/message_counts`);
  }

  deleteMessages(sender: string, receiver: string): Promise<AxiosResponse<{ deleted: boolean }>> {
    const message = { receiver_id: receiver, sender_id: sender };

    return this.client.post<{ deleted: boolean }>('/delete_messages', {
      message,
    });
  }

  markMessagesAsRead(sender: string, receiver: string): Promise<AxiosResponse<{ read: boolean }>> {
    const message = { receiver_id: receiver, sender_id: sender };

    return this.client.post<{ read: boolean }>('/mark_messages_as_read', {
      message,
    });
  }

  blockUser(to_block: string): Promise<AxiosResponse<{ blocked: boolean }>> {
    return this.client.post<{ blocked: boolean }>('/block_user', { to_block });
  }

  unblockUser(to_unblock: string): Promise<AxiosResponse<{ unblocked: boolean }>> {
    return this.client.post<{ unblocked: boolean }>('/unblock_user', { to_unblock });
  }
}
