import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  firstNameRequired: {
    id: 'error.firstNameRequired',
    defaultMessage: 'First name is required',
  },
  lastNameRequired: {
    id: 'error.lastNameRequired',
    defaultMessage: 'Last name is required',
  },
  genderRequired: {
    id: 'error.genderRequired',
    defaultMessage: 'Gender is required',
  },
  emailFormat: {
    id: 'error.emailFormat',
    defaultMessage: 'Incorrect e-mail format',
  },
  emailRequired: {
    id: 'error.emailRequired',
    defaultMessage: 'Email is required',
  },
  passwordRequired: {
    id: 'error.passwordRequired',
    defaultMessage: 'Password is required',
  },
  passwordLength: {
    id: 'error.passwordLength',
    defaultMessage: 'Password must be at least 8 characters long',
  },
  passwordDigits: {
    id: 'error.passwordDigits',
    defaultMessage: 'Password must contain at least one digit',
  },
  passwordLetters: {
    id: 'error.passwordLetters',
    defaultMessage: 'Password must contain at least one letter',
  },
  passwordSpecialChar: {
    id: 'error.passwordSpecialChar',
    defaultMessage: 'Password must contain at least one special character',
  },
  passwordMismatch: {
    id: 'error.passwordMismatch',
    defaultMessage: 'Passwords must match',
  },
  requiredField: {
    id: 'error.requiredField',
    defaultMessage: 'This field is required',
  },
  residenceRequired: {
    id: 'error.residenceRequired',
    defaultMessage: 'Residence is required',
  },
  positionRequired: {
    id: 'error.positionRequired',
    defaultMessage: 'Position is required',
  },
  institutionRequired: {
    id: 'error.institutionRequired',
    defaultMessage: 'Affiliation institution is required',
  },
  institutionsMinLength: {
    id: 'error.institutionsMinLength',
    defaultMessage: 'Please select at least 1 institution',
  },
  universityRequired: {
    id: 'error.universityRequired',
    defaultMessage: 'University name is required',
  },
  degreesMinLength: {
    id: 'error.degreesMinLength',
    defaultMessage: 'Please select at least 1 degree',
  },
  grantProviderRequired: {
    id: 'error.grantProviderRequired',
    defaultMessage: 'Institution name is required',
  },
  grantNameRequired: {
    id: 'error.grantNameRequired',
    defaultMessage: 'Grant name is required',
  },
  socialMediaPlatformRequired: {
    id: 'error.socialMediaPlatformRequired',
    defaultMessage: 'Platform is required',
  },
  socialMediaUrlRequired: {
    id: 'error.socialMediaUrlRequired',
    defaultMessage: 'Link is required',
  },
  socialMediaUrlFormat: {
    id: 'error.socialMediaUrlFormat',
    defaultMessage: 'Incorrect link format',
  },
});
