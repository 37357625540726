import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  instructionsHeader: {
    id: 'EmailConfirmationInstruction.instructionsHeader',
    defaultMessage: 'If the message has not reached you, try:',
    description: 'Confirmation Instructions header',
  },
  checkSpam: {
    id: 'EmailConfirmationInstruction.checkSpam',
    defaultMessage: 'Checking the SPAM folder, or',
    description: 'Confirmation Instructions - step 1',
  },
  sendAgain: {
    id: 'EmailConfirmationInstruction.sendAgain',
    defaultMessage: 'Request {button}.',
    description: 'Confirmation Instructions - step 2',
  },
  resendButton: {
    id: 'EmailConfirmationInstruction.resendButton',
    defaultMessage: 'to have the link sent once again',
    description: 'Link to resend confirmation email',
  },
  homeButton: {
    id: 'EmailConfirmationInstruction.homeButton',
    defaultMessage: 'Return home',
    description: 'Confirmation Instructions home button label',
  },
});
