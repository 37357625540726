import { ProfileLocation } from 'types';

export const deserializeLocation = (profileLocation: ProfileLocation) => {
  const { name, latitude, longitude } = profileLocation.data?.attributes || {};

  if (name && latitude && longitude) {
    return {
      location: {
        name: name,
        lat: latitude,
        lng: longitude,
      },
      city: name,
      coordinates: { lat: latitude, lng: longitude },
    };
  }
  return {
    location: {
      name: '',
      lat: 0,
      lng: 0,
    },
    city: '',
    coordinates: { lat: 0, lng: 0 },
  };
};
