import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApi } from 'api/ApiContext';

import { ROUTES } from '../../../../config/routes';
import { commonMessages } from '../../../../intl/commonMessages';

import { NavigationLink } from './NavigationItem';

export const MessagesCounter: React.FC = () => {
  const { profile } = useApi();
  const [unread, setUnread] = useState(0);
  const getMessagesCount = useCallback(() => {
    profile.getMessageCounts().then(data => {
      console.log(data.data.unread);
      setUnread(data.data.unread);
    });
  }, [profile, setUnread]);

  getMessagesCount();
  return (
    <NavigationLink to={ROUTES.MESSAGES}>
      <FormattedMessage {...commonMessages.messages} /> ({unread})
    </NavigationLink>
  );
};
