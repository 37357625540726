import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { useApiErrorHandler, useModal } from 'helpers';
import { useApi } from 'api/ApiContext';
import { Spinner } from 'components/Spinner/Spinner';
import { Loading } from 'components/Loading/Loading';

import { useProfile } from '../MyProfile/ProfileContext';

import {
  NewsletterForm,
  EmailForm,
  UserDetailsForm,
  TerminateModal,
  PasswordForm,
  ProfileSettingsSection,
} from './components';
import { messages } from './messages';
import styles from './ProfileSettings.module.scss';

export const ProfileSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { userData, setUserData } = useProfile();

  const [isFetchingsGenderOptions, setFetchingGenderOptions] = useState<boolean>(true);
  const [genderOptions, setGenderOptions] = useState<string[]>([]);

  const [isFetchingProfile, setFetchingProfile] = useState<boolean>(true);

  const {
    open: openTerminateModal,
    close: closeTerminateModal,
    modalProps: terminateModalProps,
  } = useModal();

  const getUserData = useCallback(async () => {
    setFetchingProfile(true);
    try {
      const { data } = await profile.getCurrentUser();
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setFetchingProfile(false);
    }
  }, [profile, handleError, setUserData]);

  const getGenderOptions = useCallback(async () => {
    setFetchingGenderOptions(true);
    try {
      const { data } = await profile.getGenderOptions();
      setGenderOptions(data);
    } catch (error) {
      handleError(error);
    } finally {
      setFetchingGenderOptions(false);
    }
  }, [profile, handleError]);

  useEffect(() => {
    getUserData();
    getGenderOptions();
  }, [getUserData, getGenderOptions]);

  if (isFetchingProfile) {
    return <Spinner isLarge={true} className={styles.spinnerContainer} />;
  }

  if (!userData) return null;

  const { gender, firstName, lastName, newsletterAccepted, yearOfBirth, birthPlace } = userData;

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <section className={styles.topContainer}>
        <div className={styles.container}>
          <h1 className={styles.mainTitle}>
            <FormattedMessage {...messages.mainTitle} />
          </h1>
        </div>
      </section>
      <section className={styles.mainSection} data-testid="mainSection">
        <div className={styles.container}>
          <ProfileSettingsSection title={formatMessage(messages.userDetailsTitle)}>
            <Loading isLoading={isFetchingsGenderOptions}>
              <UserDetailsForm
                firstName={firstName}
                lastName={lastName}
                birthPlace={birthPlace}
                yearOfBirth={yearOfBirth}
                gender={gender}
                genderOptions={genderOptions}
              />
            </Loading>
            <EmailForm />
            <PasswordForm />
          </ProfileSettingsSection>

          <ProfileSettingsSection
            title={formatMessage(messages.communicationTitle)}
            text={formatMessage(messages.communicationText)}
          >
            <h5 className={styles.checkboxTitle}>
              <FormattedMessage {...messages.communicationCheckboxTitle} />
            </h5>
            <NewsletterForm newsletterAccepted={newsletterAccepted} />
          </ProfileSettingsSection>

          <ProfileSettingsSection
            warning={true}
            title={formatMessage(messages.terminateAccountTitle)}
            text={''}
          >
            <Button onClick={openTerminateModal} warning={true} block={true}>
              <FormattedMessage {...messages.terminateAccountButton} />
            </Button>
          </ProfileSettingsSection>
        </div>
      </section>

      <Modal
        header={<FormattedMessage {...messages.terminateAccountModal} />}
        {...terminateModalProps}
      >
        <TerminateModal onClose={closeTerminateModal} />
      </Modal>
    </>
  );
};
