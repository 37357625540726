import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { Button } from 'components/Button/Button';
import { Form, Formik } from 'formik';
import { useGrantNames } from 'helpers';
import { DeserializedEntity, FormikValueSetter, ProfileFiltersValues } from 'types';
import { CheckboxField } from 'components/CheckboxField/CheckboxField';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { OptionType, SelectField } from 'components/Autocomplete';
import debounce from 'debounce-promise';

import { ItemsMultiselectAutocomplete } from '../ItemsMultiselectAutocomplete/ItemsMultiselectAutocomplete';
import { ClearableLabel } from '../ClearableLabel/ClearableLabel';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';

import { ProfilesListFiltersProps } from './types';
import { messages } from './messages';
import styles from './ProfilesListFilters.module.scss';

export const ProfilesListFilters: React.FC<ProfilesListFiltersProps> = ({
  onFiltersSubmit,
  activeFilters,
  isVisible,
  positions,
  degrees,
  grantInstitutions,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const { getGrantNames, grantNames, areNamesLoading } = useGrantNames();

  const getDisciplines = async (query: string) => {
    const response = await profile.getSubdisciplines(query);
    return response.data.data;
  };

  const getInstitutions = async (query: string) => {
    const response = await profile.getInstitutions(query);
    return response.data.data as DeserializedEntity[];
  };

  const initialValues = {
    ...activeFilters,
  };

  const onFiltersClose = (setFieldValue: FormikValueSetter) => {
    setFieldValue('disciplines', activeFilters.disciplines);
    setFieldValue('educations', activeFilters.educations);
    setFieldValue('academicTitle', activeFilters.academicTitle);
    setFieldValue('degree', activeFilters.degree);
    setFieldValue('grantProvider', activeFilters.grantProvider);
    setFieldValue('grantName', activeFilters.grantName);

    onClose();
  };

  const onFiltersClear = (setFieldValue: FormikValueSetter) => {
    setFieldValue('disciplines', []);
    setFieldValue('educations', []);
    setFieldValue('withStatus', false);
    setFieldValue('academicTitle', '');
    setFieldValue('degree', '');
    setFieldValue('grantProvider', '');
    setFieldValue('grantName', '');
  };

  const clearGrantFields = (setFieldValue: FormikValueSetter) => {
    setFieldValue('grantProvider', '');
    setFieldValue('grantName', '');
  };

  const onGrantProviderSelect = (setFieldValue: FormikValueSetter, value: OptionType) => {
    setFieldValue('grantName', '');
    getGrantNames((value as OptionType).value);
  };

  const shouldDisableClearFilters = (getFieldProps: any) => {
    return (
      getFieldProps('disciplines').value.length === 0 &&
      getFieldProps('educations').value.length === 0 &&
      !getFieldProps('withStatus').value &&
      !getFieldProps('academicTitle').value &&
      !getFieldProps('degree').value &&
      !getFieldProps('grantProvider').value
    );
  };

  return (
    <div className={classnames(styles.wrapperBackground, isVisible && styles.isVisible)}>
      <div className={styles.wrapper}>
        <Formik<ProfileFiltersValues>
          initialValues={initialValues}
          onSubmit={(values: ProfileFiltersValues) => onFiltersSubmit(values)}
        >
          {({ isSubmitting, setFieldValue, getFieldProps }) => (
            <Form className={styles.form} noValidate>
              <div className={styles.mobileHeading}>
                <h5 className={styles.title}>
                  <FormattedMessage {...messages.header} />
                </h5>
                <button onClick={() => onFiltersClose(setFieldValue)} className={styles.button}>
                  <CloseIcon className={styles.icon} />
                </button>
              </div>
              <div className={styles.heading}>
                <h5 className={styles.title}>
                  <FormattedMessage {...messages.header} />
                </h5>
                <ClearableLabel
                  label={formatMessage(messages.clearAllFilters)}
                  onClear={() => onFiltersClear(setFieldValue)}
                  disabled={shouldDisableClearFilters(getFieldProps)}
                />
              </div>
              <div className={styles.filtersArea}>
                <ItemsMultiselectAutocomplete
                  fieldName="disciplines"
                  label={formatMessage(messages.disciplinesIdsLabel)}
                  placeholder={formatMessage(messages.disciplinesIdsPlaceholder)}
                  loadOptions={debounce(getDisciplines, 400)}
                />
                <div className={styles.statusFilter}>
                  <label className={styles.statusFilterLabel}>
                    {formatMessage(messages.withStatusLabel)}
                  </label>
                  <div className={styles.statusFilterCheckbox}>
                    <CheckboxField
                      label={formatMessage(messages.withStatusDescription)}
                      value={activeFilters.withStatus}
                      name="withStatus"
                    />
                  </div>
                </div>
                <SelectField
                  name="academicTitle"
                  options={positions}
                  withSeparateLabel
                  label={<FormattedMessage {...messages.currentPositionFieldLabel} />}
                  clearButton={
                    <ClearableLabel
                      onClear={() => setFieldValue('academicTitle', '')}
                      disabled={!getFieldProps('academicTitle').value}
                    />
                  }
                  placeholder={formatMessage(messages.currentPositionFieldPlaceholder)}
                />
                <div className={styles.educationsAutocomplete}>
                  <ItemsMultiselectAutocomplete
                    fieldName="educations"
                    label={formatMessage(messages.educationsIdsLabel)}
                    placeholder={formatMessage(messages.educationsIdsPlaceholder)}
                    loadOptions={debounce(getInstitutions, 400)}
                  />
                </div>
                <SelectField
                  name="degree"
                  options={degrees}
                  withSeparateLabel
                  label={<FormattedMessage {...messages.degreeFieldLabel} />}
                  clearButton={
                    <ClearableLabel
                      onClear={() => setFieldValue('degree', '')}
                      disabled={!getFieldProps('degree').value}
                    />
                  }
                  placeholder={formatMessage(messages.degreeFieldPlaceholder)}
                />
                <label className={styles.grantsHeading}>
                  <FormattedMessage {...messages.grantsHeading} />
                </label>
                <div className={styles.grantsHeadingContainer}>
                  <Tooltip text={<FormattedMessage {...messages.grantTooltipText} />}>
                    <div className={styles.grantsWrapper}>
                      <SelectField
                        name="grantProvider"
                        onFieldChange={(value: OptionType) =>
                          onGrantProviderSelect(setFieldValue, value)
                        }
                        options={grantInstitutions}
                        withSeparateLabel
                        label={<FormattedMessage {...messages.grantProviderLabel} />}
                        clearButton={
                          <ClearableLabel
                            onClear={() => clearGrantFields(setFieldValue)}
                            disabled={!getFieldProps('grantProvider').value}
                          />
                        }
                        placeholder={formatMessage(messages.grantProviderPlaceholder)}
                      />
                      <SelectField
                        name="grantName"
                        options={grantNames}
                        isDisabled={areNamesLoading || !getFieldProps('grantProvider').value}
                        label={<FormattedMessage {...messages.grantNameLabel} />}
                        clearButton={
                          <ClearableLabel
                            onClear={() => setFieldValue('grantName', '')}
                            disabled={!getFieldProps('grantName').value}
                          />
                        }
                        placeholder={formatMessage(
                          getFieldProps('grantProvider').value
                            ? messages.grantNamePlaceholder
                            : messages.grantNameWithoutInstitutionPlaceholder
                        )}
                        className={styles.grantNameSelect}
                      />
                    </div>
                  </Tooltip>
                </div>
                <Button
                  type="submit"
                  isProcessing={isSubmitting}
                  disabled={isSubmitting}
                  className={styles.submitButton}
                >
                  <FormattedMessage {...messages.submit} />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
