import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageName: {
    id: 'GrantsList.pageName',
    defaultMessage: 'Grants',
    description: 'Grants page name',
  },
  pageTitle: {
    id: 'GrantsList.pageTitle',
    defaultMessage: 'Grants',
    description: 'Grants page title',
  },
  headingSubtitle: {
    id: 'GrantsList.headingSubtitle',
    defaultMessage: 'Seeking Funding Opportunities for Your Research?',
    description: 'Grants page heading subtitle',
  },
  headingDescription: {
    id: 'GrantsList.headingDescription',
    defaultMessage:
      'Explore our comprehensive list of active grants across various funding institutions. Delve into succinct summaries and utilize direct links to explore full details and apply on official grant pages. Navigate your next research project’s funding with ease and precision.',
    description: 'Grants page heading description',
  },
  sectionTitle: {
    id: 'GrantsList.sectionTitle',
    defaultMessage: 'Scientific oportunities',
    description: 'Grants page section title',
  },
  searchInstruction1: {
    id: 'GrantsList.searchInstruction1',
    defaultMessage: 'To easy search for a job please press',
    description: 'Grants page search instruction - first part',
  },
  searchInstruction2: {
    id: 'GrantsList.searchInstruction2',
    defaultMessage:
      'button combination and type what are you looking for. If you are using a Macbook press',
    description: 'Grants page search instruction - second part',
  },
  ctrlF: {
    id: 'GrantsList.ctrlF',
    defaultMessage: 'Ctrl + F',
    description: 'Grants page search instruction - ctrl + F',
  },
  commandF: {
    id: 'GrantsList.commandF',
    defaultMessage: 'Command + F',
    description: 'Grants page search instruction - command + F',
  },
  toastText: {
    id: 'GrantsList.toastText',
    defaultMessage:
      'Click here to replay the guided tour of our grants page for a comprehensive experience!',
    description: 'Toast information',
  },
  toastCloseText: {
    id: 'GrantsList.toastCloseText',
    defaultMessage: 'DISMISS',
    description: 'Toast Close text',
  },
});
