import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../../config/routes';

import { messages } from './messages';
import styles from './GainSection.module.scss';

interface ContainerProps {
  featureTitle: React.ReactNode;
  featureBullet1: React.ReactNode;
  featureBullet2: React.ReactNode;
  featureBullet3: React.ReactNode;
}

const GainContainer: React.FC<ContainerProps> = ({
  featureTitle,
  featureBullet1,
  featureBullet2,
  featureBullet3,
}) => (
  <div className={styles.gainContainer}>
    <h4 className={styles.featureTitle}>{featureTitle}</h4>
    <ul>
      <li className={styles.featureBullet}>{featureBullet1}</li>
      <li className={styles.featureBullet}>{featureBullet2}</li>
      <li className={styles.featureBullet}>{featureBullet3}</li>
    </ul>
  </div>
);

export const GainSection: React.FC = () => (
  <section className={styles.container}>
    <h2 className={styles.title}>
      <FormattedMessage {...messages.gainTitle} />
    </h2>

    <h5 className={styles.subtitle}>
      <Link to={ROUTES.SIGNUP}>
        <FormattedMessage {...messages.gainSubtitle} />
      </Link>
    </h5>

    <div className={styles.row}>
      <GainContainer
        featureTitle={<FormattedMessage {...messages.gainFirst} />}
        featureBullet1={<FormattedMessage {...messages.gainFirstBullet1} />}
        featureBullet2={<FormattedMessage {...messages.gainFirstBullet2} />}
        featureBullet3={<FormattedMessage {...messages.gainFirstBullet3} />}
      />
      <GainContainer
        featureTitle={<FormattedMessage {...messages.gainSecond} />}
        featureBullet1={<FormattedMessage {...messages.gainSecondBullet1} />}
        featureBullet2={<FormattedMessage {...messages.gainSecondBullet2} />}
        featureBullet3={<FormattedMessage {...messages.gainSecondBullet3} />}
      />
      <GainContainer
        featureTitle={<FormattedMessage {...messages.gainThird} />}
        featureBullet1={<FormattedMessage {...messages.gainThirdBullet1} />}
        featureBullet2={<FormattedMessage {...messages.gainThirdBullet2} />}
        featureBullet3={<FormattedMessage {...messages.gainThirdBullet3} />}
      />
      <GainContainer
        featureTitle={<FormattedMessage {...messages.gainFourth} />}
        featureBullet1={<FormattedMessage {...messages.gainFourthBullet1} />}
        featureBullet2={<FormattedMessage {...messages.gainFourthBullet2} />}
        featureBullet3={<FormattedMessage {...messages.gainFourthBullet3} />}
      />
    </div>
    <br />
    <h5 className={styles.tagline}>
      <FormattedMessage
        {...messages.gainTagline}
        values={{
          strike: (chunks: React.ReactNode) => <span className={styles.strike}>{chunks}</span>,
        }}
      />
    </h5>
  </section>
);
