import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PartnerSection.module.scss';
import { messages } from './messages';

interface ContainerProps {
  featureTitle: React.ReactNode;
  featureSubtitle: React.ReactNode;
  imageName: string;
  imageClass: string;
}

const PartnerContainer: React.FC<ContainerProps> = ({
  featureTitle,
  featureSubtitle,
  imageName,
  imageClass,
}) => (
  <div className={styles.partnerContainer}>
    <h4 className={styles.featureTitle}>{featureTitle}</h4>
    <h5 className={styles.featureSubTitle}>{featureSubtitle}</h5>
    <div className={styles.imageContainer}>
      <img
        src={require('assets/images/' + imageName + '.png')}
        alt={imageName}
        className={imageClass}
      />
    </div>
  </div>
);

export const PartnerSection: React.FC = () => (
  <div className={styles.row}>
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerFirstTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerFirstSubtitle} />}
      imageClass={styles.image2}
      imageName={'partner1'}
    />
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerSecondTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerSecondSubtitle} />}
      imageClass={styles.image}
      imageName={'partner2'}
    />
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerThirdTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerThirdSubtitle} />}
      imageClass={styles.image}
      imageName={'partner3'}
    />
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerFourthTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerFourthSubtitle} />}
      imageClass={styles.image}
      imageName={'partner4'}
    />
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerFifthTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerFifthSubtitle} />}
      imageClass={styles.image}
      imageName={'partner5'}
    />
    <PartnerContainer
      featureTitle={<FormattedMessage {...messages.partnerSixthTitle} />}
      featureSubtitle={<FormattedMessage {...messages.partnerSixthSubtitle} />}
      imageClass={styles.image}
      imageName={'partner6'}
    />
  </div>
);
