import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  noResultsCopyPefix: {
    id: 'FilteredProfiles.noResultsCopyPefix',
    defaultMessage: `Sorry, we couldn't find the person`,
    description: 'First part of the copy for empty profiles list',
  },
  noResultsCopySuffix: {
    id: 'FilteredProfiles.noResultsCopySuffix',
    defaultMessage: 'you were looking for.',
    description: 'Second part of the copy for empty profiles list',
  },
  noResultsCopySecondLine: {
    id: 'FilteredProfiles.noResultsCopySecondLine',
    defaultMessage: 'Please try different criteria.',
    description: 'Second line of the copy for empty profiles list',
  },
});
