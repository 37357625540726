import React from 'react';
import { useField } from 'formik';
import { ValueType } from 'react-select';
import { components } from 'react-select';
import uniq from 'lodash/uniq';
import { FieldError } from 'components/FieldError/FieldError';

import { AutocompleteFieldProps, OptionType, MenuProps } from './types';
import { Autocomplete } from './Autocomplete';
import { MultiValue } from './MultiValue';

const Menu: React.FC<MenuProps> = ({ children, selectProps, ...props }) => {
  if (!selectProps.inputValue) return null;

  return (
    <components.Menu {...props} selectProps={selectProps}>
      {children}
    </components.Menu>
  );
};

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  name,
  isMulti,
  errorClassName,
  ...props
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({ name });

  const handleChange = (selectValue: ValueType<OptionType>) => {
    const handleMultiselect = (options: OptionType[]) => {
      return options ? uniq(options.map(({ label }) => label)) : [];
    };

    setValue(isMulti ? handleMultiselect(selectValue as OptionType[]) : selectValue || '');
  };

  const getValue = () => {
    if (isMulti) {
      return value ? value.map((value: string) => ({ label: value, value })) : [];
    } else {
      return value || '';
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const hasError = touched && !!error;

  return (
    <div className="autocomplete-container">
      <Autocomplete
        value={getValue()}
        isInvalid={hasError}
        isValid={touched && !error}
        onChange={handleChange}
        onBlur={handleBlur}
        isMulti={isMulti}
        components={{ Menu, MultiValue }}
        {...props}
      />
      {hasError && <FieldError name={name} className={errorClassName} />}
    </div>
  );
};
