import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { commonMessages } from 'intl/commonMessages';
import { SaveButton, CancelButton } from 'components/Button/Button';
import { Checkbox } from 'components/CheckboxField/Checkbox';
import { useProfile } from 'modules/profiles/pages/MyProfile/ProfileContext';

import styles from '../../ProfileSettings.module.scss';

export const NewsletterForm: React.FC<{ newsletterAccepted: boolean }> = ({
  newsletterAccepted,
}) => {
  const { formatMessage } = useIntl();
  const [isEdited, setEdited] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(newsletterAccepted);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { profile } = useApi();
  const handleError = useApiErrorHandler();
  const { setUserData } = useProfile();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEdited(false);
    try {
      const { data } = await profile.updateSettings({ newsletterAccepted: isSubscribed });
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
      setEdited(false);
    }
  };

  const cancel = () => {
    setEdited(false);
    setIsSubscribed(newsletterAccepted);
  };

  const onChange = () => {
    setEdited(true);
    setIsSubscribed(isSubscribed => !isSubscribed);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Checkbox
        name="newsletter"
        label={formatMessage(commonMessages.profileNewsletter)}
        checked={isSubscribed}
        onChange={onChange}
      />
      {isEdited && (
        <div className={styles.buttonContainer}>
          <CancelButton onClick={cancel} className={styles.button} disabled={isSubmitting} />
          <SaveButton
            type="submit"
            className={styles.button}
            disabled={isSubmitting}
            isProcessing={isSubmitting}
          />
        </div>
      )}
    </form>
  );
};
