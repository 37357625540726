import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  header: {
    id: 'ProfilesListFilters.header',
    defaultMessage: 'Filter by',
    description: 'Profiles list filters header',
  },
  clearAllFilters: {
    id: 'ProfilesListFilters.clearAllFilters',
    defaultMessage: 'Clear all filters',
    description: 'Clear all profiles list filters button content',
  },
  disciplinesIdsLabel: {
    id: 'ProfilesListFilters.disciplinesIdsLabel',
    defaultMessage: 'Discipline',
    description: 'Discipline filter label',
  },
  disciplinesIdsPlaceholder: {
    id: 'ProfilesListFilters.disciplinesIdsPlaceholder',
    defaultMessage: 'e.g. Geology',
    description: 'Discipline filter placeholder',
  },
  currentPositionFieldLabel: {
    id: 'ProfilesListFilters.currentPosition',
    defaultMessage: 'Current Position',
    description: 'Role filter label',
  },
  currentPositionFieldPlaceholder: {
    id: 'ProfilesListFilters.currentPositionFieldPlaceholder',
    defaultMessage: 'Choose a current Position',
    description: 'Current Position filter placeholder',
  },
  degreeFieldLabel: {
    id: 'ProfilesListFilters.degreeFieldLabel',
    defaultMessage: 'Degree',
    description: 'Degree filter label',
  },
  degreeFieldPlaceholder: {
    id: 'ProfilesListFilters.degreeFieldPlaceholder',
    defaultMessage: 'Choose a degree',
    description: 'Degree filter placeholder',
  },
  grantTooltipText: {
    id: 'ProfilesListFilters.grantTooltipText',
    defaultMessage:
      'Discover scientists involved in grant projects! Our Grants & Funding search module allows you to connect with experts who have direct experience in grant implementation.',
    description: 'Grants Tooltip text',
  },
  grantsHeading: {
    id: 'ProfilesListFilters.grantsHeading',
    defaultMessage: 'Grants & Funding',
    description: 'Grants area heading',
  },
  grantProviderLabel: {
    id: 'ProfilesListFilters.grantProviderLabel',
    defaultMessage: 'Grant provider',
    description: 'Grant provider filter label',
  },
  grantProviderPlaceholder: {
    id: 'ProfilesListFilters.grantProviderPlaceholder',
    defaultMessage: 'Choose grant provider',
    description: 'Grant provider filter placeholder',
  },
  grantNameLabel: {
    id: 'ProfilesListFilters.grantNameLabel',
    defaultMessage: 'Grant name',
    description: 'Grant name filter label',
  },
  grantNamePlaceholder: {
    id: 'ProfilesListFilters.grantNamePlaceholder',
    defaultMessage: 'Choose grant name',
    description: 'Grant name filter placeholder',
  },
  grantNameWithoutInstitutionPlaceholder: {
    id: 'ProfilesListFilters.grantNameWithoutInstitutionPlaceholder',
    defaultMessage: 'You must select grant provider first',
    description: 'Grant name filter placeholder when no institution selected',
  },
  educationsIdsLabel: {
    id: 'ProfilesListFilters.educationsIdsLabel',
    defaultMessage: 'Education',
    description: 'Education filter label',
  },
  educationsIdsPlaceholder: {
    id: 'ProfilesListFilters.educationsIdsPlaceholder',
    defaultMessage: 'e.g. University of Vienna',
    description: 'Education filter placeholder',
  },
  withStatusLabel: {
    id: 'ProfilesListFilters.withStatusLabel',
    defaultMessage: 'Status',
    description: 'With status filter label',
  },
  withStatusDescription: {
    id: 'ProfilesListFilters.withStatusDescription',
    defaultMessage: 'Show only contact with filled status',
    description: 'With status filter description',
  },
  submit: {
    id: 'ProfilesListFilters.submit',
    defaultMessage: 'Filter',
    description: 'Submit the filters list',
  },
});
