import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ProfileSettings.pageTitle',
    defaultMessage: 'Profile Settings',
    description: 'Profile Settings page name',
  },
  mainTitle: {
    id: 'ProfileSettings.mainTitle',
    defaultMessage: 'Profile Settings',
    description: 'Profile Settings page name',
  },
  userDetailsTitle: {
    id: 'ProfileSettings.userDetailsTitle',
    defaultMessage: 'User Details',
    description: 'User Details title',
  },
  privacyTitle: {
    id: 'ProfileSettings.privacyTitle',
    defaultMessage: 'Privacy',
    description: 'Privacy title',
  },
  privacyText: {
    id: 'ProfileSettings.privacyText',
    defaultMessage:
      'You control who can see your profile. If you choose to stay hidden nobody will be able to find you with the search, however your profile can be seen if you share a direct url link to it with someone.  People with hidden profiles can use all the features of Polonium network.',
    description: 'Privacy text',
  },
  privacyCheckboxTitle: {
    id: 'ProfileSettings.privacyCheckboxTitle',
    defaultMessage: 'Hidden profile',
    description: 'Privacy checkbox title',
  },
  communicationTitle: {
    id: 'ProfileSettings.communicationTitle',
    defaultMessage: 'Communication',
    description: 'Communication title',
  },
  communicationText: {
    id: 'ProfileSettings.communicationText',
    defaultMessage:
      'We value your time and attention. That is why you control all the communication we sent to you. Please select which communication you would like to receive from Polonium Network.',
    description: 'Communication text',
  },
  communicationCheckboxTitle: {
    id: 'ProfileSettings.communicationCheckboxTitle',
    defaultMessage: 'Communication received',
    description: 'Communication checkbox title',
  },
  terminateAccountTitle: {
    id: 'ProfileSettings.terminateAccountTitle',
    defaultMessage: 'Terminate Polonium account',
    description: 'Terminate Polonium account title',
  },
  terminateAccountText: {
    id: 'ProfileSettings.terminateAccountText',
    defaultMessage:
      'This will cause most of your personal data to be anonymized or removed from our systems. You will also lose the access to our network database and job offers. ',
    description: 'Terminate Polonium account text',
  },
  terminateAccountButton: {
    id: 'ProfileSettings.terminateAccountButton',
    defaultMessage: 'Terminate the account',
    description: 'Terminate account button',
  },
  terminateAccountModal: {
    id: 'ProfileSettings.terminateAccountModal',
    defaultMessage: 'Terminate an account',
    description: 'Terminate account modal title',
  },
  terminateModalSubtitle: {
    id: 'ProfileSettings.terminateModalSubtitle',
    defaultMessage: 'Are you sure you want to leave?',
    description: 'Terminate account modal subtitle',
  },
  terminateModalText: {
    id: 'ProfileSettings.terminateModalText',
    defaultMessage: 'If yes, please write us an email: ',
    description: 'Terminate account modal text',
  },
  terminateButton: {
    id: 'ProfileSettings.terminateButton',
    defaultMessage: 'OK',
    description: 'Terminate account modal button',
  },
});
