import { useMemo } from 'react';
import { RouteProps, matchPath, useLocation } from 'react-router';

export const useNavigationVisibility = (excludedRoutes: RouteProps[]) => {
  const { pathname } = useLocation();
  const isVisible = useMemo(() => excludedRoutes.every(route => !matchPath(pathname, route)), [
    pathname,
    excludedRoutes,
  ]);

  return isVisible;
};
