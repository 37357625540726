import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageName: {
    id: 'JobOffersList.pageName',
    defaultMessage: 'Careers',
    description: 'Careers page name',
  },
  pageTitle: {
    id: 'JobOffersList.pageTitle',
    defaultMessage: 'Careers',
    description: 'Careers page title',
  },
  headingSubtitle: {
    id: 'JobOffersList.headingSubtitle',
    defaultMessage: 'Need to recruit researchers and scientists?',
    description: 'Careers page heading subtitle',
  },
  headingDescription: {
    id: 'JobOffersList.headingDescription',
    defaultMessage:
      'Within our network we have an easy access to thousands of well qualified scholars. Interested? Check out how to use our network for you growth.',
    description: 'Careers page heading description',
  },
  postOfferButton: {
    id: 'JobOffersList.postOfferButton',
    defaultMessage: 'Post an offer',
    description: 'Careers page post offer button content',
  },
  sectionTitle: {
    id: 'JobOffersList.sectionTitle',
    defaultMessage: 'Scientific oportunities',
    description: 'Careers page section title',
  },
  searchInstruction1: {
    id: 'JobOffersList.searchInstruction1',
    defaultMessage: 'To easy search for a job please press',
    description: 'Careers page search instruction - first part',
  },
  searchInstruction2: {
    id: 'JobOffersList.searchInstruction2',
    defaultMessage:
      'button combination and type what are you looking for. If you are using a Macbook press',
    description: 'Careers page search instruction - second part',
  },
  ctrlF: {
    id: 'JobOffersList.ctrlF',
    defaultMessage: 'Ctrl + F',
    description: 'Careers page search instruction - ctrl + F',
  },
  commandF: {
    id: 'JobOffersList.commandF',
    defaultMessage: 'Command + F',
    description: 'Careers page search instruction - command + F',
  },
});
