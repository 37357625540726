import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Poland } from 'assets/icons/poland.svg';
import { ReactComponent as World } from 'assets/icons/world.svg';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';

import { messages } from './messages';
import styles from './HeroStats.module.scss';

export const HeroStats: React.FC = () => (
  <WhiteBox landing className={styles.whiteBoxContainer}>
    <ul className={styles.list}>
      <li className={styles.container}>
        <Poland className={styles.icon} />
        <div>
          <h5 className={styles.title}>
            <FormattedMessage {...messages.firstStat} />
          </h5>
          <p className={styles.stats}>
            <FormattedMessage {...messages.firstStatNumber} />
          </p>
        </div>
      </li>
      <li className={styles.container}>
        <World className={styles.icon} />
        <div>
          <h5 className={styles.title}>
            <FormattedMessage {...messages.secondStat} />
          </h5>
          <p className={styles.stats}>
            <FormattedMessage {...messages.secondStatNumber} />
          </p>
        </div>
      </li>
    </ul>
  </WhiteBox>
);
