import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { ROUTES } from 'config/routes';

import { DropdownNavLink, LogoutButton } from '../NavigationItem/NavigationItem';

import styles from './UserMenuDropdown.module.scss';

export interface UserMenuDropdownProps {
  isOpen: boolean;
  onClick: () => void;
  onLogoutClick: () => void;
}

export const UserMenuDropdown: React.FC<UserMenuDropdownProps> = ({
  isOpen,
  onClick,
  onLogoutClick,
}) => (
  <ul className={classnames(styles.dropdown, { [styles.open]: isOpen })}>
    <DropdownNavLink to={ROUTES.MY_PROFILE} onClick={onClick}>
      <FormattedMessage {...commonMessages.profile} />
    </DropdownNavLink>

    <DropdownNavLink to={ROUTES.SETTINGS} onClick={onClick}>
      <FormattedMessage {...commonMessages.settings} />
    </DropdownNavLink>

    <LogoutButton onClick={onLogoutClick} />
  </ul>
);
