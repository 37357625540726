import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  helpTitle: {
    id: 'Home.helpTitle',
    defaultMessage: 'HELP OTHERS FIND YOU',
    description: 'Help section title',
  },
  helpSubtitle: {
    id: 'Home.helpSubtitle',
    defaultMessage: 'on your own terms',
    description: 'Help section subtitle',
  },
  helpFirstFeatureTitle: {
    id: 'Home.helpFirstFeatureTitle',
    defaultMessage: 'We strengthen your chances',
    description: 'Help first feature title',
  },
  helpFirstFeature: {
    id: 'Home.helpFirstFeature',
    defaultMessage:
      'We create a community that fosters collaboration, exchange of ideas, and sharing of knowledge and experience. You choose what you’re looking for. You choose what you want to offer. You let us know what you’re interested in - and we make sure that this is the content that you get.',
    description: 'Help first feature',
  },
  helpSecondFeatureTitle: {
    id: 'Home.helpSecondFeatureTitle',
    defaultMessage: 'Help us make a difference',
    description: 'Help second feature title',
  },
  helpSecondFeature: {
    id: 'Home.helpSecondFeature',
    defaultMessage:
      'By joining, you help us to make the research environment in Poland better. We may use statistical data for research and policy-making purposes, but we will never share your personal information with anyone else without your consent.',
    description: 'Help second feature ',
  },
  helpCTA: {
    id: 'Home.helpCTA',
    defaultMessage: 'Join for free',
    description: 'Help CTA',
  },
});
