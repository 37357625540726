import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { ProfileFiltersValues } from 'types';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/TextField/TextField';
import { useIntl, FormattedMessage } from 'react-intl';

import styles from './SearchBar.module.scss';
import { messages } from './messages';

const validationSchema = object().shape({
  query: string(),
});

interface SearchBarProps {
  defaultSearchValue: string;
  onSearchSubmit: ({ query }: ProfileFiltersValues) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onSearchSubmit, defaultSearchValue }) => {
  const { formatMessage } = useIntl();

  const initialValues = {
    query: defaultSearchValue,
  };

  return (
    <>
      <Formik<ProfileFiltersValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSearchSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className={styles.form} noValidate>
            <TextField
              name="query"
              label={<FormattedMessage {...messages.searchLabel} />}
              placeholder={formatMessage(messages.searchPlaceholder)}
              classNames={{ input: styles.searchField, wrapper: styles.searchWrapper }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onSearchSubmit(values);
                }
              }}
            />
            <Button
              type="submit"
              small
              className={styles.submit}
              iconClassName={styles.searchIcon}
              isProcessing={isSubmitting}
              disabled={isSubmitting}
              icon={!isSubmitting && <SearchIcon />}
            />
          </Form>
        )}
      </Formik>
      <p className={styles.searchSuffix}>
        <FormattedMessage {...messages.searchInstructions} />
      </p>
    </>
  );
};
