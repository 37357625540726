import React from 'react';
import Select, { ValueType } from 'react-select';
import { OptionType } from 'components/Autocomplete';
import { useIntl, FormattedMessage } from 'react-intl';

import styles from './OrderDropdown.module.scss';
import { messages } from './messages';
import './OrderDropdown.scss';

interface OrderDropdownProps {
  currentValue: any;
  onOrderChange: (order: ValueType<OptionType>) => void;
}

export const OrderDropdown: React.FC<OrderDropdownProps> = ({ onOrderChange, currentValue }) => {
  const { formatMessage } = useIntl();

  const orderOptions = [
    { label: formatMessage(messages.nameOption), value: 'last_name ASC' },
    { label: formatMessage(messages.dateOfJoiningOption), value: 'created_at DESC' },
  ];

  return (
    <div className={styles.orderDropdownWrapper}>
      <span>
        <FormattedMessage {...messages.prefix} />
      </span>
      <div className="order-dropdown">
        <Select
          name="orderBy"
          isSearchable={false}
          options={orderOptions}
          onChange={onOrderChange}
          value={orderOptions.find(option => option.value === currentValue)}
          classNamePrefix="dropdown"
        />
      </div>
    </div>
  );
};
