import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'BecomePartner.pageTitle',
    defaultMessage: 'Become a partner',
    description: 'Become a partner landing page',
  },
  heroTitle: {
    id: 'BecomePartner.heroTitle',
    defaultMessage: 'Why work with us?',
    description: 'Hero title',
  },
  heroMessage: {
    id: 'BecomePartner.heroMessage',
    defaultMessage:
      'We strengthen your chances of reaching the right people significantly. You don’t have to trust our word, we have the data.',
    description: 'Hero message',
  },
  heroMessageSecond: {
    id: 'BecomePartner.heroMessageSecond',
    defaultMessage:
      'Our research on the Polish scientific diaspora suggests that access to this community may offer great opportunities for the business sector in Poland, allowing it to expand its professional networks and find new employees with experience gained at the best institutions all over the world.',
    description: 'Hero message second paragraph',
  },
  heroMessageEmphasized: {
    id: 'BecomePartner.heroMessageEmphasized',
    defaultMessage: 'You bring the great offers, we let the candidates find you.',
    description: 'Hero message emphasize',
  },
  heroButton: {
    id: 'BecomePartner.heroButton',
    defaultMessage: 'Find out more',
    description: 'Hero button',
  },
});
