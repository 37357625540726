import React, { useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Form, Formik, FormikHelpers } from 'formik';
import { object } from 'yup';
import { validators, PASSWORD_MIN_LENGTH } from 'config/errors/validators';
import { useApi } from 'api/ApiContext';
import { useApiErrorHandler } from 'helpers';
import { useSearchParams } from 'helpers';
import { commonMessages } from 'intl/commonMessages';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { Button } from 'components/Button/Button';
import background from 'assets/images/background-5.svg';

import { SuccessScreen } from '../../components/SuccessScreen/SuccessScreen';

import { RequirementListItem } from './components/RequirementListItem';
import { messages } from './messages';
import styles from './ResetPassword.module.scss';

interface ResetPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = object().shape({
  password: validators.password,
  passwordConfirmation: validators.passwordConfirmation,
});

export interface ResetPasswordProps extends RouteComponentProps<{}> {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { formatMessage } = useIntl();
  const { auth } = useApi();
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [accessToken, uid, client] = useSearchParams(['access-token', 'uid', 'client']);
  const handleError = useApiErrorHandler();

  const handleSubmit = useCallback(
    async (values: ResetPasswordForm, { setSubmitting }: FormikHelpers<ResetPasswordForm>) => {
      const headers = { accessToken, uid, client };

      try {
        await auth.updatePassword({ ...values, headers });
        setSubmitted(true);
      } catch (error) {
        handleError(error);
        setSubmitting(false);
      }
    },
    [auth, accessToken, uid, client, handleError]
  );

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      {isSubmitted ? (
        <SuccessScreen
          title={<FormattedMessage {...messages.successTitle} />}
          description={<FormattedMessage {...messages.successDescription} />}
        />
      ) : (
        <AuthLayout
          description={<FormattedMessage {...messages.description} />}
          title={<FormattedMessage {...messages.header} />}
          background={background}
        >
          <Formik<ResetPasswordForm>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={styles.form} noValidate>
                <PasswordField
                  name="password"
                  label={formatMessage(messages.newPasswordLabel)}
                  placeholder={formatMessage(commonMessages.passwordPlaceholder)}
                />
                <PasswordField
                  name="passwordConfirmation"
                  label={formatMessage(commonMessages.passwordConfirmationLabel)}
                  placeholder={formatMessage(commonMessages.passwordPlaceholder)}
                />
                <ul className={styles.list}>
                  <RequirementListItem
                    messageID="lengthRequirement"
                    highlightedID="lengthDetails"
                    extraValues={{ minLength: PASSWORD_MIN_LENGTH }}
                  />
                  <RequirementListItem
                    messageID="charactersRequirement"
                    highlightedID="charactersDetails"
                  />
                  <RequirementListItem
                    messageID="uniquenessRequirement"
                    highlightedID="uniquenessDetails"
                  />
                </ul>
                <Button
                  type="submit"
                  className={styles.submit}
                  isProcessing={isSubmitting}
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...messages.submitButton} />
                </Button>
              </Form>
            )}
          </Formik>
        </AuthLayout>
      )}
    </>
  );
};
