import React from 'react';

import styles from '../GrantCard.module.scss';

const CallStartAtInfo: React.FC<{ callStartAt: string }> = ({ callStartAt }) => (
  <span>
    <span className={styles.callStartAtEndAtElementName}>Call start: </span>
    <span>{callStartAt}</span>
  </span>
);
const CallEndAtInfo: React.FC<{ callEndAt: string }> = ({ callEndAt }) => (
  <span>
    <span className={styles.callStartAtEndAtElementName}>Call deadline: </span>
    <span>{callEndAt}</span>
  </span>
);
const CallStartAtEndAtInfo: React.FC<{
  callStartAt: string | undefined;
  callEndAt: string | undefined;
}> = ({ callStartAt, callEndAt }) => {
  return (
    <div className={styles.callStartAtEndAtElementContainer}>
      {callStartAt && callEndAt ? (
        <span>
          <span className={styles.callStartAtEndAtElementName}>Call duration:&nbsp;</span>
          {`${callStartAt} <> ${callEndAt}`}
        </span>
      ) : (
        <>
          {callStartAt && <CallStartAtInfo callStartAt={callStartAt} />}
          {callEndAt && <CallEndAtInfo callEndAt={callEndAt} />}
        </>
      )}
    </div>
  );
};
export default CallStartAtEndAtInfo;
