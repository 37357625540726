import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';

import styles from './InlineEditButton.module.scss';

export const InlineEditButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  onClick,
}) => (
  <button className={styles.button} onClick={onClick}>
    <FormattedMessage {...commonMessages.editModeButton} />
  </button>
);
