import React from 'react';
import { map, range } from 'lodash';
import { SelectField } from 'components/Autocomplete/SelectField';

export type YearDropdownProps = {
  name: string;
};

export const YearDropdown: React.FC<YearDropdownProps> = props => {
  const thisYear = new Date().getFullYear();
  const years = map(range(thisYear, 1929, -1), year => {
    return { value: year, label: year };
  });

  return (
    <div>
      <SelectField label="Year of birth *" isSearchable={false} options={years} {...props} />
      <p style={{ marginTop: -26, marginBottom: 26 }}>not visible for other users</p>
    </div>
  );
};
