import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  nameOption: {
    id: 'OrderDropdown.nameOption',
    defaultMessage: 'Name',
    description: 'Label for name order dropdown',
  },
  dateOfJoiningOption: {
    id: 'OrderDropdown.dateOfJoiningOption',
    defaultMessage: 'Date of joining',
    description: 'Label for date of joining order dropdown',
  },
  prefix: {
    id: 'OrderDropdown.prefix',
    defaultMessage: 'Sort by',
    description: 'Prefix order dropdown',
  },
});
