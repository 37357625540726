import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import image from 'assets/images/about-us-bg.png';
import { ROUTES } from 'config/routes';
import { ButtonLink } from 'components/Button/ButtonLink';

import { HeroSection } from '../components/HeroSection/HeroSection';
import { NewsletterSection } from '../components/NewsletterSection/NewsletterSection';

import { messages } from './messages';
import styles from './About.module.scss';
import { DataSection } from './components/DataSection/DataSection';
import { AboutPoloniumSection } from './components/AboutPoloniumSection/AboutPoloniumSection';

export const About: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <>
      <Helmet title={formatMessage(messages.pageTitle)} />
      <HeroSection
        backgroundImage={image}
        heroTitle={<FormattedMessage {...messages.heroTitle} />}
        classNames={{ textContainer: styles.heroSection, photo: styles.heroPhoto }}
      >
        <div className={styles.row}>
          <p className={styles.paragraph}>
            <FormattedMessage {...messages.heroMessage1} />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage
              {...messages.heroMessage2}
              values={{
                bold: (
                  <span className={styles.bold}>
                    <FormattedMessage {...messages.heroMessageEmphasized} />
                    <br />
                    <br />
                  </span>
                ),
              }}
            />
          </p>
        </div>
        <ButtonLink to={ROUTES.SIGNUP} className={styles.heroButton}>
          <FormattedMessage {...messages.heroButton} />
        </ButtonLink>
        <ButtonLink to={ROUTES.PARTNERSHIP} secondary>
          <FormattedMessage {...messages.becomePartnerButton} />
        </ButtonLink>
      </HeroSection>
      <DataSection />
      <AboutPoloniumSection />
      <NewsletterSection />
    </>
  );
};
