import React from 'react';
import { POLONIUM_FOUNDATION_WEBSITE } from 'config/constants';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as PoloniumLogo } from 'assets/icons/polonium-logo.svg';

import styles from './ButtonPolonium.module.scss';

export const ButtonPolonium: React.FC = () => (
  <a
    className={styles.button}
    href={POLONIUM_FOUNDATION_WEBSITE}
    target="_blank"
    rel="noreferrer noopener"
  >
    <PoloniumLogo className={styles.poloniumLogo} />
    <ArrowIcon className={styles.arrowIcon} />
  </a>
);
