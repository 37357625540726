import React, { useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { useNotification } from 'modules/notifications/NotificationContext';
import { AVATAR_UPLOAD_CONFIG, FileUploadConfig } from 'config/fileUpload';

import { messages } from './messages';
import styles from './FileUploadField.module.scss';

export interface FileUploadFieldProps extends DropzoneOptions {
  onFileAdd: (files: File[]) => void;
  addedFile: File | null;
  config?: FileUploadConfig;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  onFileAdd,
  addedFile,
  children,
  config = AVATAR_UPLOAD_CONFIG,
}) => {
  const { createNotification } = useNotification();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      onFileAdd && onFileAdd(acceptedFiles);

      rejectedFiles.forEach(({ name }: File) => {
        createNotification({
          type: 'error',
          message: (
            <FormattedMessage
              {...messages.fileRejected}
              values={{
                name,
                size: config.maxSizeInMB,
                formats: config.acceptedFormats,
              }}
            />
          ),
        });
      });
    },
    [onFileAdd, createNotification, config]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: config.acceptedFormatsList,
    maxSize: config.maxSizeInBytes,
  });

  return (
    <>
      <div {...getRootProps()} className={styles.fieldWrapper}>
        <input {...getInputProps()} />
        {children}
      </div>
      {addedFile && (
        <>
          <h5 className={styles.fileHeader}>
            <FormattedMessage {...messages.addedFile} />
          </h5>
          <div className={styles.file}>{addedFile.name}</div>
        </>
      )}
    </>
  );
};
