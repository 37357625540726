import { OptionType } from 'components/Autocomplete/types';
import { capitalizeFirstLetter } from 'helpers';
import { ValueType } from 'react-select';

export class GenderOptions {
  options: OptionType[];
  gender: string;

  constructor(gender: string, options: string[]) {
    this.gender = gender;
    this.options = this.generateOptions(options);
  }

  get option() {
    return this.findOption(this.gender);
  }

  private generateOptions(pulledOptions: string[]) {
    return pulledOptions.map(value => ({
      label: capitalizeFirstLetter(value),
      value,
    }));
  }

  private findOption(gender: string): ValueType<OptionType> {
    const option = this.options.find(({ value }) => value === gender);

    return option;
  }
}
