import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ProfilesList.pageTitle',
    defaultMessage: 'Community',
    description: 'Community page name',
  },
  resultsMessage: {
    id: 'ProfilesList.resultsMessage',
    defaultMessage: `Results for "{searchValue}"`,
    description: 'Message for results',
  },
  showAllfiltersButton: {
    id: 'ProfilesList.showAllfiltersButton',
    defaultMessage: 'Show all filters',
    description: 'Show all filters button content',
  },
  noQueryMessage: {
    id: 'ProfilesList.noQueryMessage',
    defaultMessage: 'Community',
    description: 'Message for results with no query provided',
  },
  resultsCount: {
    id: 'ProfilesList.resultsCount',
    defaultMessage: '{count, plural, one {result} other {results}}',
    description: 'Results count',
  },
  resultsSuffix: {
    id: 'ProfilesList.resultsSuffix',
    defaultMessage: 'available',
    description: 'Suffix for filters results',
  },
});
