import { AxiosResponse } from 'axios';
import { JobOffer, DeserializedJobOffer } from 'types/jobOffer';

import { ResourceClient } from './ResourceClient';

export class JobOffersClient extends ResourceClient {
  getJobOffers(): Promise<AxiosResponse<{ data: JobOffer[] }>> {
    return this.client.get<{ data: JobOffer[] }>('/job_offers');
  }

  getJobOffer(id: string): Promise<AxiosResponse<{ data: { attributes: DeserializedJobOffer } }>> {
    return this.client.get<{ data: { attributes: DeserializedJobOffer } }>(`/job_offers/${id}`);
  }
}
