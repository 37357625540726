import React from 'react';
import classnames from 'classnames';

import styles from './HeroSection.module.scss';

interface HeroSectionProps {
  backgroundImage: string;
  heroTitle: React.ReactNode;
  heroMessage?: React.ReactNode;
  heroMessage2?: React.ReactNode;
  heroMessageEmphasized?: React.ReactNode;
  classNames?: {
    photo?: string;
    textContainer?: string;
  };
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  backgroundImage,
  heroTitle,
  heroMessage,
  heroMessage2,
  heroMessageEmphasized,
  classNames = {},
  children,
}) => (
  <section className={styles.section}>
    <div
      className={classnames(styles.heroPhoto, classNames.photo)}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    />

    <div className={styles.container}>
      <div className={classnames(styles.halfWidthContainer, classNames.textContainer)}>
        <h1 className={styles.heroTitle}>{heroTitle}</h1>
        {heroMessage && (
          <>
            <p className={styles.heroParagraph}>{heroMessage}</p>

            {heroMessage2 && (
              <>
                <p className={styles.heroParagraph}>{heroMessage2}</p>
              </>
            )}
            <p className={styles.heroParagraph}>
              <strong className={styles.textBolder}>{heroMessageEmphasized}</strong>
            </p>
          </>
        )}
      </div>
      {children}
    </div>
  </section>
);
