import { ROUTES } from 'config/routes';

import { Signup } from './pages/Signup/Signup';
import { Login } from './pages/Login/Login';
import { AccountConfirmation } from './pages/AccountConfirmation/AccountConfirmation';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { ForgotPasswordConfirmation } from './pages/ForgotPasswordConfirmation/ForgotPasswordConfirmation';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';

export const authorizationRoutes = [
  { path: ROUTES.SIGNUP, exact: true, component: Signup },
  { path: ROUTES.LOGIN, exact: true, component: Login },
  { path: ROUTES.ACCOUNT_CONFIRMATION, exact: true, component: AccountConfirmation },
  { path: ROUTES.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: ROUTES.FORGOT_PASSWORD_CONFIRMATION, exact: true, component: ForgotPasswordConfirmation },
  { path: ROUTES.RESET_PASSWORD, exact: true, component: ResetPassword },
];
