import React from 'react';

import styles from '../GrantCard.module.scss';

const InstitutionInfo: React.FC<{
  institution: string;
  grantId: number;
  institutionId: number | undefined;
}> = ({ institution, grantId, institutionId }) => {
  return (
    <div id={institutionId === grantId ? 'sixth-step' : ''} className={styles.institution}>
      {institution}
    </div>
  );
};

export default InstitutionInfo;
