import React from 'react';
import Slider, { Settings } from 'react-slick';
import { FormattedMessage } from 'react-intl';
import testimonial_michal_heller from 'assets/images/8.png';
import testimonial_monika_magon from 'assets/images/7.png';
import testimonial_3 from 'assets/images/AndrzejSZH.png';

import { Slide } from './Slide';
import { SliderArrowButton } from './SliderArrowButton';
import { messages } from './messages';
import styles from './TestimonialsSection.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const testimonialSlides = [
  {
    author: 'Olle Bergman',
    imgUrl: testimonial_michal_heller,
    copyrights: 'Olle Bergman, Eskilstuna, Sweden',
    text:
      'Life, in general, is about connecting stuff – in art we juxtapose ideas and objects, in professional life, we coordinate solutions and systems with the flow and transformation of matter. Connecting people is both an art and a professional task. I love to see my – quite extensive – network as a huge workshop where I maintain a continuous creative process by picking the right people to form new constellations and clusters. Most of all, I love to connect small groups of people and see a project or a beautiful friendship being formed. If things start happening while I’m not even present, I feel immensely proud: wow, the world is so much bigger than me!',
    date: '3 Sep 2020',
  },
  {
    author: 'Agata Misiaszek',
    imgUrl: testimonial_monika_magon,
    copyrights: 'Agata Misiaszek, EMBL Heidelberg',
    text:
      'My name is Agata and I am a structural biologist. My science journey started in Edinburgh with undergraduate studies in Molecular Biology. At that time, through to events such as Science: Polish Perspectives I met people from various fields and at different career stages, who gave me a glimpse into the incredible world of research. Thanks to a lot of encouragement from them, I first applied for internships in Sweden and later for a PhD position at European Molecular Biology Laboratory (EMBL) in Germany, where I am currently based. Each new step in my career was accompanied by invaluable information and support from people I met on my way.',
    date: '27 Aug 2020',
  },
  {
    author: 'Andrzej Szewczak-Harris',
    imgUrl: testimonial_3,
    copyrights: 'Andrzej Szewczak-Harris, University of Cambridge',
    text:
      "I’m Andrzej, I work at the Department of Biochemistry, University of Cambridge and I am a Fellow of St Catharine's College, where I teach biochemistry and genetics to veterinary and medical undergraduates. Before coming to the UK, I studied molecular biology and biotechnology in Poznań and Göttingen. I got involved with Polonium Foundation through PhD friends, who encouraged me to attend the SPP conferences. The meetings have been a hugely stimulating experience; a source of not only new personal connections, but also serious scientific collaborations.",
    date: '8 Sept 2020',
  },
];

export const TestimonialsSection: React.FC = () => {
  const sliderSettings: Settings = {
    infinite: true,
    lazyLoad: 'progressive',
    speed: 1500,
    autoplaySpeed: 8000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    prevArrow: <SliderArrowButton prev />,
    nextArrow: <SliderArrowButton />,
  };

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          <FormattedMessage {...messages.testimonialsTitle} />
        </h2>
        <h3 className={styles.subtitle}>
          <FormattedMessage {...messages.testimonialsSubtitle} />
        </h3>
      </div>
      <div className={styles.slider}>
        <Slider {...sliderSettings}>
          {testimonialSlides.map(({ author, date, ...testimonial }) => (
            <Slide key={`${author}-${date}`} title={author} date={date} {...testimonial} />
          ))}
        </Slider>
      </div>
    </section>
  );
};
