import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { useApiErrorHandler } from 'helpers';
import { useApi } from 'api/ApiContext';
import { DeserializedPosition } from 'types';

import { MenuProps, OptionType } from './types';

import { SelectField, SelectFieldProps, MenuWithHint } from '.';

import './Autocomplete.scss';

const Menu: React.FC<MenuProps> = props => (
  <MenuWithHint
    hint={<FormattedMessage {...commonMessages.positionAutocompleteHint} />}
    openOnFocus
    {...props}
  />
);

export interface PositionSelectFieldProps extends Omit<SelectFieldProps<OptionType>, 'label'> {}

export const PositionSelectField: React.FC<PositionSelectFieldProps> = ({ name, ...props }) => {
  const [positions, setPositions] = useState<DeserializedPosition[]>([]);
  const { formatMessage } = useIntl();
  const { profile } = useApi();
  const handleError = useApiErrorHandler();

  const getPositions = useCallback(async () => {
    try {
      const response = await profile.getPositions();
      setPositions(response.data.data);
    } catch (error) {
      handleError(error);
    }
  }, [profile, handleError]);

  useEffect(() => {
    getPositions();
  }, [getPositions]);

  return (
    <SelectField
      name={name}
      components={{ Menu }}
      label={formatMessage(commonMessages.positionLabel)}
      placeholder={formatMessage(commonMessages.positionPlaceholder)}
      options={positions}
      isCreatable
      {...props}
    />
  );
};
